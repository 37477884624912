import React, { useState, useRef, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

// CUSTOM COMPONENTS
import Loader from "../Loader/Loader";
import Error from "../Error/Error";

// CUSTOM SCSS
import "./DetailsRows.scss";
import "../../scss/shared/Flag-large.scss";

const DetailsRows = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const { data } = props;
  const prevProps = usePrevious({ data });
  useEffect(() => {
    if (prevProps) {
      if (
        JSON.stringify(prevProps.data) !== JSON.stringify(props.data) &&
        props.data
      ) {
        setIsLoading(props.data.fetching);
        setError(props.data.error);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  //console.log("props.data", props.data);

  return (
    <Card>
      <CardBody className="card_box details_box">
        {isLoading ? (
          <Loader loading={isLoading} />
        ) : (
          <>
            {error ? (
              <Error error={error} />
            ) : (
              <div className="material-table__wrap">
                <Table
                  className={
                    "material-table" +
                    (props.className && " " + props.className)
                  }
                >
                  <TableBody>
                    {props.data.view.map((item, index) => {
                      return (
                        <TableRow
                          className="material-table__row"
                          key={"dataset-row-" + item.attribute}
                        >
                          {item.header ? (
                            <TableCell className="material-table__cell key_cell">
                              {item.header}
                            </TableCell>
                          ) : null}
                          <TableCell
                            className="material-table__cell value_cell"
                            colSpan={item.header ? "1" : "2"}
                          >
                            {props.data.data[item.attribute] && (
                              <div
                                className={`${
                                  item.header ? "normal_value" : "bold_value"
                                } ${item.class && item.class}`}
                              >
                                {item.type === "flag" ? (
                                  <span
                                    className={`flag-lg flag-lg-${
                                      props.data.data[item.attribute]
                                    }`}
                                  ></span>
                                ) : (
                                  props.data.data[
                                    item.attribute
                                  ].toLocaleString()
                                )}
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default DetailsRows;
