import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Cart from '../../../containers_/ECommerce/Cart/index';
import OrdersList from '../../../containers_/ECommerce/OrdersList/index';
import Payment from '../../../containers_/ECommerce/Payment/index';
import ProductEdit from '../../../containers_/ECommerce/ProductEdit/index';
import ProductsList from '../../../containers_/ECommerce/ProductsList/index';
import Catalog from '../../../containers_/ECommerce/Catalog/index';
import ProductPage from '../../../containers_/ECommerce/ProductPage/index';

export default () => (
  <Switch>
    <Route path="/e-commerce/cart" component={Cart} />
    <Route path="/e-commerce/catalog" component={Catalog} />
    <Route path="/e-commerce/orders_list" component={OrdersList} />
    <Route path="/e-commerce/payment" component={Payment} />
    <Route path="/e-commerce/product_edit" component={ProductEdit} />
    <Route path="/e-commerce/product_page" component={ProductPage} />
    <Route path="/e-commerce/products_list" component={ProductsList} />
  </Switch>
);
