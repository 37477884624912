import React from 'react';
import { Card, CardBody } from 'reactstrap';

const IntroductionFeatures = () => (
  <Card className="card--not-full-height">
    <CardBody>
      <div className="card__title">
        <h5 className="bold-text">Introduction</h5>
      </div>
      <p>
        DataStories is a modern dashboard template based on React Components and
        Bootstrap 4 Framework. We’ve used modern technologies and best practices
        to make our product easy to work with. It’s the most convenient template
        for developers, because of React Components, clean code and detailed
        documentation, which allows you to build any projects easily! Use it for
        e-commerce, analytics, sports and other types of web or mobile
        applications.
      </p>
      <div className="card__title">
        <h5 className="bold-text">Features</h5>
      </div>
      <ul>
        <li>Bootstrap 4 Stable (4.0.0)</li>
        <li>Fully Responsive Layout</li>
        <li>SASS Powered</li>
        <li>100+ Pages</li>
        <li>Free Icon Fonts</li>
        <li>200+ UI Elements</li>
        <li>Dashboard Cards & Widgets</li>
        <li>Shop and Listing Cards & Widgets</li>
        <li>Responsive Navbar Menu</li>
        <li>3 Kinds of Charts (Recharts, Chart.js, React-vis)</li>
        <li>Form Validation</li>
        <li>Form Wizards</li>
        <li>React WYSIWYG Editor</li>
        <li>Material-ui</li>
        <li>Redux</li>
        <li>Redux Form</li>
        <li>React Router 4</li>
        <li>React-smooth-scrollbar</li>
        <li>Reactstrap (Bootstrap 4 components)</li>
        <li>React-slick</li>
        <li>React-big-calendar</li>
        <li>Colored Tables and DataTables</li>
        <li>Google and Vector Maps</li>
        <li>Two Color Themes</li>
        <li>Unlimited Theme Possibilities</li>
        <li>FREE Lifetime Updates</li>
        <li>and much much more…</li>
      </ul>
    </CardBody>
  </Card>
);

export default IntroductionFeatures;
