import React, { PureComponent } from 'react';
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';
import classnames from 'classnames';
import Panel from '../../../components/Panel/Panel';
import ShareModal from './ShareData/ShareModal';

import './ProfileTabs.scss';

export default class ProfileTabs extends PureComponent {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  spreadDataSets = () => {
    /** Fields for share button */
    const fieldList = {
      fields: [
        {
          name: 'url',
          type: 'copy'
        },
        {
          name: 'embed',
          type: 'copy'
        },
        {
          name: 'twitter',
          type: 'link'
        },
        {
          name: 'facebook',
          type: 'link'
        }
      ]
    };
    var queries = this.props.datasets.query.map((data, index) => {
      return (
        <Panel
          key={'query-' + index}
          divider={true}
          title={data.title}
          type="query"
          id={data._id}
          dataPanel={true}
          collapse={true}
          label={data.access_level}
        >
          <div className="d-flex tags_container">
            <div className="label">Tags: </div>
            {data.tags.map((tag, index) => {
              return (
                <div key={`query-tag-${index}`} className="tag">
                  {tag}
                </div>
              );
            })}
          </div>
        </Panel>
      );
    });
    var datasets = this.props.datasets.dataset.map((data, index) => {
      return (
        <Panel
          key={'dataset-' + index}
          divider={true}
          title={data.title}
          type="dataset"
          id={data._id}
          dataPanel={true}
          collapse={true}
          label={data.access_level}
        >
          <div className="d-flex tags_container">
            <div className="label">Tags: </div>
            {data.tags.map((tag, index) => {
              return (
                <div key={`dataset-tag-${index}`} className="tag">
                  {tag}
                </div>
              );
            })}
          </div>
        </Panel>
      );
    });
    var visualizations = this.props.datasets.visualization.map(
      (data, index) => {
        return (
          <Panel
            key={'visual-' + index}
            divider={true}
            title={data.title}
            type="visualization"
            id={data._id}
            dataPanel={true}
            collapse={true}
            label={data.access_level}
          >
            <div className="d-flex tags_container">
              <div className="label">Tags: </div>
              {data.tags.map((tag, index) => {
                return (
                  <div key={`visual-tag-${index}`} className="tag">
                    {tag}
                  </div>
                );
              })}
            </div>
            <div>
              <ShareModal id={data.id} url="visualization/" {...fieldList} />
            </div>
          </Panel>
        );
      }
    );

    return (
      <>
        <TabPane tabId="1">
          <Card>
            <CardBody>{queries}</CardBody>
          </Card>
        </TabPane>
        <TabPane tabId="2">
          <Card>
            <CardBody>{datasets}</CardBody>
          </Card>
        </TabPane>
        <TabPane tabId="3">
          <Card>
            <CardBody>{visualizations}</CardBody>
          </Card>
        </TabPane>
      </>
    );
  };

  render() {
    const { activeTab } = this.state;
    return (
      <div className="profile_container">
        <div className="tabs tabs_profile">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    this.toggle('1');
                  }}
                >
                  Queries
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    this.toggle('2');
                  }}
                >
                  Data sets
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => {
                    this.toggle('3');
                  }}
                >
                  Visualizations
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              {this.spreadDataSets()}
            </TabContent>
          </div>
        </div>
      </div>
    );
  }
}
