import { apiRequest } from "../../../services/api/index";

import { fetchData, setData, setError } from "./publicContractsActions";

// CUSTOM DATA
import { buyersHead } from "../../../services/helpers/buyersHead";

export function getBuyers(filters, from) {
  return async (dispatch) => {
    dispatch(fetchData("buyers"));
    try {
      const params = {
        type: "grid",
        data: {
          type: "BUYERS",
          terms: [],
          ranges: [],
          size: 10,
          from: from,
        },
      };
      // Add selected facet's value
      if (filters.buyerCountry) {
        params.data.terms.push({
          ISO_COUNTRY_CODE: filters.buyerCountry,
        });
      }
      if (filters.sellerCountry) {
        params.data.terms.push({
          WIN_COUNTRY_CODE: filters.sellerCountry,
        });
      }
      if (filters.cpv) {
        params.data.terms.push({
          CPV: filters.cpv,
        });
      }
      // Add selected facet's range
      if (filters.year) {
        params.data.ranges.push({
          YEAR: {
            start: filters.year[0],
            end: filters.year[1],
          },
        });
      }
      // if (filters.number) {
      //   params.data.params.number = filters.number;
      // }
      // if (filters.amount) {
      //   params.data.params.amount = filters.amount;
      // }
      const response = await apiRequest(params);
      if (response.status === 200) {
        const payload = {
          data: response.data.data,
          view: buyersHead,
          total: response.data.total,
          from: from + 10,
        };
        dispatch(setData("buyers", payload));
      } else {
        dispatch(setError("buyers", "Something went wrong. Please try later!"));
      }
    } catch (error) {
      dispatch(setError("buyers", "Something went wrong. Please try later!"));
    }
  };
}
