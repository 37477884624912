const barChart = {
  chart: {
    type: "column",
  },
  title: {
    text: "",
  },
  colors: ["#FF8660", "#FFB760", "#E05486", "#4481A5", "#9FE657"],
  legend: {
    enabled: true,
    shadow: false,
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.0f}</b></td></tr>',
    footerFormat: "</table>",
    useHTML: true,
  },
  xAxis: {
    title: {
      enabled: false,
    },
    type: "category",
    categories: [],
    labels: {
      useHTML: 1,
      style: {
        "max-width": "200px",
      },
    },
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
    series: {
      dataLabels: {},
    },
  },
  yAxis: [
    {
      title: {
        enabled: true,
        text: "Amount (€)",
      },
      type: "logarithmic",
    },
    {
      title: {
        enabled: true,
        text: "Number of Contracts",
      },
      type: "logarithmic",
      opposite: true,
    },
  ],
  series: [
    {
      name: "Amount (€)",
      data: [],
    },
    {
      name: "Number of Contracts",
      yAxis: 1,
      data: [],
    },
  ],
  credits: {
    enabled: true,
  },
};

export { barChart };
