import React, { useState } from "react";

// CUSTOM COMPONENTS
import SelectItems from "../SelectItems/SelectItems";

// CUSTOM SERVICES
import { createSheet } from "../../services/export/Sheet";

// ICONS
import FileDownloadOutlineIcon from "mdi-react/FileDownloadOutlineIcon";

// CUSTOM SCSS
import "./ExportSheet.scss";

/** set up sheet available types */
const sheetTypes = ["Excel", "CSV"];

const ExportSheet = ({ head, rows }) => {
  /** set up default type first item of sheetTypes */
  const [type, setType] = useState(sheetTypes[0]);

  /** Calls services Export to return new created Sheet */
  const getSheet = async () => {
    await createSheet(type, head, rows);
  };

  /** Sets selected value */
  const selectValue = (selected) => {
    setType(selected);
  };

  return (
    <div className="d-flex align-items-center export_sheet">
      <div>Download</div>
      <SelectItems options={sheetTypes} selectValue={selectValue} />
      <div
        className="export_sheet_action"
        onClick={() => {
          getSheet();
        }}
      >
        <FileDownloadOutlineIcon />
      </div>
    </div>
  );
};

export default ExportSheet;
