import React, { useState } from 'react';
import { checkPerName } from '../../services/validationFields/validationFields';
import TextField from '@material-ui/core/TextField';

const TagField = props => {
  /** InputField state */
  const [errorMessage, setError] = useState();
  /** Setup props for input */
  const inputProps = {
    name: props.name,
    type: props.type,
    label: props.label,
    placeholder: props.placeholder,
    required: props.required ? props.required : false
  };

  /**
   * Validates input, displays error message and sends check to parent component
   * @param {String} value
   */
  const checkValue = value => {
    var errorMessage = checkPerName(props.name, value);
    if (errorMessage) {
      setError(errorMessage);
    } else {
      /** calls setTags before calling parent component */
      props.valueCheck(setTags(value), props.name);
    }
  };

  /**
   * Transform string with comma's to array
   * @param {String} value
   */
  const setTags = value => {
    return value.split(',');
  };

  /**
   * Removes error display upon change value
   */
  const changeValue = () => {
    if (errorMessage) {
      setError();
    }
  };

  return (
    <>
      <TextField
        className="material-form__field"
        label={props.label}
        placeholder={props.placeholder}
        error={errorMessage}
        onChange={() => changeValue()}
        onBlur={e => checkValue(e.target.value)}
        variant="outlined"
        {...inputProps}
      />
    </>
  );
};

export default TagField;
