/**
 * Return equivalent function result depending on variable
 * @param {*} name
 * @param {*} value
 */
const checkPerName = (name, value) => {
  switch (name) {
    case "email":
      return email(value);
    case "username":
      return validUserPass(name, value);
    case "password":
      return validUserPass(name, value);
    case "title":
      return defined(value);
    default:
      return undefined;
  }
};

/**
 * Value not empty
 * @param {*} value
 */
const defined = (value) => (!value ? "Empty field" : undefined);

/**
 * Max length of variable
 * @param {*} max
 */
const maxLength = (max) => (value) => {
  return value && value.length > max
    ? `Must be ${max} characters or less`
    : undefined;
};

/**
 * Minimum length of variable
 * @param {*} min
 */
const minLength = (min) => (value) => {
  return value && value.length < min
    ? `Must be ${min} characters or more`
    : undefined;
};

/**
 * Max value of variable
 * @param {*} max
 */
const maxValue = (max) => (value) =>
  value && value > max ? `Must be more than ${max}` : undefined;

/**
 * Minimum value of variable
 * @param {*} min
 */
const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;

/**
 * Accepted email form
 * @param {*} value
 */
const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

/**
 * Value is alphanumeric
 * @param {*} value
 */
const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? "Only alphanumeric characters"
    : undefined;

/**
 * Validates username or password and displays message with priority order
 * @param {*} name
 * @param {*} value
 */
const validUserPass = (name, value) => {
  var errorMessage;

  /* eslint-disable no-cond-assign */
  return (errorMessage = defined(value))
    ? errorMessage
    : (errorMessage = alphaNumeric(value)) && name === "username"
    ? errorMessage
    : // : (errorMessage = minLength(6)(value))
      //   ? errorMessage
      //   : (errorMessage = maxLength(15)(value))
      //     ? errorMessage
      //     : undefined;
      undefined;
};

export {
  checkPerName,
  defined,
  maxLength,
  minLength,
  minValue,
  maxValue,
  email,
  alphaNumeric,
};
