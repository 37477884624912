import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router';

// REDUX
import { connect } from 'react-redux';
import { getSearch } from '../../redux/actions/publicContracts/searchActions';
import { getVisualization } from '../../redux/actions/visualizationActions';

// CUSTOM SERVICES
import authStorage from '../../services/authentication/authStorage';

// CUSTOM COMPONENTS
import MatTable from '../../components/MatTable/MatTable';
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error/Error';

// CUSTOM SCSS
import './Datasets.scss';

class Datasets extends PureComponent {
  state = {
    isLoading: false,
    error: ''
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      // Loads preselected filters from dataset
      let isLogged = !!authStorage.getAuth('auth');
      if (isLogged) {
        this.props.getVisualization(this.props.match.params.id, isLogged);
        this.setState({ isLoading: true });
      }
    }
  }

  componentDidUpdate(prevProps) {
    // Loads new data when filters are updated
    if (
      JSON.stringify(this.props.visualization) !==
      JSON.stringify(prevProps.visualization)
    ) {
      if (
        this.props.visualization.fetching !== prevProps.visualization.fetching
      ) {
        this.setState({ isLoading: this.props.visualization.fetching });
      }
      if (this.props.visualization.error !== prevProps.visualization.error) {
        this.setState({ error: this.props.visualization.error });
      }
      if (
        this.props.visualization.item.data.length > 0 &&
        JSON.stringify(this.props.visualization.item.data[0]) !==
          JSON.stringify(prevProps.visualization.item.data[0])
      ) {
        this.props.getSearch(this.props.visualization.item.data[0], 0);
      }
    }
  }

  /**
   * Loads next page search data from API
   */
  getNextSearchPage = () => {
    this.props.getSearch(
      this.props.visualization.item.data[0],
      this.props.search.from
    );
  };

  render() {
    return (
      <Container className="dashboard">
        {this.props.visualization.item.title && (
          <Row>
            <Col md={12}>
              <h3 className="page-title">
                {this.props.visualization.item.title}
              </h3>
            </Col>
          </Row>
        )}
        <Row></Row>
        <Loader loading={this.state.isLoading} page={true} />
        {!this.state.isLoading && (
          <>
            {this.state.error ? (
              <Error error={this.state.error} page={true} />
            ) : (
              <Row className="dashboard_container">
                <Col>
                  <div className="result">
                    <MatTable
                      data={this.props.search}
                      rows={10}
                      onFilterUpdate={() => {}}
                      onNextSearchPage={() => {
                        this.getNextSearchPage();
                      }}
                      checkBox={false}
                      export={true}
                    ></MatTable>
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    visualization: state.visualization,
    search: state.publicContracts.search
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getVisualization: (id, access) => dispatch(getVisualization(id, access)),
    getSearch: (filters, search) => dispatch(getSearch(filters, search))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Datasets)
);
