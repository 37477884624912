import * as Cookie from 'js-cookie';

/**
 * Set auth storage name value
 * @param {*} name
 * @param {*} value
 */
const setAuth = (name, value) => {
  Cookie.set(name, value);
};

/**
 * Get auth value
 * @param {*} name
 */
const getAuth = name => {
  return Cookie.get(name);
};

/**
 * Remove auth
 * @param {*} name
 */
const removeAuth = name => {
  Cookie.remove(name);
  return Cookie.get(name);
};

export default {
  setAuth,
  getAuth,
  removeAuth
};
