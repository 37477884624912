import React from 'react';
import PropTypes from 'prop-types';

// CUSTOM SCSS
import './Loader.scss';

const Loader = ({ loading, simple, page }) => {
  return (
    <>
      {loading && (
        <div
          className={`load inline-loader ${loading ? '' : ' loaded'} ${
            simple ? ' simple-loader' : ''
          } ${page ? ' page-loader' : ''}`}
        >
          <div className="load__icon-wrap inside_loader">
            <svg className="load__icon">
              <path
                fill="#4ce1b6"
                d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
              />
            </svg>
          </div>
        </div>
      )}
    </>
  );
};

Loader.propTypes = {
  loading: PropTypes.bool,
  simple: PropTypes.bool
};

Loader.defaultProps = {
  loading: false,
  simple: false
};

export default Loader;
