import React, { useEffect, useState } from "react";
import { Col, Badge, Table, Button } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

function handleExecute(path) {
  //console.log("Query will be executed in a new Window", path);
  window.open(`http://143.233.226.60:9200/${path}`, "_blank");
}

const BasicTable = (props) => {
  // Initiation of the table data
  const [method, setMethod] = useState("");
  const [url, setURL] = useState("");
  const [query, setQuery] = useState("");
  const [paths, setPath] = useState([]);

  // Upon rendering of the table, fetch the query related information from REDUX
  useEffect(() => {
    if (props.search.queryData) {
      if (
        query !== decodeURIComponent(props.search.queryData.path) ||
        method !== props.search.queryData.method
      ) {
        // console.log(
        //   "This is the functional component renderering the query info: ",
        //   props.search
        // );
        setMethod(props.search.queryData.method);
        setURL(`http://143.233.226.60:9200/`);
        setQuery(props.search.queryData.body);

        // Restructure the paths
        let pathArray = decodeURIComponent(props.search.queryData.path).split(
          ","
        );
        pathArray = pathArray.map((path) => {
          path = path.split("");
          if (path[0] === "/") {
            path.shift();
          }
          path = path.join("").toString().replace(",", "");
          return path;
        });
        setPath(pathArray);
      }
    }
  }, [props.search.queryData]);

  function renderRows() {
    return paths.map((path) => {
      return (
        <tr>
          <td>
            <Badge color="warning">ELASTIC</Badge>
          </td>
          <td>
            <Badge color="success">GET</Badge>
          </td>
          <td>{url + path}</td>
          <td>{query}</td>
          <td>
            <Button
              color="success"
              outline
              size="sm"
              key={path}
              onClick={() => handleExecute(path)}
            >
              <div style={{ height: "40px", width: "40px" }}>
                <img src="/gree_play_icon.png" />
              </div>
            </Button>
          </td>
        </tr>
      );
    });
  }

  return (
    <Col>
      <div className="card__title">
        <h5 className="bold-text">Elastic Query Information</h5>
      </div>
      <Table responsive hover striped responsive>
        <thead>
          <tr>
            <th>Repository</th>
            <th>Method</th>
            <th>URL</th>
            <th>Query</th>
            <th>RUN</th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </Table>
    </Col>
  );
};

const mapStateToProps = (state) => {
  return {
    search: state.publicContracts.search,
  };
};

export default withTranslation("common")(
  connect(mapStateToProps, {})(BasicTable)
);
