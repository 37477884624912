import React, { PureComponent } from "react";
import { Card, CardBody } from "reactstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";

import ColumnResizer from "react-column-resizer";

// CUSTOM COMPONENTS
import MatTableHead from "./MatTableHead";
import ExportSheet from "../ExportSheet/ExportSheet";
import Loader from "../Loader/Loader";
import Error from "../Error/Error";

// CUSTOM SCSS
import "./MatTable.scss";
import "../../scss/shared/Flag.scss";

// COUNTRIES ISO CODES
var countriesIso = require("i18n-iso-countries");
countriesIso.registerLocale(require("i18n-iso-countries/langs/en.json"));

function getSorting(order, orderBy) {
  if (order === "desc") {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

export default class MatTable extends PureComponent {
  state = {
    order: "asc",
    orderBy: "",
    selected: new Map([]),
    heads: [],
    subheads: [],
    data: [],
    page: 0,
    rowsPerPage: this.props.rows ? this.props.rows : 5,
    isLoading: true,
    subHead: false,
    error: "",
  };

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
      if (
        this.props.data &&
        this.props.data.view &&
        this.props.data.data &&
        this.props.data.data.length > 0
      ) {
        if (this.props.identifier === "sellerCountries")
          // console.log("ANESID 1 ::: ", this.props.data);
          this.setState({ subhead: false });
        let { heads, subheads } = this.createHeads(this.props.data);
        let data = this.state.data;
        const newData = this.props.data.data.map((data, index) => {
          if (data.group) {
            return data.data;
          } else {
            let formatted_data = {
              id: data.id ? data.id : "data-" + index,
            };
            this.props.data.view.forEach((element, index) => {
              formatted_data = this.createDataElement(
                formatted_data,
                data,
                element
              );
            });
            return formatted_data;
          }
        });

        data = this.props.data.from === 10 ? newData : data.concat(newData);
        // In case of Buyer Countries Add another Amount column with approx value. Also Add the relevnt field  in the rendered data on the table
        if (
          this.props.identifier === "buyerCountries" ||
          this.props.identifier === "allData" ||
          this.props.identifier === "sellerCountries"
        ) {
          if (this.props.identifier === "sellerCountries")
            //console.log("ANESID ::: ", data);

            // anesid: Refactor the heads of the table to include euro sign  on amounts field
            heads = heads.map((head) => {
              head.label = head.label === "Amount" ? "Amount (€)" : head.label;
              return head;
            });

          //Add another Amount column with approx value in the heads ans subheads.
          heads.splice(2, 0, {
            disablePadding: false,
            id: "amountAprox",
            label: "Aprox. Amount (€)",
            type: "string",
          });

          subheads.splice(2, 0, []);

          //console.log("anesid table after: ", heads, data, subheads);
          // Add another Amount column with approx value in the data.
          data = data.map((d) => {
            d.amountAprox = this.formatCurrencies(d.amount);
            d.amount = d.amount.toLocaleString();
            return d;
          });
        } else if (
          this.props.identifier === "buyerCountriesResult" ||
          this.props.identifier === "sellerCountriesResult" ||
          this.props.identifier === "allDataResult" ||
          this.props.identifier === "CPVsCountriesResult"
        ) {
          // anesid: Refactor the heads of the table to include euro sign  on amounts field
          heads = heads.map((head) => {
            head.label = head.label === "Amount" ? "Amount (€)" : head.label;
            //head.type = head.type === "date" ? "string" : head.type;
            return head;
          });

          //Add another Amount column with approx value in the heads ans subheads.
          heads.splice(7, 0, {
            disablePadding: false,
            id: "amountAprox",
            label: "Aprox. Amount (€)",
            type: "string",
          });

          subheads.splice(7, 0, []);
          // Add another Amount column with approx value in the data.
          data = data.map((d) => {
            if (d.AWARD_VALUE_EURO === -1) {
              d.amountAprox = "N/A";
              d.AWARD_VALUE_EURO = "N/A";

              return d;
            } else {
              d.amountAprox = this.formatCurrencies(d.AWARD_VALUE_EURO);
              d.AWARD_VALUE_EURO = d.AWARD_VALUE_EURO.toLocaleString();

              return d;
            }
          });
        } else if (this.props.identifier === "CPVs") {
          //console.log("anesid CPVs before: ", heads, data, subheads);

          heads = heads.map((head) => {
            head.label = head.label === "Amount" ? "Amount (€)" : head.label;
            return head;
          });

          heads.splice(1, 0, {
            disablePadding: false,
            id: "amountAprox",
            label: "Aprox. Amount (€)",
            type: "string",
          });

          subheads.splice(7, 0, []);

          data = data.map((d) => {
            d.amountAprox = this.formatCurrencies(d.amount);
            d.amount = d.amount.toLocaleString();

            return d;
          });

          //console.log("anesid CPVs after: ", heads, data, subheads);
        }
        //console.log("ANESID", data, heads);
        this.setState({
          heads,
          subheads,
          data,
          total: this.props.data.total,
          page: this.props.data.from / 10 - 1,
        });
      } else {
        this.setState({
          order: "asc",
          orderBy: "",
          selected: new Map([]),
          heads: [],
          subheads: [],
          page: 0,
          rowsPerPage: this.props.rows ? this.props.rows : 5,
        });
      }
      this.setState({
        isLoading: this.props.data.fetching,
        error: this.props.data.error,
      });
    }
  }

  // Formaat large numbers into K Million, Billions etc by adding a digit in the end of the umber in string format
  formatCurrencies = (amount) => {
    // 15 Zeroes for Quadrillions
    return Math.abs(Number(amount)) >= 1.0e15
      ? Math.round(Math.abs(Number(amount)) / 1.0e15).toLocaleString() + " Q"
      : // 12 Zeroes for Trillions
      Math.abs(Number(amount)) >= 1.0e12
      ? Math.round(Math.abs(Number(amount)) / 1.0e12).toLocaleString() + " T"
      : // 9 Zeroes for Millions
      Math.abs(Number(amount)) >= 1.0e9
      ? Math.round(Math.abs(Number(amount)) / 1.0e9).toLocaleString() + " B"
      : // 6 Zeroes for Millions
      Math.abs(Number(amount)) >= 1.0e6
      ? Math.round(Math.abs(Number(amount)) / 1.0e6).toLocaleString() + " M"
      : // 3 Zeroes for Thousands
      Math.abs(Number(amount)) >= 1.0e3
      ? Math.round(Math.abs(Number(amount)) / 1.0e3).toLocaleString() + " K"
      : Math.round(Math.abs(Number(amount)).toLocaleString());
  };

  createHeads = (data) => {
    const heads = [];
    const subheads = [];
    data.view.forEach((element, index) => {
      let label = "";
      if (element.type.indexOf("multiple") > -1) {
        label = element.header[this.props.resultsType];
      } else {
        label = element.header;
      }
      if (
        element.header ||
        (element.attribute === "code" && data.id === "graphResults") ||
        element.type === "flag"
      ) {
        heads.push({
          id: element.attribute ? element.attribute : "head-" + index,
          label: label,
          type: element.type,
          disablePadding: false,
        });
        const subheadsRow = [];
        if (element.type === "parent") {
          this.setState({ subhead: true });
          data.data[0][element.attribute].forEach((subElement) => {
            subheadsRow.push({
              id: element.attribute + "-" + subElement.key,
              label: subElement.key,
              disablePadding: false,
            });
          });
        }
        subheads.push(subheadsRow);
      }
    });
    return { heads, subheads };
  };

  setPath() {
    return this.props.innerPath ? ".." : ".";
  }

  createDataElement = (formatted_data, data, element) => {
    let text = data[element.attribute];
    if (element.type === "date" && text) {
      text = new Date(text).toLocaleDateString();
    }
    if (element.type === "flag") {
      text =
        '<span class="flag"><img src="" class="flag flag-' +
        text +
        '" /></span>';
    }
    if (element.type.indexOf("country") > -1) {
      const country = countriesIso.getName(text, "en");
      text =
        '<a href="/countries/' +
        text +
        '">' +
        (country ? country : text) +
        "</a>";
    }

    if (element.url) {
      if (!text) {
        text = "(missing)";
      }
      let url = data[element.url];
      var pattern = /^((http|https|ftp):\/\/)/;
      if (!pattern.test(url)) {
        url = "http://" + url;
      }
      formatted_data[element.attribute] =
        '<a href="' +
        url +
        '" target="_blank">' +
        text +
        '</a> <span class="lnr lnr-link" />';
    } else if (element.link && text) {
      formatted_data[element.attribute] =
        '<a href="/' + element.link + "/" + text + '">' + text + "</a>";
    } else {
      formatted_data[element.attribute] = text;
    }
    return formatted_data;
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { data } = this.state;
      const newSelected = new Map();
      data.map((n) => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
    const oldSelected = this.state.selected;
    const newSelected = new Map([]);
    const value = oldSelected.get(id);
    if (!value) {
      newSelected.set(id, true);
    }
    this.setState({ selected: newSelected });
    this.props.onFilterUpdate(this.props.data.id, id);
  };

  handleChangePage = (event, page) => {
    // If there is a next page and data are not already loaded, load data from API
    const isNext = page > this.state.page;
    const nextExists = page * this.state.rowsPerPage < this.state.total;
    const dataLoaded =
      (page + 1) * this.state.rowsPerPage > this.state.data.length;
    if (isNext && nextExists && dataLoaded) {
      this.props.onNextSearchPage();
    }

    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  cellClick = (label, item) => {
    if (label === "Dataset") {
      this.props.onCellClicked(item.url);
    }
  };

  render() {
    return (
      <Card>
        <CardBody>
          {this.props.data && this.props.data.title && (
            <div className="card__title">
              <h5 className="bold-text">{this.props.data.title}</h5>
            </div>
          )}
          <Loader loading={this.state.isLoading} />
          {!this.state.isLoading && (
            <>
              <Error error={this.state.error} />
              {this.state.data.length > 0 && (
                <>
                  <div className="material-table__wrap">
                    <Table className="material-table">
                      <MatTableHead
                        numSelected={
                          [...this.state.selected].filter((el) => el[1]).length
                        }
                        order={this.state.order}
                        orderBy={this.state.orderBy}
                        onSelectAllClick={this.handleSelectAllClick}
                        onRequestSort={this.handleRequestSort}
                        rowCount={this.state.data.length}
                        heads={this.state.heads}
                        subheads={this.state.subheads}
                        checkBox={this.props.checkBox}
                      />
                      <ColumnResizer className="columnResizer" minWidth={10} />

                      {this.state.subhead && (
                        <MatTableHead
                          numSelected={
                            [...this.state.selected].filter((el) => el[1])
                              .length
                          }
                          order={this.state.order}
                          orderBy={this.state.orderBy}
                          onSelectAllClick={this.handleSelectAllClick}
                          onRequestSort={this.handleRequestSort}
                          rowCount={this.state.data.length}
                          heads={this.state.subheads}
                          checkBox={this.props.checkBox}
                        />
                      )}
                      <TableBody>
                        {this.state.data
                          .sort(
                            getSorting(this.state.order, this.state.orderBy)
                          )
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                          .map((d) => {
                            const isSelected = this.isSelected(d.id);
                            return (
                              <TableRow
                                className="material-table__row"
                                role="checkbox"
                                aria-checked={isSelected}
                                tabIndex={-1}
                                key={d.id}
                                selected={isSelected}
                                hover={false}
                              >
                                {this.props.checkBox && (
                                  <TableCell
                                    className="material-table__cell"
                                    padding="checkbox"
                                  >
                                    <Checkbox
                                      checked={isSelected}
                                      className="material-table__checkbox"
                                      onClick={(event) =>
                                        this.handleClick(event, d.id)
                                      }
                                    />
                                  </TableCell>
                                )}
                                {this.state.heads.map((col, index) => {
                                  if (col.type === "parent") {
                                    return this.state.subheads[index].map(
                                      (subCol, subIndex) => {
                                        return (
                                          <TableCell
                                            key={subCol.id + "-" + d.id}
                                            className="material-table__cell material-table__cell-right"
                                          >
                                            {subCol.id === "code" ? (
                                              <div
                                                className="flag"
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    d[col.id][subIndex].value,
                                                }}
                                              ></div>
                                            ) : (
                                              <div
                                                onClick={() =>
                                                  this.cellClick(
                                                    subCol.label,
                                                    d
                                                  )
                                                }
                                                className="text_ellipsis"
                                                dangerouslySetInnerHTML={{
                                                  __html: d[col.id][subIndex]
                                                    ? d[col.id][subIndex].value
                                                    : "",
                                                }}
                                              ></div>
                                            )}
                                          </TableCell>
                                        );
                                      }
                                    );
                                  } else {
                                    return (
                                      <TableCell
                                        key={col.id + "-" + d.id}
                                        className="material-table__cell material-table__cell-right"
                                      >
                                        {col.id === "code" ? (
                                          <div
                                            className="flag"
                                            dangerouslySetInnerHTML={{
                                              __html: d[col.id],
                                            }}
                                          ></div>
                                        ) : (
                                          <div
                                            onClick={() =>
                                              this.cellClick(col.label, d)
                                            }
                                            className="text_ellipsis"
                                            dangerouslySetInnerHTML={{
                                              __html: d[col.id],
                                            }}
                                          ></div>
                                        )}
                                      </TableCell>
                                    );
                                  }
                                })}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </div>
                  <TablePagination
                    component="div"
                    className="material-table__pagination"
                    count={
                      this.state.total
                        ? this.state.total
                        : this.state.data.length
                    }
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    rowsPerPageOptions={[]}
                    dir="ltr"
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                  />
                </>
              )}
            </>
          )}
          {this.props.export && (
            <div className="d-flex align-items-center justify-content-end">
              <ExportSheet head={this.state.heads} rows={this.state.data} />
            </div>
          )}
        </CardBody>
      </Card>
    );
  }
}
