const infoHead = {
  COUNTRIES: [
    {
      header: '',
      type: 'string',
      attribute: 'name',
      class: 'huge'
    },
    {
      header: '',
      type: 'flag',
      attribute: 'code'
    },
    {
      header: 'Contracts',
      type: 'string',
      attribute: 'contracts',
      class: 'large'
    },
    {
      header: 'Awards',
      type: 'string',
      attribute: 'awards',
      class: 'large'
    },
    {
      header: 'Revenues',
      type: 'string',
      attribute: 'revenues',
      class: 'large'
    },
    {
      header: 'Expenses',
      type: 'string',
      attribute: 'expenses',
      class: 'large'
    },
    {
      header: 'Seller organisations',
      type: 'string',
      attribute: 'sellers',
      class: 'large'
    },
    {
      header: 'Buyer organisations',
      type: 'string',
      attribute: 'buyers',
      class: 'large'
    }
  ],
  SELLERS: [
    {
      header: '',
      type: 'string',
      attribute: 'name',
      class: 'huge'
    },
    {
      header: 'Contracts',
      type: 'string',
      attribute: 'count',
      class: 'large'
    },
    {
      header: 'Amount',
      type: 'string',
      attribute: 'amount',
      class: 'large'
    },
    {
      header: 'Address',
      type: 'string',
      attribute: 'address'
    },
    {
      header: 'Town',
      type: 'string',
      attribute: 'town'
    },
    {
      header: 'Country',
      type: 'string',
      attribute: 'country'
    },
    {
      header: '',
      type: 'flag',
      attribute: 'code'
    }
  ],
  BUYERS: [
    {
      header: '',
      type: 'string',
      attribute: 'name',
      class: 'huge'
    },
    {
      header: 'Contracts',
      type: 'string',
      attribute: 'count',
      class: 'large'
    },
    {
      header: 'Amount',
      type: 'string',
      attribute: 'amount',
      class: 'large'
    },
    {
      header: 'Address',
      type: 'string',
      attribute: 'address'
    },
    {
      header: 'Town',
      type: 'string',
      attribute: 'town'
    },
    {
      header: 'Country',
      type: 'string',
      attribute: 'country'
    },
    {
      header: '',
      type: 'flag',
      attribute: 'code'
    }
  ]
};

export { infoHead };
