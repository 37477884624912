import {
  SET_TOKEN,
  SET_USER,
  LOG_IN_ERROR,
  FETCHING_API,
  RESET_REDUX
} from '../actions/authActions';

const initialState = {
  username: '',
  email: '',
  avatar: '',
  loading: false,
  token: '',
  error: ''
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_TOKEN:
      return { token: action.payload };
    case SET_USER:
      return {
        ...state,
        username: action.payload.username,
        email: action.payload.email
      };
    case LOG_IN_ERROR:
      return {
        ...state,
        token: '',
        error: action.payload
      };
    case FETCHING_API:
      return {
        ...state,
        loading: action.payload
      };
    case RESET_REDUX:
      return {
        ...state,
        loading: false,
        error: ''
      };
    default:
      return state;
  }
}
