import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import authStorage from '../../services/authentication/authStorage';

const PrivateRoute = ({ component: Component, type, location, ...rest }) => {
  const isLoggedIn = authStorage.getAuth('auth');
  const sharedPages = ['/visualization/', '/iframe/'];
  /** checks if shared pages include current location pathname */
  let isShared = false;

  /** check if current location exists in sharedPages */
  for (const page of sharedPages) {
    if (!isShared) {
      isShared = location.pathname.includes(page);
    }
  }

  /** Checkes if route is shared or if user is logged in, or if route is public  */
  return (
    <Route
      {...rest}
      render={props =>
        isShared ? (
          <Component {...props} />
        ) : isLoggedIn ? (
          type === 'Private' ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        ) : type === 'Public' ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/log_in',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
