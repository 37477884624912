import React, { useState, useRef, useEffect, Fragment } from "react";

import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import Boost from "highcharts/modules/boost";

import HighchartSankey from "highcharts/modules/sankey";
import HighchartsWheel from "highcharts/modules/dependency-wheel";

HighchartSankey(Highcharts);
HighchartsWheel(Highcharts);
Boost(Highcharts);

let constructOption = (props) => {
  let dataSeries = [];
  let from = "";
  if (Array.isArray(props.data["CAE_NAME"])) {
    from = props.data["CAE_NAME"][0];
  } else {
    from = props.data["CAE_NAME"];
  }
  props.connections.data.map((data) => {
    dataSeries.push({
      from: from,
      to: data.key,
      weight: data.count,
    });
  });
  const options = {
    boost: {
      boostThreshold: 1,
      enabled: true,
    },
    chart: {
      type: "dependencywheel",
    },
    title: {
      text: "Connections Between Seller and Buyers",
    },
    series: {
      animation: false,
      data: dataSeries,
    },
  };
};

const ChordDia = (props) => {
  const [optionsSeries, setOptionsSeries] = useState({});
  // console.log("PROPS CHORD", props)
  useEffect(() => {
    let dataSeries = [];
    let from = "";
    // if (Array.isArray(props.data["CAE_NAME"])) {
    if (Array.isArray(props.data["WIN_NAME"])) {
      from = props.data["WIN_NAME"][0];
      // from = props.data["CAE_NAME"][0]
    } else {
      from = props.data["WIN_NAME"];
      // from = props.data["CAE_NAME"]
    }
    props.connections.map((data) => {
      dataSeries.push({
        from: from,
        to: data.key,
        weight: data.count,
      });
    });
    const options = {
      boost: {
        boostThreshold: 1,
        enabled: true,
      },

      chart: {
        type: "dependencywheel",
      },
      title: {
        text: "Connections Between Seller and Buyers",
      },
      series: {
        animation: false,
        data: dataSeries,
      },
    };
    setOptionsSeries(options);
  }, []);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={optionsSeries} />
    </div>
  );
};

export default ChordDia;
