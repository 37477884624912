import React, { useState } from 'react';
import { MenuItem, FormControl, Select } from '@material-ui/core';

const SelectItems = ({ options, selectValue }) => {
  /** Default selected first option */
  const [selected, setSelected] = useState(options[0]);

  /** Sends value change to parent component */
  const handleChange = value => {
    setSelected(value);
    selectValue(value);
  };

  return (
    <FormControl variant="outlined">
      <Select
        value={selected}
        onChange={event => {
          handleChange(event.target.value);
        }}
        displayEmpty
        MenuProps={{
          disableScrollLock: true,
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}
      >
        {options.map(item => {
          return (
            <MenuItem key={'end-' + item} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectItems;
