const {
  countries
} = require("i18n-iso-countries/langs/en.json")

const NA = "Not available"
// export const tabulatorAwardedCols = [{
//     title: 'Name',
//     field: 'TED_NOTICE_URL',
//     formatter: "link",
//     width: 150,
//     formatterParams: {
//       urlPrefix: "https://",
//       target: "_blank"
//     }
//   },
//   {
//     title: 'Seller',
//     field: 'CAE_NAME',
//     hozAlign: 'left'
//   },
//   {
//     title: 'Seller Country',
//     field: 'ISO_COUNTRY_CODE'
//   },
//   {
//     title: 'Buyer',
//     field: 'WIN_NAME'
//   },
//   {
//     title: 'Buyer Country',
//     field: 'WIN_COUNTRY_CODE'
//   },
//   {
//     title: 'Award Date',
//     field: 'DT_AWARD'
//   },
//   {
//     title: 'Dispatch Date',
//     field: 'DT_DISPATCH'
//   },
//   {
//     title: 'Amount',
//     field: 'AWARD_VALUE_EURO',
//     formatter: function (cell) {
//       return cell.getValue() == -1 ? "N/A" : cell.getValue()
//     }
//   },
//   {
//     title: 'Year',
//     field: "YEAR"
//   },
//   {
//     title: 'Main Activity',
//     field: "MAIN_ACTIVITY"
//   }
// ]


export const tabulatorAwardedCols = [{
    title: 'Name',
    field: 'TED_NOTICE_URL',
    formatter: "link",
    width: 150,
    formatterParams: {
      urlPrefix: "https://",
      target: "_blank"
    }
  },
  {
    title: 'Seller',
    // field: 'CAE_NAME',
    field: 'WIN_NAME',

    hozAlign: 'left'
  },
  {
    title: 'Seller Country',
    // field: 'ISO_COUNTRY_CODE',
    field: 'WIN_COUNTRY_CODE',

    formatter: function (cell) {
      if (cell.getValue() == "UK") {
        return "United Kingdom"
      } else if (countries[cell.getValue()]) {
        return countries[cell.getValue()]
      } else {
        return cell.getValue()
      }
    }
  },
  {
    title: 'Buyer',
    // field: 'WIN_NAME',
    field: 'CAE_NAME',

  },
  {
    title: 'Buyer Country',
    field: 'ISO_COUNTRY_CODE',
    // field: 'WIN_COUNTRY_CODE',
    formatter: function (cell) {
      if (cell.getValue() === "UK") {
        return "United Kingdom"
      } else if (countries[cell.getValue()]) {
        return countries[cell.getValue()]
      } else {
        return cell.getValue()
      }
    }
  },
  {
    title: 'Award Date',
    field: 'DT_AWARD',
    formatter: function (cell) {
      if (cell.getValue() === "01-01-1980") {
        return "N/A"
      } else {
        return cell.getValue()
      }
    }
  },
  {
    title: 'Dispatch Date',
    field: 'DT_DISPATCH',
    formatter: function (cell) {
      if (cell.getValue() === "01-01-1980") {
        return "N/A"
      } else {
        return cell.getValue()
      }
    }
  },
  {
    title: 'Amount',
    field: 'AWARD_VALUE_EURO',
    formatter: function (cell) {
      return cell.getValue() == -1 ? "N/A" : cell.getValue() + ' €'
    }
  },
  {
    title: 'Aprox Amount',
    field: 'AWARD_VALUE_EURO',
    formatter: function (cell) {
      let value = cell.getValue()
      if (value === -1) {
        return "N/A"
      }
      if (Math.abs(Number(value)) > 1e15) {
        value = Math.floor((Math.abs(Number(value)) / 1e15) * 100) / 100 + "Q"
      } else if (Math.abs(Number(value)) > 1e12) {
        value = Math.floor((Math.abs(Number(value)) / 1e12) * 100) / 100 + "T"
      } else if (Math.abs(Number(value)) > 1e9) {
        value = Math.floor((Math.abs(Number(value)) / 1e9) * 100) / 100 + "B"
      } else if (Math.abs(Number(value)) > 1e6) {
        value = Math.floor((Math.abs(Number(value)) / 1e6) * 100) / 100 + "M"
      } else if (Math.abs(Number(value)) > 1e3) {
        value = Math.floor((Math.abs(Number(value)) / 1e3) * 100) / 100 + "K"
      }
      return value + ' €'
    }
  },
  {
    title: 'Year',
    field: "YEAR"
  },
  {
    title: 'Main Activity',
    field: "MAIN_ACTIVITY"
  }
]

export const tabulatorNoticeCols = [{
    title: 'Name',
    field: 'TED_NOTICE_URL',
    formatter: "link",
    width: 150,
    formatterParams: {
      urlPrefix: "https://",
      target: "_blank"
    }
  },
  {
    // title: 'Seller',
    title: 'Buyer',
    field: 'CAE_NAME',
    hozAlign: 'left'
  },
  {
    // title: 'Seller Country',
    title: 'Buyer Country',
    field: 'ISO_COUNTRY_CODE',
    formatter: function (cell) {
      if (cell.getValue() === "UK") {
        return "United Kingdom"
      } else if (countries[cell.getValue()]) {
        return countries[cell.getValue()]
      } else {
        return cell.getValue()
      }
    }
  },
  {
    title: 'Start Date',
    field: 'CONTRACT_START',
    formatter: function (cell) {
      if (cell.getValue() === "01-01-1980") {
        return "N/A"
      } else {
        return cell.getValue()
      }
    }
  },
  {
    title: 'End Date',
    field: 'CONTRACT_COMPLETION',
    formatter: function (cell) {
      if (cell.getValue() === "01-01-1980") {
        return "N/A"
      } else {
        return cell.getValue()
      }
    }
  },
  {
    title: 'Amount',
    field: 'VALUE_EURO_FIN_1',
    formatter: function (cell) {
      return cell.getValue() === -1 ? "N/A" : cell.getValue() + ' €'
    }
  },
  {
    title: 'Aprox Amount',
    field: 'VALUE_EURO_FIN_1',
    formatter: function (cell) {
      let value = cell.getValue()
      if (value === -1) {
        return "N/A"
      }
      if (Math.abs(Number(value)) > 1e15) {
        value = Math.floor((Math.abs(Number(value)) / 1e15) * 100) / 100 + "Q"
      } else if (Math.abs(Number(value)) > 1e12) {
        value = Math.floor((Math.abs(Number(value)) / 1e12) * 100) / 100 + "T"
      } else if (Math.abs(Number(value)) > 1e9) {
        value = Math.floor((Math.abs(Number(value)) / 1e9) * 100) / 100 + "B"
      } else if (Math.abs(Number(value)) > 1e6) {
        value = Math.floor((Math.abs(Number(value)) / 1e6) * 100) / 100 + "M"
      } else if (Math.abs(Number(value)) > 1e3) {
        value = Math.floor((Math.abs(Number(value)) / 1e3) * 100) / 100 + "K"
      }
      return value + ' €'
    }
  },
  {
    title: 'Year',
    field: "YEAR"
  },
  {
    title: 'Main Activity',
    field: "MAIN_ACTIVITY"
  }
]

export const TabulatorNoticeAndAwarded = [{
    title: 'Name',
    field: 'TED_NOTICE_URL',
    formatter: "link",
    width: 150,
    formatterParams: {
      urlPrefix: "https://",
      target: "_blank"
    }
  },
  {
    title: 'Seller',
    // field: 'CAE_NAME',
    field: 'WIN_NAME',

    hozAlign: 'left'
  },
  {
    title: 'Seller Country',
    // field: 'ISO_COUNTRY_CODE',
    field: 'WIN_COUNTRY_CODE',

    formatter: function (cell) {
      if (cell.getValue() === "UK") {
        return "United Kingdom"
      } else if (countries[cell.getValue()]) {
        return countries[cell.getValue()]
      } else {
        return cell.getValue()
      }
    }
  },
  {
    title: 'Buyer',
    // field: 'WIN_NAME'
    field: 'CAE_NAME',

  },
  {
    title: 'Buyer Country',
    field: 'ISO_COUNTRY_CODE',

    // field: 'WIN_COUNTRY_CODE',
    formatter: function (cell) {
      if (!cell.getValue()) {
        return "Not awarded"
      }
      if (cell.getValue() === "UK") {
        return "United Kingdom"
      } else if (countries[cell.getValue()]) {
        return countries[cell.getValue()]
      } else {
        return cell.getValue()
      }
    }
  },
  {
    title: 'Start Date',
    field: 'DT_AWARD',
    formatter: function (cell) {
      if (cell.getValue() === "01-01-1980") {
        return "N/A"
      } else {
        return cell.getValue()
      }
    }
  },
  {
    title: 'End Date',
    field: 'DT_DISPATCH',
    formatter: function (cell) {
      if (cell.getValue() === "01-01-1980") {
        return "N/A"
      } else {
        return cell.getValue()
      }
    }
  },
  {
    title: 'Amount',
    field: 'AWARD_VALUE_EURO',
    formatter: function (cell) {
      return cell.getValue() === -1 ? "N/A" : cell.getValue() + ' €'
    }
  },
  {
    title: 'Aprox Amount',
    field: 'VALUE_EURO_FIN_1',
    formatter: function (cell) {
      let value = cell.getValue()
      if (Math.abs(Number(value)) > 1e15) {
        value = Math.floor((Math.abs(Number(value)) / 1e15) * 100) / 100 + "Q"
      } else if (Math.abs(Number(value)) > 1e12) {
        value = Math.floor((Math.abs(Number(value)) / 1e12) * 100) / 100 + "T"
      } else if (Math.abs(Number(value)) > 1e9) {
        value = Math.floor((Math.abs(Number(value)) / 1e9) * 100) / 100 + "B"
      } else if (Math.abs(Number(value)) > 1e6) {
        value = Math.floor((Math.abs(Number(value)) / 1e6) * 100) / 100 + "M"
      } else if (Math.abs(Number(value)) > 1e3) {
        value = Math.floor((Math.abs(Number(value)) / 1e3) * 100) / 100 + "K"
      }
      return value + ' €'
    }
  },
  {
    title: 'Year',
    field: "YEAR"
  },
  {
    title: 'Main Activity',
    field: "MAIN_ACTIVITY"
  }
]

// export const tabulatorNoticeCols = [{
//     title: 'Name',
//     field: 'TED_NOTICE_URL',
//     formatter: "link",
//     width: 150,
//     formatterParams: {
//       urlPrefix: "https://",
//       target: "_blank"
//     }
//   },
//   {
//     title: 'Seller',
//     field: 'CAE_NAME',
//     hozAlign: 'left'
//   },
//   {
//     title: 'Seller Country',
//     field: 'ISO_COUNTRY_CODE'
//   },
//   {
//     title: 'Start Date',
//     field: 'CONTRACT_START'
//   },
//   {
//     title: 'End Date',
//     field: 'CONTRACT_COMPLETION'
//   },
//   {
//     title: 'Amount',
//     field: 'VALUE_EURO_FIN_1',
//     formatter: function (cell) {
//       return cell.getValue() == -1 ? "N/A" : cell.getValue()
//     }
//   },
//   {
//     title: 'Year',
//     field: "YEAR"
//   },
//   {
//     title: 'Main Activity',
//     field: "MAIN_ACTIVITY"
//   }
// ]

export const noticeColumns = {
  "ID_NOTICE_CN": "Notice Id",
  "TED_NOTICE_URL": "Notice URL",
  "YEAR": "Year",
  "ID_TYPE": "Type ID",
  "DT_DISPATCH": "Dispatch Date",
  "XSD_VERSION": "XML Schema Version",
  "CANCELLED": "Cancelled",
  "CORRECTIONS": "Corrections",
  "FUTURE_CAN_ID": "Publication ID of CAN which followed this Notice",
  "FUTURE_CAN_ID_ESTIMATED": "Publication ID was estimated for this dataset",
  "B_MULTIPLE_CAE": "Multiple Buyers",
  "CAE_NAME": "Buyer",
  "CAE_NATIONALID": "National Registration Number (Seller)",
  "CAE_ADDRESS": "Buyer Address",
  "CAE_TOWN": "Buyer Town",
  "CAE_POSTAL_CODE": "Buyer Postal Code",
  "ISO_COUNTRY_CODE": "First listed authority", //check this
  "B_MULTIPLE_COUNTRY": "Contracting authorities from at least two different countries",
  "ISO_COUNTRY_CODE_ALL": "Countries of authorities",
  "CAE_TYPE": "Type of contracting authority", // SEE 1
  "EU_INST_CODE": "EU institution", // SEE 2
  "MAIN_ACTIVITY": "Areas of activity",
  "B_ON_BEHALF": "Central or Several buyers",
  "B_INVOLVES_JOINT_PROCUREMENT": "Involves joint procurement",
  "B_AWARDED_BY_CENTRAL_BODY": "The contract is awarded by a central body",
  "TYPE_OF_CONTRACT": "Type of contract", //see 3
  "TAL_LOCATION_NUTS": "Nomenclature of Territorial Units for Statistics",
  "B_FRA_AGREEMENT": "Framework Agreement", // see 4
  "FRA_ESTIMATED": "Framework Indications", // see 5
  "B_FRA_SINGLE_OPERATOR": "Framework Operator", // Y Framework agreement with a single operators, N Framework agreement with several operators
  "FRA_NUMBER_OPERATORS": "Number of participants to the framework agreement envisaged",
  "FRA_NUMBER_MAX_OPERATORS": "Max Number of participants to the framework agreement envisaged",
  "B_DYN_PURCH_SYST": "Involves contract(s) based on a dynamic purchasing system",
  "CPV": "CPV",
  "ID_LOT": "Lot identifier",
  "ADDITIONAL_CPVS": "Additional CPV", // CHECK CPVS
  "B_GPA": "Contract is covered by Government Procurement Agreement",
  "LOTS_NUMBER": "Lots number of given CAN",
  "LOTS_SUBMISSION": "Tenders may be submitted for", // -A all lots, O one lot, M multiple lots
  "B_VARIANTS": "Variants will be accepted",
  "VALUE_EURO": "CAN value (Euro w/o VAT)",
  "VALUE_EURO_FIN_1": "CAN FIN_1 value (Euro w/o VAT)",
  "VALUE_EURO_FIN_2": "CAN FIN_2 (Euro w/o VAT)",
  "B_OPTIONS": "Contract Options",
  "B_EU_FUNDS": "Contract is related to a project and/or programme financed by EU funds",
  "B_RENEWALS": "This contract is subject to renewal",
  "DURATION": "Duration of the contract framework agreement or dynamic purchasing (months)",
  "CONTRACT_START": "Time limit for completion start",
  "CONTRACT_COMPLETION": "Time limit for completion end",
  "TOP_TYPE": "Type of procedure", // see 7
  "B_ACCELERATED": "Option to accelerate the procedure has been used",
  "ENV_OPERATORS": "Envisaged number of operators",
  "ENV_MIN_OPERATORS": "Envisaged minimum number",
  "ENV_MAX_OPERATORS": "Envisaged maximum number",
  "CRIT_CODE": "Award Criteria", // L lowest price , M Most economically advantageous
  "CRIT_PRICE_WEIGHT": "Weight given to price",
  "CRIT_CRITERIA": "Award Criteria",
  "CRIT_WEIGHTS": "Criteria Weighing",
  "B_ELECTRONIC_AUCTION": "Electronic auction has been used",
  "DT_APPLICATIONS": "Time limit for receipt of tenders or requests to participate",
  "B_LANGUAGE_ANY_EC": "Language(s) in which tenders or requests to participate may be drawn up",
  "ADMIN_LANGUAGES_TENDER": "Language(s) in which tenders or requests to participate may be drawn up (EU)",
  "ADMIN_OTHER_LANGUAGES_TENDER": "Language(s) in which tenders or requests to participate may be drawn up (Other)",
  "B_RECURRENT_PROCUREMENT": "Recurrent procurement"
}

export const awardColumns = {
  "ID_NOTICE_CAN": "Notice Id",
  "TED_NOTICE_URL": "Notice URL",
  "YEAR": "Year",
  "ID_TYPE": "Type ID", //Check this
  "DT_DISPATCH": "Dispatch Date",
  "XSD_VERSION": "XML Schema Version ",
  "CANCELLED": "Cancelled",
  "CORRECTIONS": "Corrections",
  "B_MULTIPLE_CAE": "Multiple Buyer",
  "CAE_NAME": "Buyer",
  "CAE_NATIONALID": "National Registration Number (Buyer)",
  "CAE_ADDRESS": "Buyer Address",
  "CAE_TOWN": "Buyer Town",
  "CAE_POSTAL_CODE": "Buyer Postal Code",
  "CAE_GPA_ANNEX": "Buyer Classification (According commitments of the EU) ",
  "ISO_COUNTRY_CODE": "First listed authority", //check this
  "ISO_COUNTRY_CODE_GPA": "Legal country of first listed authority",
  "B_MULTIPLE_COUNTRY": "Contracting authorities from at least two different countries",
  "ISO_COUNTRY_CODE_ALL": "Countries of authorities",
  "CAE_TYPE": "Type of contracting authority", // SEE 1
  "EU_INST_CODE": "EU institution", // SEE 2
  "MAIN_ACTIVITY": "Areas of activity",
  "B_ON_BEHALF": "Central or Several buyers",
  "B_INVOLVES_JOINT_PROCUREMENT": "Involves joint procurement",
  "B_AWARDED_BY_CENTRAL_BODY": "The contract is awarded by a central body",
  "TYPE_OF_CONTRACT": "Type of contract", //see 3
  "TAL_LOCATION_NUTS": "Nomenclature of Territorial Units for Statistics",
  "B_FRA_AGREEMENT": "Framework Agreement", // see 4
  "FRA_ESTIMATED": "Framework Indications", // see 5
  "B_FRA_CONTRACT": "Within Framework agreement",
  "B_DYN_PURCH_SYST": "Involves contract(s) based on a dynamic purchasing system",
  "CPV": "CPV",
  "MAIN_CPV_CODE_GPA": "CPV code of the main object of the contract",
  "ID_LOT": "Lot identifier",
  "ADDITIONAL_CPVS": "Additional CPV", // CHECK CPVS
  "B_GPA": "Contract is covered by Government Procurement Agreement",
  "GPA_COVERAGE": "GPA Information", //see 6
  "LOTS_NUMBER": "Lots number of given CAN",
  // "LOTS_SUBMISSION":"Tenders may be submitted for", // -A all lots, O one lot, M multiple lots
  // "B_VARIANTS":"Variants will be accepted",
  "VALUE_EURO": "CAN value (Euro w/o VAT)",
  "VALUE_EURO_FIN_1": "CAN FIN_1 value (Euro w/o VAT)",
  "VALUE_EURO_FIN_2": "CAN FIN_2 (Euro w/o VAT)",
  "B_EU_FUNDS": "Contract is related to a project and/or programme financed by EU funds",
  "TOP_TYPE": "Type of procedure", // see 7
  "B_ACCELERATED": "Option to accelerate the procedure has been used",
  "OUT_OF_DIRECTIVES": "Procurement falls outside the scope of application of the directive but CAN published",
  "CRIT_CODE": "Award Criteria", // L lowest price , M Most economically advantageous
  "CRIT_PRICE_WEIGHT": "Weight given to price",
  "CRIT_CRITERIA": "Award Criteria",
  "CRIT_WEIGHTS": "Criteria Weighing",
  "B_ELECTRONIC_AUCTION": "Electronic auction has been used",
  "NUMBER_AWARDS": "CAs for given CAN",
  "ID_AWARD": "Contract award ID",
  "ID_LOT_AWARDED": "Lot No",
  "INFO_ON_NON_AWARD": "Award info", // see 8
  // "INFO_UNPUBLISHED",
  "B_AWARDED_TO_A_GROUP": "Contract has been awarded to a group of economic operators",
  "WIN_NAME": "Seller",
  "WIN_NATIONALID": "National Registration Number (Buyer)",
  "WIN_ADDRESS": "Seller address",
  "WIN_TOWN": "Seller town",
  "WIN_POSTAL_CODE": "Seller postal code",
  "WIN_COUNTRY_CODE": "Seller country",
  "B_CONTRACTOR_SME": "Contractor is an SME",
  "CONTRACT_NUMBER": "Contract No",
  "TITLE": "Title",
  "NUMBER_OFFERS": "Number of tenders received",
  "NUMBER_TENDERS_SME": "Number of tenders received SMEs",
  "NUMBER_TENDERS_OTHER_EU": "Number of tenders received from tenderers from other EU Member States",
  "NUMBER_TENDERS_NON_EU": "Number of tenders received from tenderers from non-EU Member States",
  "NUMBER_OFFERS_ELECTR": "Number of offers received by electronic means",
  "AWARD_EST_VALUE_EURO": "Estimated CA value (Euro w/o VAT)",
  "AWARD_VALUE_EURO": "CA value (FIN 1 EURO w/o VAT)",
  "AWARD_VALUE_EURO_FIN_1": "Final CA value (FIN 1 EURO w/o VAT)",
  "B_SUBCONTRACTED": "Contract is likely to be subcontracted",
  "DT_AWARD": "Awarded date"
}

export const contractingAuthority = {
  "1": "Ministry or any other national or federal authority, including their regional of local subdivisions",
  "3": "Regional or local authority",
  "4": "Water, energy, transport and telecommunications sectors",
  "5": "European Union institution/agency",
  "5A": "Other international organisation",
  "6": "Body governed by public law",
  "8": "Other",
  "N": "National or federal Agency / Office",
  "R": "Regional or local Agency / Office",
  "Z": "Not specified",
  "N/A": NA
}

export const euInstCode = {
  AG: "Agencies",
  BC: "European Central Bank",
  BI: "European Investment Bank",
  BR: "European Bank for Reconstruction and Development",
  CA: "European Court of Auditors",
  CJ: "Court of Justice of the European Union",
  CL: "Council of the European Union",
  CR: "European Committee of the Regions",
  EA: "European External Action Service",
  EC: "European Commission",
  ES: "European Economic and Social Committee",
  FI: "European Investment Fund",
  OB: "European Patent Office",
  OP: "Publications office of the EU",
  PA: "European Parliament",
  "N/A": NA,
  "01": "01",
  "05": "05"
}

export const contractType = {
  "W": "Works",
  "U": "Supplies",
  "S": "Services",
  "N/A": NA
}

export const frameworkAggrement = {
  "Y": "The notice involves the establishment of a framework agreement",
  "N": "The notice does not involves the establishment of a framework agreement",
  "N/A": NA
}
// 05 ?



let K = "The keyword `framework', in the appropriate language, was found in the title or description of the notice."
let A = "Multiple awards were given per one lot, which is legally admissible only in case of framework agreements, dynamic purchasing systems, innovation partnerships, and qualification systems."
let C = "Consistency across notices: the contract notice which preceded this notice was marked as a framework agreement."
export const frameworkEstimated = {
  "K": K,
  "A": A,
  "C": C,
  "KA": K + " " + A,
  "KC": K + " " + C,
  "AC": A + " " + C,
  "KAC": K + " " + A + " " + C,
  "N/A": NA
}

export const gpaCoverage = {
  "1": "Covered by GPA",
  "2": "Entity not covered by GPA",
  "3": "Entity covered but contract not covered by GPA",
  "4": "Below-thresholds contract",
  "5": "Contracting entity is not an EU public entity",
  "N/A": NA
}

export const topType = {
  "OPE": "Open",
  "RES": "Restricted",
  "NIC": "Negotiated with a call for competition",
  "NOC": "Negotiated without a call for competition",
  "AWP": "Award without prior publication of a contract notice",
  "N/A": NA,
  "COD": "Competitive dialogue",
  "NIP": "Negotiated with a call for competition",
  "NOP": "Negotiated without a call for competition",
  "INP": "INP"
}

export const infoNonAward = {
  "AWARDED": "Contract was awarded",
  "PROCUREMENT_UNSUCCESSFUL": "Contract not awarded because No tenders or requests to participate were received or all were rejected",
  "PROCUREMENT_DISCONTINUED": "Contract not awarded"
}

export const critCode = {
  "M": "Most economically advantageous",
  "L": "Lowest price",
  "N/A": NA
}
/*
1

Type of contracting authority.
1 “Ministry or any other national or federal authority, including their
regional of local subdivisions”
3 “Regional or local authority”
4 “Water, energy, transport and telecommunications sectors”
5 "European Union institution/agency"
5A "other international organisation"
6 "Body governed by public law"
8 "Other"
N "National or federal Agency / Office"
R "Regional or local Agency / Office"
Z “Not specified"

-------------------------------------
2

EU institution (or type of EU institution).
AG "agencies"
BC "European Central Bank"
BI "European Investment Bank"
BR "European Bank for Reconstruction and Development"
CA "European Court of Auditors"
CJ "Court of Justice of the European Union"
CL "Council of the European Union"
CR "European Committee of the Regions"
EA "European External Action Service"
EC "European Commission"
ES "European Economic and Social Committee"
FI "European Investment Fund"
OB "European Patent Office"
OP "Publications office of the E

---------------------------------------------
3

Type of contract. The values are the following:
W "Works"
U "Supplies"
S "Services"

---------------------------------
4

Y if "The notice
involves the
establishment of a
framework
agreement" is selected
or "Framework
agreement with a
single operator" is
selected or
"Framework
agreement with
several operators" is
selected.
----------------------------
5

Whether there are indications that this notice is
actually about a framework agreement, even though
it has not been marked as such by the buyer (i.e. the
buyer possibly forgot to mark the field). Indications
are the following:
K "The keyword `framework', in the
appropriate language, was found in the
title or description of the notice."
A "Multiple awards were given per one lot,
which is legally admissible only in case
of framework agreements, dynamic
purchasing systems, innovation
partnerships, and qualification systems."
C "Consistency across notices: the contract
notice which preceded this notice was
marked as a framework agreement."
The use of these indications depends on how
important it is to not misclassify frameworks in your
analysis. One possible approach is to assume that
the notice has been misclassified as a framework
when at least two of the indications above are
present. [ADDED]

-----------------------------------------
6
Detailed information about whether the contract is covered by the
Government Procurement Agreement.
1 covered by GPA
2 entity not covered by GPA
3 entity covered but contract not covered by GPA
4 below-thresholds contract
5 contracting entity is not an EU public entity
(This field is only available for 2014-2016)

7
AWP “award without prior
publication of a contract notice”
COD “competitive dialogue”
NOC/NOP “negotiated without a
call for competition”
NIC/NIP “negotiated with a call for
competition”
OPE “open"
RES “restricted”

8
If the variable is empty, then a contract was
awarded.
PROCUREMENT_UNSUCCESSFUL means that
"A contract is not awarded", because "No tenders or
requests to participate were received or all were
rejected"
PROCUREMENT_DISCONTINUED means that
"A contract is not awarded" because of "Other
reasons (discontinuation of procedure)"

*/