import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import TablePagination from "@material-ui/core/TablePagination";
import { connect } from "react-redux";
import { withRouter } from "react-router";
// REDUX
import {
  createTotals,
  createMap,
  createPerField,
  searchPerField,
} from "../../redux/actions/detailsActions";

import { esthonianSearch } from "../../redux/actions/publicContracts/esthonianRegistryActions";
import EsthonianDetails from "./customDetails/esthonianDetails"
import EsthonianMap from "./customDetails/esthonianMap"


// CUSTOM SCSS
import "./Details.scss";
import "../../scss/shared/Flag.scss";

// CUSTOM COMPONENTS
import CountryInfo from "../../components/CountryInfo/CountryInfo";
import OrganisationInfo from "../../components/OrganisationInfo/OrganisationInfo";
import MapChart from "../../components/MapChart/MapChart";
import DetailsRows from "../../components/DetailsRows/DetailsRows";

// CUSTOM DATA
import { detailsTitles } from "../../services/platform/utils";

// HELPERS
import { getCountries } from "../../services/iso/countries";

class InfoDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: { data: {} },
      from: 0,
      size: 10,
      type: null,
      organisationError: "",
    };
  }

  /**
   * Load datasets after component mount
   */
  async componentDidMount() {
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ANESID DETAILS: ");
    const pathData = this.getUrlType(this.props.location.pathname);
    console.log("PATHDATA", pathData)

    this.setState({ type: pathData.type });
    let id = pathData.id;
    if (pathData.type === "COUNTRIES") {
      console.log(
        ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ANESID DETAILS COUNTRIES: ",
        pathData
      );
      this.props.createMap([{ ISO_COUNTRY_CODE: id }], "COUNTRIES");
      this.props.createTotals(
        [{ ISO_COUNTRY_CODE: id }, { WIN_COUNTRY_CODE: id }],
        this.state.from,
        this.state.size,
        "COUNTRIES"
      );
      this.props.createPerField({ ISO_COUNTRY_CODE: id }, "yearDataISO");
      this.props.createPerField({ WIN_COUNTRY_CODE: id }, "yearDataWIN");
      this.props.searchPerField(
        pathData.type,
        id,
        "SELLERS",
        this.state.from,
        this.state.size
      );
      this.props.searchPerField(
        pathData.type,
        id,
        "BUYERS",
        this.state.from,
        this.state.size
      );
    } else {
      console.log(
        ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ANESID DETAILS ORGANIZATIONS: ",
        pathData
      );
      const totalField = pathData.type === "SELLERS" ? "WIN_NAME" : "CAE_NAME";
      const mapField =
        pathData.type === "SELLERS" ? "WIN_COUNTRY_CODE" : "ISO_COUNTRY_CODE";

      const totalsResponse = await this.props.createTotals(
        [{ [totalField]: id }],
        this.state.from,
        this.state.size,
        pathData.type
      );

      if (totalsResponse && totalsResponse.data) {
        await this.props.createMap(
          [
            {
              [mapField]: totalsResponse.data.code,
            },
          ],
          pathData.type
        );
        await this.props.searchPerField(
          pathData.type,
          totalsResponse.data.name,
          null,
          this.state.from,
          this.state.size
        );

        // FETCH DETAILS FROM ESTHONIAN DATASET
        if (totalsResponse.data.code == "EE") {
          console.log("ITS ESTHONIAN!!!!", pathData)
          this.props.esthonianSearch({ cae_name: pathData.id })
        }
      } else {
        this.setState({ organisationError: "Please try again later" });
      }
    }
  }

  /**
   * Separates query's parameters to array
   *
   * @param {*} query
   */
  getUrlType = (pathName) => {
    const url = {
      type: "",
      id: "",
    };
    if (pathName.includes("countries")) {
      url.type = "COUNTRIES";
    } else if (pathName.includes("sellers")) {
      url.type = "SELLERS";
    } else if (pathName.includes("buyers")) {
      url.type = "BUYERS";
    }
    url.id = this.getUrlPathId(pathName, url.type.toLowerCase());
    return url;
  };

  getUrlPathId = (pathName, value) => {
    const urlId = pathName.replace("/" + value + "/", "");
    return urlId;
  };

  getNextSearchPage = async (type, from, value) => {
    let relationship = null;
    if (this.state.type === "COUNTRIES") {
      if (type.includes("BUYERS")) {
        relationship = "BUYERS";
      } else if (type.includes("SELLERS")) {
        relationship = "SELLERS";
      }
    }
    await this.props.searchPerField(
      this.state.type,
      value,
      relationship,
      from,
      this.state.size
    );
  };

  renderEsthonian = () => {
    // if (this.props.details.dataTotals.data) {
    //   console.log("antonismpantis", this.props.details.dataTotals.data.code)
    //   console.log("antonismpantis", this.props.esthonianReg)
    //   // this.props.esthonianSearch
    // }
    if ((this.state.type === "BUYERS" || this.state.type === "SELLERS") && this.props.details.dataTotals.data.code == "EE" && this.props.esthonianReg.data.length > 0) {
      console.log("antonismpantis", this.props.details.dataTotals.data.code)
      console.log("antonismpantis", this.props.esthonianReg)
      delete this.props.esthonianReg.data[0].contract_notices
      delete this.props.esthonianReg.data[0].contract_award_notices

      return (
        <EsthonianDetails data={this.props.esthonianReg} />
      )
    }
  }

  renderEsthonianMap = () => {
    return <EsthonianMap />
  }

  render() {
    return (
      <Container className="details">
        <Row>
          <Col md={12}>
            <h3 className="page-title">
              {this.state.type && detailsTitles[this.state.type]}
            </h3>
          </Col>
        </Row>
        <Row></Row>
        <Row className="details_container">
          <Col className="details_sidebar" md={4}>
            <div className="details_box">
              <h4>Details</h4>
              <DetailsRows
                data={this.props.details.dataTotals}
                className="no_border"
              />
            </div>
            <div className="sidebar_box">
              <MapChart
                data={this.props.details.mapData}
                onFilterUpdate={() => { }}
                selectedValue={null}
              ></MapChart>
            </div>
          </Col>
          <Col md={8}>
            <Row>
              <Col md={12}>
                {this.state.type === "COUNTRIES" ? (
                  <CountryInfo
                    info={this.props.details}
                    getNextSearchPage={this.getNextSearchPage}
                  />
                ) : (
                    <OrganisationInfo
                      info={this.props.details}
                      getNextSearchPage={this.getNextSearchPage}
                    />
                  )}
              </Col>
            </Row>


            {this.renderEsthonian()}


          </Col>

        </Row>

        {/* {this.state.type === "BUYERS" && this.props.details.dataTotals.data.code == "EE" && this.renderEsthonian()} */}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    details: state.details,
    esthonianReg: state.publicContracts.esthonianRegistry
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createTotals: (countryCode, from, size, type) =>
      dispatch(createTotals(countryCode, from, size, type)),
    createMap: (countryField, type) => dispatch(createMap(countryField, type)),
    createPerField: (field, type) => dispatch(createPerField(field, type)),
    searchPerField: (type, term, mult, from, size) =>
      dispatch(searchPerField(type, term, mult, from, size)),
    esthonianSearch: (filters) => dispatch(esthonianSearch(filters))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InfoDetails)
);
