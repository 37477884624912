const sellersHead = [
  {
    header: '',
    type: 'flag',
    attribute: 'country'
  },
  {
    header: 'Organization',
    type: 'link',
    attribute: 'key',
    link: 'sellers'
  },
  {
    header: 'Amount',
    type: 'number',
    attribute: 'amount'
  },
  {
    header: 'Total',
    type: 'number',
    attribute: 'count'
  }
];

export { sellersHead };
