const resultsTimeHead = [
  {
    header: {
      WIN_COUNTRY_CODE: 'Country',
      ISO_COUNTRY_CODE: 'Country',
      WIN_NAME: 'Buyer',
      CAE_NAME: 'Seller',
      CPV: 'CPV',
      NUMBER_TENDERS_SME: 'Received Tenders'
    },
    type: 'multiple-country',
    attribute: 'key'
  },
  {
    header: 'Amount',
    type: 'parent',
    attribute: 'amount'
  },
  {
    header: 'Total',
    type: 'parent',
    attribute: 'count'
  }
];

export { resultsTimeHead };
