import React from "react";
import { Col, Row } from "reactstrap";

// COMPONENTS
import MatTable from "../MatTable/MatTable";
import GridTable from "../GridTable/GridTable";

const OrganisationInfo = (props) => {
  const { info } = props;

  return (
    <>
      <Row>
        <Col>
          <div className="tabs tabs_results">
            <div className="tabs__wrap">
              <div
                className="result"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "10px",
                }}
              >
                {/* <MatTable
                  data={info.searchData}
                  rows={10}
                  onNextSearchPage={() => {
                    props.getNextSearchPage(
                      "searchData",
                      info.searchData.from,
                      info.dataTotals.data.name
                    );
                  }}
                  checkBox={false}
                  export={true}
                  innerPath={true}
                ></MatTable> */}

                <GridTable
                  identifier="allDataResult"
                  data={info.searchData}
                  updateSearchGrid={(identifier) => {
                    let from =
                      identifier === "next"
                        ? info.searchData.from
                        : info.searchData.from - 20;
                    props.getNextSearchPage(
                      "searchData",
                      from,
                      info.dataTotals.data.name
                    );
                  }}
                  export={true}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default OrganisationInfo;
