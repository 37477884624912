import React, { useState } from 'react';

// CUSTOM COMPONENTS
import Modal from '../Modal/Modal';
import AddDatasetForm from './AddDatasetForm';

const ModalDataset = props => {
  const [modalStatus, toggleModal] = useState(false);

  const closeModal = () => {
    toggleModal(true);
  };

  return (
    <Modal
      color="success"
      title="Save to profile"
      header
      btn="Save to profile"
      className="grey"
      close={modalStatus}
    >
      <AddDatasetForm data={props.data} closeModal={() => closeModal()} />
    </Modal>
  );
};

export default ModalDataset;
