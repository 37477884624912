import React from 'react';

// CUSTOM COMPONENTS
import Modal from '../../../../components/Modal/Modal';
import ShareTabs from './ShareTabs';

const ShareModal = ({ id, url, fields }) => {
  return (
    <Modal
      outline={false}
      title="Share"
      header
      btn="Share"
      icon="share"
      className="grey "
      close={false}
    >
      {/* Share tabs properties could be used with {...props} but its not a 100% good practice  */}
      <ShareTabs id={id} url={url} fields={fields} />
    </Modal>
  );
};

export default ShareModal;
