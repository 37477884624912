import {
  FETCHING_API,
  MY_DATASETS,
  RESET_MY_DATASETS,
  ADD_DATASET,
  RESET_ADD_DATASET,
  DATASET_ERROR,
  RESET_DATASET_INDICATORS
} from '../actions/datasetActions';

const initialState = {
  myDatasets: {
    datasets: [],
    fetched: null
  },
  dataset: { title: '', type: '', access_level: '', tags: [], data: [] },
  loading: false,
  error: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCHING_API:
      return {
        ...state,
        loading: action.payload
      };
    case MY_DATASETS:
      return {
        ...state,
        myDatasets: {
          ...state.myDatasets,
          datasets: action.payload,
          fetched: new Date().getTime()
        }
      };
    case RESET_MY_DATASETS:
      return {
        ...state,
        myDatasets: {
          ...state.myDatasets,
          datasets: [],
          fetched: null
        }
      };
    case ADD_DATASET:
      return {
        ...state,
        dataset: {
          ...state.dataset,
          title: action.payload.dataset.title,
          type: action.payload.dataset.type,
          access_level: action.payload.dataset.access_level,
          tags: action.payload.dataset.tags,
          data: action.payload.dataset.data
        },
        myDatasets: {
          ...state.myDatasets,
          datasets: [...state.myDatasets.datasets, action.payload.dataset]
        }
      };
    case RESET_ADD_DATASET:
      return {
        ...state,
        dataset: {
          ...state.dataset,
          title: '',
          type: '',
          access_level: '',
          tags: [],
          data: []
        }
      };
    case DATASET_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case RESET_DATASET_INDICATORS:
      return {
        ...state,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
