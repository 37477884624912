import React from 'react';
import './ErrorInput.scss';
/**
 * Simple return of error message
 * @param {*} props
 */
const ErrorInput = props => {
  return (
    <>
      <span className="input_field_error">{props.errorMessage}</span>
    </>
  );
};

export default ErrorInput;
