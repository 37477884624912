import axios from "axios";

import authStorage from "../authentication/authStorage";
import { mapRoutes } from "./apiRoutes";

/** No auth routes */
const noAuthRoutes = ["/auth/", "/visualization/"];

/** Backend URL */
const my_backend = process.env.REACT_APP_BACKEND_URL
  ? process.env.REACT_APP_BACKEND_URL
  : "http://localhost:3001/";

/**
 * AXIOS CONFIG
 * ds_axiosInstance: Data Stories axios instance
 */
const ds_axiosInstance = axios.create({
  baseURL: my_backend,
});

/**
 * INTERCEPTORS
 */
ds_axiosInstance.interceptors.request.use(
  async (config) => {
    config.headers["Content-type"] = "application/json";

    /** Check if noAuthRoutes contained in request url */
    var noAuth = false;
    for (var route of noAuthRoutes) {
      /** Assign the noAuth value only if it is false, else keep same value (true) */
      noAuth = !noAuth ? config.url.includes(route) : noAuth;
    }

    /** Include JWT token in request */
    if (!noAuth) {
      const token = await authStorage.getAuth("auth");
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      } else {
        console.log(
          "\x1b[35m%s\x1b[0m",
          "[DS REQUEST] \n",
          "Authorization required for this kind of action ☄️"
        );
        return false;
      }
    }
    console.log("\x1b[35m%s\x1b[0m", "[DS REQUEST] \n", config.url);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
ds_axiosInstance.interceptors.response.use(
  (response) => {
    console.log("\x1b[35m%s\x1b[0m", "[DS RESPONSE] \n", response);
    return response;
  },
  (error) => {
    return error.response;
  }
);

/** Global API sender/responder */
export const apiRequest = async (request) => {
  const reqParams = {
    ...mapRoutes[request.type],
  };
  request.data = request.data ? request.data : "";

  reqParams.url = request.dynamic_url
    ? reqParams.url + request.dynamic_url
    : reqParams.url;
  // console.log(
  //   ">>>>>>>>>>>>>>>>>>>>> ANESID API URL TO HIT: ",
  //   reqParams.url,
  //   request.data
  // );
  return ds_axiosInstance[reqParams.method](reqParams.url, request.data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
