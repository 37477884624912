import React from 'react';
import LoginCard from '../../components/Login/LoginCard';

const LogIn = () => (
  <div className="account account--photo">
    <LoginCard />
  </div>
);

export default LogIn;
