import React, { PureComponent } from "react";

// CUSTOM COMPONENTS
import Loader from "../Loader/Loader";
import Error from "../Error/Error";
import { Button } from "reactstrap";
import ExportSheet from "../ExportSheet/ExportSheet";
import ReactDataGrid from "react-data-grid";
//import 'react-data-grid/dist/react-data-grid.css';
//import "./react-data-grid.css";

// CUSTOM DATA

// COUNTRIES ISO CODES
var countriesIso = require("i18n-iso-countries");
countriesIso.registerLocale(require("i18n-iso-countries/langs/en.json"));

// CUSTOM SCSS
const ROW_COUNT = 50;

export default class GridTable extends PureComponent {
  state = {
    columns: [],
    rows: [],
    totalRows: [],
    from: 10,
    total: null,
    data: [],
    heads: [],
    identifier: null,
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (
      this.props.identifier === "buyerCountriesResult" ||
      this.props.identifier === "sellerCountriesResult" ||
      this.props.identifier === "allDataResult" ||
      this.props.identifier === "CPVsCountriesResult"
    ) {
      if (JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
        if (this.props.data && this.props.data.data) {
          //console.log("anesid_inside_Grid: ", this.props);
          //
          // Structure the Column Headers
          //
          let columns = this.props.data.view.map((column) => {
            return {
              key: column.header.split(" ").join(""),
              name: column.header,
              resizable: true,
              sortable: true,
              width: 120,
            };
          });
          columns.unshift({
            key: "id",
            sortable: true,
            name: "ID",
            resizable: true,
            width: 80,
          });
          columns.push({
            key: "amountAprox",
            sortable: true,
            name: "Aprox. Amount (€)",
            resizable: true,
            width: 140,
          });
          columns = columns.map((col) => {
            if (col.key === "StartDate") {
              col.key = "AwardDate";
              col.name = "Award Date";
              col.width = 100;
            } else if (col.key === "EndDate") {
              col.key = "DispatchDate";
              col.name = "Dispatch Date";
              col.width = 120;
            } else if (col.key === "Name") {
              col.formatter = this.LinkFormater;
            } else if (col.key === "id") {
              col.formatter = this.LinkFormaterId;
            } else if (col.key === "Amount") {
              col.name = "Amount (€)";
            } else if (
              col.key === "SellerCountry" ||
              col.key === "BuyerCountry"
            ) {
              col.width = 108;
              col.formatter =
                col.key === "SellerCountry"
                  ? this.SellerCountryFormatter
                  : this.BuyerCountryFormatter;
            } else if (col.key === "Seller") {
              col.formatter = this.SellerFormatter;
            } else if (col.key === "Buyer") {
              col.formatter = this.BuyerFormatter;
            }
            return col;
          });

          //
          // Structure the Data Rows. In case of undefined data make the proper data adjustments
          //
          let rows = this.props.data.data.map((row) => {
            return {
              id:
                (row.ID_AWARD !== undefined && row.ID_AWARD !== -1 )
                  ? [row.ID_AWARD, row.TED_NOTICE_URL]
                  : ["N/A", " "],
              Name:
                row.TITLE !== undefined
                  ? [row.TITLE, row.TED_NOTICE_URL]
                  : [" ", " "],
              Seller: (row.WIN_NAME !== undefined && row.WIN_NAME !== "N/A") ? row.WIN_NAME : ["N/A"],
              SellerCountry:
                (row.WIN_COUNTRY_CODE !== undefined) ? row.WIN_COUNTRY_CODE : " ",
              Buyer: row.CAE_NAME !== undefined ? row.CAE_NAME : " ",
              BuyerCountry:
                row.ISO_COUNTRY_CODE !== undefined ? row.ISO_COUNTRY_CODE : " ",
              AwardDate: (row.DT_AWARD !== undefined && row.DT_AWARD !== "1980-01-01") ? row.DT_AWARD : "N/A",
              DispatchDate:
                row.DT_DISPATCH !== undefined ? row.DT_DISPATCH : "N/A",
              Amount: row.hasOwnProperty("AWARD_VALUE_EURO")
                ? row.AWARD_VALUE_EURO === -1
                  ? "N/A"
                  : row.AWARD_VALUE_EURO.toLocaleString()
                : "N/A",
              amountAprox: row.hasOwnProperty("AWARD_VALUE_EURO")
                ? row.AWARD_VALUE_EURO === -1
                  ? "N/A"
                  : this.formatCurrencies(row.AWARD_VALUE_EURO).toLocaleString()
                : "N/A",
            };
          });

          console.log("anesid anesid anesid: ", rows);
          //
          // Structure the Headers and Data for Sheet Export
          //
          let { heads } = this.createHeads(this.props.data);
          // console.log("data from state", this.state.data);
          let data = this.state.data;
          const newData = this.props.data.data.map((data, index) => {
            if (data.group) {
              return data.data;
            } else {
              let formatted_data = {
                id: data.id ? data.id : "data-" + index,
              };
              this.props.data.view.forEach((element, index) => {
                formatted_data = this.createDataElement(
                  formatted_data,
                  data,
                  element
                );
              });
              return formatted_data;
            }
          });

          //console.log("This is the already existing data", data);
          //console.log("This is the new  data", newData);
          data = this.props.data.from === 10 ? newData : data.concat(newData);
          //console.log("After the merge: ", data);
          //Add another Amount column with approx value in the heads ans subheads.
          heads.splice(7, 0, {
            disablePadding: false,
            id: "amountAprox",
            label: "Amount (~)",
            type: "string",
          });
          // Add another Amount column with approx value in the data.
          data = data.map((d) => {
            if (d.AWARD_VALUE_EURO === undefined) {
              d.AWARD_VALUE_EURO = "N/A";
              return d;
            } else {
              if (d.AWARD_VALUE_EURO === -1) {
                d.amountAprox = "N/A";
                d.AWARD_VALUE_EURO = "N/A";

                return d;
              } else {
                d.amountAprox = this.formatCurrencies(d.AWARD_VALUE_EURO);
                d.AWARD_VALUE_EURO = d.AWARD_VALUE_EURO.toLocaleString();

                return d;
              }
            }
          });
          // Set  the Components state to store the heads and data for the tabe and the sheet.
          this.setState({
            columns,
            rows,
            from: this.props.data.from,
            total: this.props.data.total,
            heads,
            data,
            identifier: this.props.identifier,
          });
        } else {
          //   this.setState({});
        }
        this.setState({
          isLoading: this.props.data.fetching,
          error: this.props.data.error,
        });
      }
    }
  }

  LinkFormater = (obj) => {
    //console.log("ANESID ANESID ANESID bANESID", obj);
    return (
      <span
        dangerouslySetInnerHTML={{
          __html:
            '<a href="http://' +
            obj.row.Name[1] +
            '" target="_blank">' +
            obj.row.Name[0] +
            "</a>",
        }}
      />
    );
  };

  LinkFormaterId = (obj) => {
    //console.log("ANESID ANESID ANESID bANESID", obj);
    return (
      <span
        dangerouslySetInnerHTML={{
          __html:
            '<a href="http://' +
            obj.row.id[1] +
            '" target="_blank">' +
            obj.row.id[0] +
            "</a>",
        }}
      />
    );
  };

  SellerCountryFormatter = (obj) => {
    //console.log("ANESID ANESID ANESID bANESID", obj);
    let country = countriesIso.getName(obj.row.SellerCountry[0], "en");
    country = country ? country : obj.row.SellerCountry;
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: `<a href=\"/countries/${obj.row.SellerCountry}\">${country}</a>`,
        }}
      />
    );
  };

  SellerFormatter = (obj) => {
    //console.log("ANESID ANESID ANESID bANESID", obj);

    return (
      <span
        dangerouslySetInnerHTML={{
          __html: `<a href=\"/sellers/${obj.row.Seller[0]}\">${obj.row.Seller[0]}</a>`,
        }}
      />
    );
  };

  BuyerCountryFormatter = (obj) => {
    let country = null;
    if (obj.row.BuyerCountry[0] !== "N/A") {
      country = countriesIso.getName(obj.row.BuyerCountry, "en");
      country = country ? country : obj.row.BuyerCountry;
    } else {
      country = "N/A";
    }
    //console.log("Country: ", country);

    //console.log("ANESID ANESID ANESID bANESID", obj);
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: `<a href=\"/countries/${obj.row.BuyerCountry}\">${country}</a>`,
        }}
      />
    );
  };

  BuyerFormatter = (obj) => {
    //console.log("ANESID ANESID ANESID bANESID", obj);

    return (
      <span
        dangerouslySetInnerHTML={{
          __html: `<a href=\"/buyers/${obj.row.Buyer[0]}\">${obj.row.Buyer[0]}</a>`,
        }}
      />
    );
  };

  createHeads = (data) => {
    const heads = [];
    const subheads = [];
    data.view.forEach((element, index) => {
      let label = "";
      if (element.type.indexOf("multiple") > -1) {
        label = element.header[this.props.resultsType];
      } else {
        label = element.header;
      }
      if (
        element.header ||
        (element.attribute === "code" && data.id === "graphResults") ||
        element.type === "flag"
      ) {
        heads.push({
          id: element.attribute ? element.attribute : "head-" + index,
          label: label,
          type: element.type,
          disablePadding: false,
        });
        const subheadsRow = [];
        if (element.type === "parent") {
          this.setState({ subhead: true });
          data.data[0][element.attribute].forEach((subElement) => {
            subheadsRow.push({
              id: element.attribute + "-" + subElement.key,
              label: subElement.key,
              disablePadding: false,
            });
          });
        }
        subheads.push(subheadsRow);
      }
    });
    return { heads, subheads };
  };

  createDataElement = (formatted_data, data, element) => {
    let text = data[element.attribute];
    if (element.type === "date" && text) {
      text = new Date(text).toLocaleDateString();
    }
    if (element.type.indexOf("country") > -1) {
      const country = countriesIso.getName(text, "en");
      text =
        '<a href="/countries/' +
        text +
        '">' +
        (country ? country : text) +
        "</a>";
    }
    if (element.type === "flag") {
      text =
        '<span class="flag"><img src="" class="flag flag-' +
        text +
        '" /></span>';
    }
    if (element.url) {
      if (!text) {
        text = "(missing)";
      }
      let url = data[element.url];
      var pattern = /^((http|https|ftp):\/\/)/;
      if (!pattern.test(url)) {
        url = "http://" + url;
      }
      formatted_data[element.attribute] =
        '<a href="' + url + '" target="_blank">' + text + "</a>";
    } else {
      formatted_data[element.attribute] = text;
    }
    return formatted_data;
  };

  // Format large numbers into K Million, Billions etc by adding a digit in the end of the umber in string format
  formatCurrencies = (amount) => {
    // 15 Zeroes for Quadrillions
    return Math.abs(Number(amount)) >= 1.0e15
      ? Math.round(Math.abs(Number(amount)) / 1.0e15).toLocaleString() + " Q"
      : // 12 Zeroes for Trillions
      Math.abs(Number(amount)) >= 1.0e12
      ? Math.round(Math.abs(Number(amount)) / 1.0e12).toLocaleString() + " T"
      : // 9 Zeroes for Millions
      Math.abs(Number(amount)) >= 1.0e9
      ? Math.round(Math.abs(Number(amount)) / 1.0e9).toLocaleString() + " B"
      : // 6 Zeroes for Millions
      Math.abs(Number(amount)) >= 1.0e6
      ? Math.round(Math.abs(Number(amount)) / 1.0e6).toLocaleString() + " M"
      : // 3 Zeroes for Thousands
      Math.abs(Number(amount)) >= 1.0e3
      ? Math.round(Math.abs(Number(amount)) / 1.0e3).toLocaleString() + " K"
      : Math.round(Math.abs(Number(amount)).toLocaleString());
  };

  sortRows = (initialRows, sortColumn, sortDirection) => (rows) => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };
    return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
  };

  sort = (sortColumn, sortDirection) => {
    let rows = this.sortRows(this.state.rows, sortColumn, sortDirection);
    this.setState({ rows });
  };

  fetchPrev = () => {
    //console.log("previous");
    if (
      this.state.identifier === "buyerCountriesResult" ||
      this.props.identifier === "sellerCountriesResult" ||
      this.props.identifier === "allDataResult" ||
      this.props.identifier === "CPVsCountriesResult"
    ) {
      this.props.updateSearchGrid("previous");
    }
  };

  fetchNext = () => {
    //console.log("next");
    if (
      this.state.identifier === "buyerCountriesResult" ||
      this.props.identifier === "sellerCountriesResult" ||
      this.props.identifier === "allDataResult" ||
      this.props.identifier === "CPVsCountriesResult"
    ) {
      this.props.updateSearchGrid("next");
    }
  };

  render() {
    // console.log("anesid Inside Grid State: ", this.state);
    // console.log("anesid Inside Range Props: ", this.props);
    return (
      <div>
        {this.props.data && (
          <div className="card__title">
            <h5 className="bold-text">Contracts</h5>
          </div>
        )}
        <Loader loading={this.state.isLoading} simple={true} />
        {!this.state.isLoading && (
          <>
            <Error error={this.state.error} />
            {this.state.rows.length > 0 && (
              <>
                <ReactDataGrid
                  className = {"rdg-light"}
                  enableCellSelect={true}
                  columns={this.state.columns}
                  rows={this.state.rows}
                  rowsCount={ROW_COUNT}
                  height={387}
                  rowGetter={(i) => this.state.rows[i]}
                  onColumnResize={(idx, width) => console.log(``)}
                  onGridSort={(sortColumn, sortDirection) =>
                    this.sort(sortColumn, sortDirection)
                  }
                />
                <div className="row" style={{ marginTop: "10px" }}>
                  <div style={{ marginLeft: "20px" }}>
                    <div>
                      <Button
                        disabled={this.state.from === 10 ? true : false}
                        color="success"
                        outline
                        size="sm"
                        onClick={this.fetchPrev}
                      >
                        Back
                      </Button>
                    </div>
                  </div>
                  <div style={{ marginTop: "6px", marginLeft: "12px" }}>
                    {this.state.from - 9}-{this.state.from} of{" "}
                    {this.state.total}
                  </div>
                  <div style={{ textAlign: "right", marginLeft: "12px" }}>
                    <div>
                      <Button
                        disabled={
                          this.state.from >= this.state.total ? true : false
                        }
                        color="success"
                        outline
                        size="sm"
                        onClick={this.fetchNext}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "right",
                    marginLeft: "12px",
                    marginTop: "-55px",
                  }}
                >
                  {this.props.export && (
                    <div className="d-flex align-items-center justify-content-end">
                      <ExportSheet
                        head={this.state.heads}
                        rows={this.state.data}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  }
}
