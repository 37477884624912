import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';

// CUSTOM COMPONENTS
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error/Error';
import Visualization from '../../components/Visualization/Visualization';

// Auth Service
import authStorage from '../../services/authentication/authStorage';

// Action Function Dispatchers
import {
  getVisualization,
  resetVisualization
} from '../../redux/actions/visualizationActions';
import { getResult } from '../../redux/actions/publicContracts/resultActions';

// SCSS
import './Visualizations.scss';

class Visualizations extends Component {
  constructor() {
    super();
    this.state = {
      filters: { data: {} },
      noResult: false
    };
  }
  /**
   * Load visualization after component mount
   */
  async componentDidMount() {
    if (this.props.match.params.id) {
      var id = this.props.match.params.id;
      await this.loadVisualization(id);
      /** load single visualization data */
    }
  }

  loadVisualization = async id => {
    var isLogged = !!authStorage.getAuth('auth');
    const filterData = await this.props.getVisualization(id, isLogged);
    this.setState({ filters: { data: filterData.data[0] } });
    if (filterData && filterData.data[0]) {
      await this.props.getResult(filterData.data[0], 0);
    } else {
      this.setState({ noResult: true });
    }
  };

  render() {
    return (
      <Container className="dashboard">
        {this.props.visualization.title && (
          <Row>
            <Col md={12}>
              <h3 className="page-title">{this.props.visualization.title}</h3>
            </Col>
          </Row>
        )}
        <Row></Row>
        <Loader loading={this.props.loading} page={true} />
        {!this.props.loading && (
          <>
            {this.props.result.error ? (
              <Error error={this.props.result.error} page={true} />
            ) : (
              <Row className="dashboard_container">
                <Col>
                  <div className="result">
                    <Visualization
                      data={this.props.result}
                      filters={this.state.filters}
                    />
                    {this.state.noResult && (
                      <div className="empty_visualization">
                        Visualization does not exist or you do not have access
                        to it
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    visualization: state.visualization.item,
    loading: state.visualization.fetching,
    result: state.publicContracts.result
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getVisualization: (id, access) => dispatch(getVisualization(id, access)),
    resetVisualization: () => dispatch(resetVisualization()),
    getResult: payload => dispatch(getResult(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Visualizations);
