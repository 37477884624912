import { apiRequest } from "../../../services/api/index";

import { fetchData, setData, setError } from "./publicContractsActions";

// CUSTOM DATA
import { sellersHead } from "../../../services/helpers/sellersHead";

export function getSellers(filters, from) {
  return async (dispatch) => {
    dispatch(fetchData("sellers"));
    try {
      const params = {
        type: "grid",
        data: {
          type: "SELLERS",
          terms: [],
          ranges: [],
          size: 10,
          from: from,
        },
      };
      // Add selected facet's value
      if (filters.buyerCountry) {
        params.data.terms.push({
          ISO_COUNTRY_CODE: filters.buyerCountry,
        });
      }
      if (filters.sellerCountry) {
        params.data.terms.push({
          WIN_COUNTRY_CODE: filters.sellerCountry,
        });
      }
      if (filters.cpv) {
        params.data.terms.push({
          CPV: filters.cpv,
        });
      }
      // Add selected facet's range
      if (filters.year) {
        params.data.ranges.push({
          YEAR: { start: filters.year[0], end: filters.year[1] },
        });
      }
      // if (filters.number) {
      //   params.data.params.number = filters.number;
      // }
      // if (filters.amount) {
      //   params.data.params.amount = filters.amount;
      // }
      const response = await apiRequest(params);
      if (response.status === 200) {
        //console.log("YOYOYOYOYOYOYOYOYOYOYOYOYOYOY::: ", response.data.data);
        const payload = {
          data: response.data.data,
          view: sellersHead,
          total: response.data.total,
          from: from + 10,
        };
        dispatch(setData("sellers", payload));
      } else {
        dispatch(
          setError("sellers", "Something went wrong. Please try later!")
        );
      }
    } catch (error) {
      dispatch(setError("sellers", "Something went wrong. Please try later!"));
    }
  };
}
