import React, { useState, useRef, useEffect, Fragment } from "react";
import { Col, Container, Row, Button, ButtonGroup } from "reactstrap";
import Loader from '../Loader/Loader';
import { Card, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
// import { userSearch, getConnections, getRelatedNotice, getContractsBetween } from "../../redux/actions/publicContracts/searchActions";
import { getFirstCountryContracts, getSecondCountryContracts } from "../../redux/actions/countryComparison/CountryComparison";
import { AiOutlineDotChart, AiOutlineTable } from "react-icons/ai";
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';

// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/bootstrap/tabulator_bootstrap.css'; // theme
import { ReactTabulator } from 'react-tabulator';
import Boost from 'highcharts/modules/boost';
import './BubbleChart.css'

import Highcharts from "highcharts/highstock";
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
NoDataToDisplay(Highcharts);

HighchartsMore(Highcharts);

const columnsTabulator = [
  {
    title: 'Name',
    field: 'TED_NOTICE_URL',
    formatter: "link",
    width: 150,
    formatterParams: {
      urlPrefix: "https://",
      target: "_blank"
    }
  }, {
    title: 'Aprox Amount',
    field: 'AWARD_VALUE_EURO',
    formatter: function (cell) {
      let value = cell.getValue()
      if (value === -1) {
        return "N/A"
      }
      if (Math.abs(Number(value)) > 1e15) {
        value = Math.floor((Math.abs(Number(value)) / 1e15) * 100) / 100 + "Q"
      } else if (Math.abs(Number(value)) > 1e12) {
        value = Math.floor((Math.abs(Number(value)) / 1e12) * 100) / 100 + "T"
      } else if (Math.abs(Number(value)) > 1e9) {
        value = Math.floor((Math.abs(Number(value)) / 1e9) * 100) / 100 + "B"
      } else if (Math.abs(Number(value)) > 1e6) {
        value = Math.floor((Math.abs(Number(value)) / 1e6) * 100) / 100 + "M"
      } else if (Math.abs(Number(value)) > 1e3) {
        value = Math.floor((Math.abs(Number(value)) / 1e3) * 100) / 100 + "K"
      }
      return value + ' €'
    }
  },
  {
    title: 'Amount',
    field: 'AWARD_VALUE_EURO',
    formatter: function (cell) {
      return cell.getValue() == -1 ? "N/A" : cell.getValue() + ' €'
    }
  }, {
    title: 'Award Date',
    field: 'DT_AWARD',
    formatter: function (cell) {
      if (cell.getValue() === "01-01-1980") {
        return "N/A"
      } else {
        return cell.getValue()
      }
    }
  }
]

const BubbleChart = (props) => {
  const [rSelected, setRSelected] = useState(2);

  const [optionsSeries, setOptionsSeries] = useState({})
  const [load, setLoad] = useState(true)
  const dispatch = useDispatch()
  // let CountriesContract = useSelector(
  //   (state) => state.publicContracts.firstCountryContracts
  // );
  const { contracts } = props
  // useEffect(() => {
  //   let country = props.data === "UK" ? "GB" : props.data
  //   console.log("buble chart props2", country)

  //   let filters = { sellerCountry: country }
  //   if (props.data.no_country == 1) {
  //     dispatch(getFirstCountryContracts(filters, 0, "sellerCountries", 10, "CONTRACT_AWARD_NOTICES", "AWARD_VALUE_EURO"))
  //   }
  // }, [])

  useEffect(() => {
    if (contracts.length > 0) {
      let data = []
      contracts.map(contract => {
        let dataRow = {}
        dataRow["y"] = Number(contract.AWARD_VALUE_EURO) / 1e12
        dataRow["x"] = contract.YEAR
        dataRow["name"] = contract.CAE_NAME.toString()
        dataRow["date"] = contract.DT_AWARD !== '01-01-1980' ? contract.DT_AWARD : "N/A"
        data.push(dataRow)
      })
      const bubbleOption = {
        title: {
          text: ""
        },
        chart: {
          type: 'bubble',
          plotBorderWidth: 1,
          zoomType: 'xy'
        }, legend: {
          enabled: false
        },
        yAxis: {
          gridLineWidth: 1,
          title: {
            text: 'Amount'
          },
          labels: {
            format: '{value} T'
          }
        },
        xAxis: {
          startOnTick: false,
          endOnTick: false,
          title: {
            text: 'Year'
          },
          maxPadding: 0.2,
        },
        tooltip: {
          // useHTML: true,
          // headerFormat: '<table>',
          // pointFormat: '<tr><th colspan="2"><h3>{point.country}</h3></th></tr>' +
          //   '<tr><th>Fat intake:</th><td>{point.x}g</td></tr>' +
          //   '<tr><th>Sugar intake:</th><td>{point.y}g</td></tr>' +
          //   '<tr><th>Obesity (adults):</th><td>{point.name}%</td></tr>',
          // footerFormat: '</table>',
          // followPointer: true
          formatter() {
            return `<b><h1>${this.point.name}</h1></b> <br />` +
              `Date: ${this.point.date}  <br />` +
              `Amount:${this.point.y}`

          }
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}'
            }
          }
        },


        series: [{
          data: data
        }]
      }
      setOptionsSeries(bubbleOption)
    } else {

      setOptionsSeries(
        {
          title: {
            text: ""
          },
          chart: {
            type: 'bubble',
            plotBorderWidth: 1,
            zoomType: 'xy'
          }, legend: {
            enabled: false
          },
          series: [{
            data: []
          }]
        }
      )
    }
  }, [contracts])





  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={6}>
            <div className="card__title">
              <h5 className="bold-text">Bubble Chart</h5>
            </div>
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <ButtonGroup>
              <Button color="primary" onClick={() => setRSelected(1)} active={rSelected === 1}><AiOutlineTable /></Button>
              <Button color="primary" onClick={() => setRSelected(2)} active={rSelected === 2}><AiOutlineDotChart /></Button>
            </ButtonGroup>
          </Col>

        </Row>

        {/* <Loader loading={load} /> */}
        {optionsSeries && rSelected === 2 && (<Fragment>

          <HighchartsReact
            highcharts={Highcharts}
            options={optionsSeries}
            onRender={(e) => { console.log("RENDERED") }}
          />
        </Fragment>)}
        {
          optionsSeries && rSelected === 1 && contracts.length > 0 && (
            <Fragment>
              <ReactTabulator
                columns={columnsTabulator}
                data={props.contracts ? props.contracts : []}
                // rowClick={this.rowClick}
                // layout={"fitData"}
                responsiveLayout={"hide"}
              // Height="70vh"
              />
            </Fragment>
          )
        }
        {/* {
          rSelected === 1 && contracts.length > 9
        } */}

      </CardBody>
    </Card >
  )
}

export default BubbleChart;
