import React, { Component } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import TablePagination from '@material-ui/core/TablePagination';
import { connect } from 'react-redux';

// REDUX
import {
  getMyDatasets,
  resetDatasetIndicators
} from '../../redux/actions/datasetActions';
import { getMyStories } from '../../redux/actions/storiesActions';

// CUSTOM COMPONENTS
import ProfileMain from './components/ProfileMain';
import ProfileTabs from './components/ProfileTabs';
import Loader from '../../components/Loader/Loader';
import Story from '../../components/Story/Story';

// CUSTOM SCSS
import './Profile.scss';

const ROWS_PER_PAGE = 3;

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: {
        query: 0,
        dataset: 0,
        visualization: 0
      },
      datasets: {
        query: [],
        dataset: [],
        visualization: []
      },
      storiesPage: 0
    };
  }

  /**
   * Reset redux to initial state if error or loading is not null
   */
  componentWillUnmount() {
    if (this.props.error || this.props.loading) {
      this.props.resetDatasetIndicators();
    }
  }

  /**
   * Load datasets after component mount
   */
  async componentDidMount() {
    console.log('I MOUNTEEEEEEDDDDD');
    await this.props.getMyStories(ROWS_PER_PAGE, this.state.storiesPage);
    await this.loadDatasets();
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetching !== prevProps.fetching) {
      this.setState({ isLoading: this.props.fetching });
    }
    if (this.props.error !== prevProps.error) {
      this.setState({ error: this.props.error });
    }
    //console.log('this.props.stories.list', this.props.stories.list);
  }

  /**
   * Checks and calls to get user datasets
   */
  async loadDatasets() {
    if (!this.props.fetched_myDatasets) {
      var datasets_result = await this.props.getMyDatasets();
      if (datasets_result) {
        this.sortDatasetCategories();
      }
    } else {
      this.sortDatasetCategories();
    }
  }

  /**
   * Sorts datasets depending on type
   */
  sortDatasetCategories = () => {
    var counter = {
      query: 0,
      dataset: 0,
      visualization: 0
    };
    var datasets = {
      query: [],
      dataset: [],
      visualization: []
    };

    this.props.myDatasets.forEach(dataset => {
      if (dataset.type === 'Query') {
        counter.query++;
        datasets.query.push(dataset);
      } else if (dataset.type === 'Dataset') {
        counter.dataset++;
        datasets.dataset.push(dataset);
      } else if (dataset.type === 'Visualization') {
        counter.visualization++;
        datasets.visualization.push(dataset);
      }
    });
    this.setState({ counter, datasets });
  };

  handleChangePage = (event, page) => {
    this.setState({ storiesPage: page }, () => {
      this.props.getMyStories(ROWS_PER_PAGE, this.state.storiesPage);
    });
  };

  render() {
    return (
      <Container>
        <div className='profile'>
          <Row>
            <Col md={12} lg={12} xl={4}>
              <Row>
                <ProfileMain
                  user={this.props.user}
                  counter={this.state.counter}
                />
              </Row>
              <ProfileTabs datasets={this.state.datasets} />
            </Col>
            <Col md={12} lg={12} xl={8}>
              <Card>
                <CardBody>
                  <div className='card__title'>
                    <h5 className='bold-text'>My stories</h5>
                  </div>
                  <Col>
                    {this.props.stories.list.map(story => (
                      <Row key={story.id}>
                        <Story
                          story={story}
                          className='inline_story'
                          textSize={250}
                        />
                      </Row>
                    ))}
                  </Col>

                  {this.props.stories.listCount && (
                    <TablePagination
                      component='div'
                      className='material-table__pagination'
                      count={this.props.stories.listCount}
                      rowsPerPage={ROWS_PER_PAGE}
                      page={this.state.storiesPage}
                      backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                      nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                      onChangePage={this.handleChangePage}
                      rowsPerPageOptions={[]}
                      dir='ltr'
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true
                      }}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {this.props.error && (
            <Row>
              <div className='profile_error_message'>{this.props.error}</div>
            </Row>
          )}

          {this.props.loading && (
            <Row>
              <Loader loading={this.props.loading} simple={true}></Loader>
            </Row>
          )}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    myDatasets: state.dataset.myDatasets.datasets,
    fetched: state.dataset.myDatasets.fetched,
    error: state.dataset.error,
    loading: state.dataset.loading,
    stories: state.stories
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMyDatasets: () => dispatch(getMyDatasets()),
    resetDatasetIndicators: () => dispatch(resetDatasetIndicators()),
    getMyStories: (pageSize, page) => dispatch(getMyStories(pageSize, page))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
