const resultTypes = [
  {
    id: "ISO_COUNTRY_CODE",
    name: "Buyer Countries",
  },
  {
    id: "WIN_COUNTRY_CODE",
    name: "Seller Countries",
  },
  {
    id: "CAE_NAME",
    name: "Buyers",
  },
  {
    id: "WIN_NAME",
    name: "Sellers",
  },
  {
    id: "CPV",
    name: "CPVs",
  },
  {
    id: "NUMBER_TENDERS_SME",
    name: "Received Tenders",
  },
];

const buyerCountriesrResultTypes = [
  {
    id: "ISO_COUNTRY_CODE",
    name: "Buyer Countries",
  },
  {
    id: "CAE_NAME",
    name: "Buyers",
  },
];

const sellerCountriesrResultTypes = [
  {
    id: "WIN_COUNTRY_CODE",
    name: "Seller Countries",
  },
  {
    id: "WIN_NAME",
    name: "Sellers",
  },
];

const CPVResultTypes = [
  {
    id: "CPV",
    name: "CPVs",
  },
];

const chartTypes = [
  {
    id: "grid",
    name: "Grid View",
  },
  {
    id: "pie",
    name: "Pie Chart",
  },
  {
    id: "bar",
    name: "Bar Chart",
  },
  {
    id: "treemap",
    name: "Treemap Chart",
  },
  {
    id: "line-timeseries",
    name: "Line (Timeseries)",
  },
  {
    id: "grid-timeseries",
    name: "Grid (Timeseries)",
  },
];

const normalise = [
  {
    id: "no",
    name: "No Norm.",
  },
  {
    id: "gdp",
    name: "GDP",
  },
  {
    id: "percapita",
    name: "Per capita",
  },
];

const aggregate = [
  {
    id: "amount",
    name: "Amount",
  },
  {
    id: "count",
    name: "Number",
  },
];

const details = {
  "TED.Organisation.Buyer": {
    title: "organisation.contract.buyer.title",
    country: "organisation.country",
    notices: "organisation.TED.buyer.notices.count",
    contracts: "organisation.TED.buyer.contracts.count",
    amount: "organisation.TED.buyer.contracts.amount.total",
    top: "organisation.TED.buyer.contracts.seller.top",
    topCategory: "organisation.TED.buyer.contracts.categories.top",
    noticesList: "organisation.TED.notices.listitems.for.buyer",
    contractsList: "organisation.TED.contracts.listitems.for.buyer",
    noticesOverTime: "organisation.TED.buyer.notices.over.date",
    contractsOverTime: "organisation.TED.buyer.contracts.over.date",
    countries: "organisation.TED.contracts.seller.countries.all",
  },
  "TED.Organisation.Seller": {
    title: "organisation.contract.seller.title",
    country: "organisation.country",
    notices: "",
    contracts: "organisation.TED.contracts.count",
    amount: "organisation.TED.contracts.amount.total",
    top: "organisation.TED.contracts.buyer.top",
    topCategory: "organisation.TED.contracts.categories.top",
    noticesList: "",
    contractsList: "organisation.TED.contracts.listitems.for.seller",
    noticesOverTime: "",
    contractsOverTime: "organisation.TED.seller.contracts.over.date",
    countries: "organisation.TED.contracts.buyer.countries.all",
    relatedSellers: "company.sellers.related.sellers.through.contracts",
  },
};

const detailsTitles = {
  "TED.Organisation.Buyer": {
    head: "TED Buyer Organisation Details",
    title: "_title",
    country: "_country",
    notices: "Notices:",
    contracts: "Contracts:",
    amount: "Total Amount:",
    top: "Top Seller:",
    topCategory: "Top Category:",
    noticesList: "Notices",
    contractsList: "Award Notices",
    noticesOverTime: "Contracts (Notices)",
    contractsOverTime: "Contracts (Award Notices)",
    countries: "Seller Countries",
  },
  "TED.Organisation.Seller": {
    head: "TED Seller Organisation Details",
    title: "_title",
    country: "_country",
    notices: "",
    contracts: "Contracts:",
    amount: "Total Amount:",
    top: "Top Buyer:",
    topCategory: "Top Category:",
    noticesList: "",
    contractsList: "Contracts (Award Notices)",
    noticesOverTime: "",
    contractsOverTime: "Contracts (Award Notices)",
    countries: "Buyer Countries",
    relatedSellers: "Related Sellers",
  },
  Country: {
    head: "Country Details",
  },
  "Contract.AwardNotice": {
    head: "Contract Details",
  },
  "Contract.Notice": {
    head: "Contract Details",
  },
  AidActivity: {
    head: "Contract Details",
  },
};

const detailsTabs = {
  "TED.Organisation.Buyer": [
    {
      id: "noticesList",
      name: "Notices",
      charts: ["noticesOverTime"],
      mapCharts: [],
      tables: ["noticesList"],
      details: [],
    },
    {
      id: "contractsList",
      name: "Award Notices",
      charts: ["contractsOverTime"],
      mapCharts: [],
      tables: ["contractsList"],
      details: [],
    },
  ],
  "TED.Organisation.Seller": [
    {
      id: "contractsList",
      name: "Award Notices",
      charts: ["contractsOverTime", "relatedSellers"],
      mapCharts: [],
      tables: ["contractsList"],
      details: [],
    },
  ],
  Country: [
    {
      id: "datasets",
      name: "Datasets",
      charts: [],
      mapCharts: [],
      tables: ["datasets"],
      details: [],
    },
    // NOTE Omitted filters for simplicity
    {
      id: "products",
      name: "Products/Industries/Resources",
      charts: [],
      mapCharts: ["products"],
      tables: [],
      details: [],
    },
    {
      id: "indicatorsList",
      name: "World Development Indicators",
      charts: ["indicators"],
      mapCharts: [],
      tables: ["indicatorsList"],
      details: [],
    },
    {
      id: "indexes",
      name: "Indexes",
      charts: [],
      mapCharts: [],
      tables: [],
      details: ["indexes"],
    },
  ],
  "Contract.AwardNotice": [
    {
      id: "contractsList",
      name: "List of contracts",
      charts: ["pricePerDate"],
      mapCharts: [],
      tables: ["contractsList"],
      details: [],
    },
    {
      id: "awardNotice",
      name: "Award Notice",
      charts: [],
      mapCharts: [],
      tables: [],
      details: ["awardNotice"],
    },
    {
      id: "relatedNotice",
      name: "Related Notice",
      charts: [],
      mapCharts: [],
      tables: [],
      details: ["relatedNotice"],
    },
  ],
  "Contract.Notice": [
    {
      id: "contractsList",
      name: "List of contracts",
      charts: ["pricePerDate"],
      mapCharts: [],
      tables: ["contractsList"],
      details: [],
    },
    {
      id: "awardNotice",
      name: "Award Notice",
      charts: [],
      mapCharts: [],
      tables: [],
      details: ["awardNotice"],
    },
    {
      id: "relatedNotice",
      name: "Related Notice",
      charts: [],
      mapCharts: [],
      tables: [],
      details: ["relatedNotice"],
    },
  ],
  AidActivity: [
    {
      id: "contractsList",
      name: "List of contracts",
      charts: ["pricePerDate"],
      mapCharts: [],
      tables: ["contractsList"],
      details: [],
    },
    {
      id: "awardNotice",
      name: "Award Notice",
      charts: [],
      mapCharts: [],
      tables: [],
      details: ["awardNotice"],
    },
    {
      id: "relatedNotice",
      name: "Related Notice",
      charts: [],
      mapCharts: [],
      tables: [],
      details: ["relatedNotice"],
    },
  ],
  BUYERS: "Buyer Organisation",
  SELLERS: "Seller Organisation",
  COUNTRIES: "Country",
};

const amount = [
  "*",
  "1",
  "10",
  "100",
  "1000",
  "10000",
  "100000",
  "1000000",
  "10000000",
  "100000000",
  "1000000000",
];

export {
  resultTypes,
  buyerCountriesrResultTypes,
  sellerCountriesrResultTypes,
  CPVResultTypes,
  chartTypes,
  normalise,
  aggregate,
  amount,
  details,
  detailsTitles,
};
