export const publicContractsState = {
  buyers: {
    id: 'buyer',
    data: [],
    error: '',
    fetching: false,
    title: 'Buyers',
    view: []
  },
  amount: {
    id: 'amount',
    title: 'Amount',
    error: '',
    fetching: false,
    data: []
  },
  buyerCountries: {
    id: 'buyerCountry',
    data: [],
    error: '',
    fetching: false,
    map: 'Europe',
    title: 'Buyer Countries',
    view: []
  },
  cpvs: {
    id: 'cpv',
    data: [],
    error: '',
    fetching: false,
    title: 'CPVs',
    view: []
  },
  additional_cpvs: {
    id: 'cpv',
    data: [],
    error: '',
    fetching: false,
    title: 'additional cpvs',
    view: []
  },
  connections: {
    id: 'connections',
    data: [],
    error: '',
    fetching: false,
    title: 'connections',
    view: []
  },
  cpvsTable: {
    id: 'cpv',
    data: [],
    error: '',
    fetching: false,
    title: 'CPVs',
    view: [],
    from: 0,
    total: 0
  },
  additionalCpvsTable: {
    id: 'additional_cpv',
    data: [],
    error: '',
    fetching: false,
    title: 'Additional CPVs',
    view: [],
    from: 0,
    total: 0
  },
  numberOfTrends: {
    id: 'numberOfTenders',
    data: [],
    error: '',
    fetching: false,
    title: 'Number of tenders received',
    view: []
  },
  result: {
    id: 'graphResults',
    data: [],
    error: '',
    fetching: false,
    view: [],
    from: 0,
    total: 0
  },
  search: {
    id: 'searchResults',
    data: [],
    error: '',
    fetching: false,
    title: 'Search data',
    view: [],
    from: 0,
    total: 0,

  },
  userSearch: {
    id: 'searchResults',
    data: [],
    error: '',
    fetching: false,
    title: 'Search data',
    view: [],
    from: 0,
    total: 0,
    rows: 20
  },
  constractsBetween: {
    id: "contractsBetween",
    data: [],
    error: '',
    fetching: false,
    title: 'Search data',
    view: [],
    from: 0,
    total: 0,
    rows: 100
  },
  relatedNotice: {
    id: "relatedNotice",
    data: [],
    error: '',
    fetching: false,
    title: 'Search data',
    view: [],
    from: 0,
    total: 0,
    rows: 100
  },
  sellerCountries: {
    id: 'sellerCountry',
    data: [],
    error: '',
    fetching: false,
    map: 'World',
    title: 'Seller Countries',
    view: []
  },
  sellers: {
    id: 'seller',
    data: [],
    error: '',
    fetching: false,
    title: 'Sellers',
    view: [],
    from: 0,
    total: 0
  },
  time: {
    id: 'year',
    data: [],
    error: '',
    fetching: false,
    title: 'Time Period for Contracts',
    view: []
  },
  filters: {
    data: {
      amount: null,
      buyerCountry: null,
      sellerCountry: null,
      cpv: null,
      numberOfTenders: null,
      year: null,
      aggregate: 'amount',
      baseurl: '/details',
      normalise: 'no',
      chartType: 'bar',
      resultsType: 'ISO_COUNTRY_CODE'
    },
    error: '',
    fetching: false
  },
  firstCountryComparison: {
    id: "firstCountryComparison",
    data: [],
    error: '',
    fetching: false,
    title: 'first Country Comparison',
    view: [],
    from: 0,
    total: 100,
  },
  secondCountryComparison: {
    id: "secondCountryComparison",
    data: [],
    error: '',
    fetching: false,
    title: 'second Country Comparison',
    view: [],
    from: 0,
    total: 100,
  },
  firstCountryContracts: {
    id: "firstCountryContracts",
    data: [],
    error: '',
    fetching: false,
    title: 'First Country Contracts',
    view: [],
    from: 0,
    total: 100,
  },
  secondCountryContracts: {
    id: "secondCountryContracts",
    data: [],
    error: '',
    fetching: false,
    title: 'second Country Contracts',
    view: [],
    from: 0,
    total: 100,
  },
  esthonianRegistry: {
    id: "esthonianRegistry",
    data: [],
    error: '',
    fetching: false,
    title: 'Esthonian Registry Details',
  }

};