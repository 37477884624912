import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { InputLabel, MenuItem, FormControl, Select } from '@material-ui/core';

// CUSTOM DATA
import { chartTypes, normalise } from '../../services/platform/utils';

// CUSTOM SCSS
import './ChartProps.scss';

const ChartProps = props => {
  return (
    <Card className="card_chart_props">
      <CardBody>
        {props.filters && (
          <div className="filter-row d-flex justify-content-end">
            {/* <div>
              <FormControl className="chart-select" variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">
                  Normalization
                </InputLabel>
                <Select
                  value={props.filters.data.normalise}
                  labelId="demo-simple-select-outlined-label"
                  onChange={event =>
                    props.onFilterUpdate('normalise', event.target.value)
                  }
                  displayEmpty
                  MenuProps={{
                    disableScrollLock: true,
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left'
                    }
                  }}
                >
                  {normalise.map(item => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div> */}
            <div>
              <FormControl className="chart-select" variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">
                  Chart Type
                </InputLabel>
                <Select
                  value={props.filters.data.chartType}
                  labelId="demo-simple-select-outlined-label"
                  onChange={event =>
                    props.onFilterUpdate('chartType', event.target.value)
                  }
                  displayEmpty
                  MenuProps={{
                    disableScrollLock: true,
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left'
                    }
                  }}
                >
                  {chartTypes.map(item => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default React.memo(ChartProps);
