import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';

// CUSTOM COMPONENTS
import StoryForm from '../../components/StoryForm/StoryForm';
import DraggableDatasets from './DraggableDatasets';

import {
  getMyDatasets,
  resetDatasetIndicators
} from '../../redux/actions/datasetActions';

import { getResult } from '../../redux/actions/publicContracts/resultActions';

import './NewStory.scss';

class NewStory extends Component {
  constructor() {
    super();
    this.state = {
      myDatasets: []
    };
  }
  async componentDidMount() {
    const data = {
      params: {
        type: 'Visualization'
      }
    };
    await this.props.getMyDatasets(data);
    this.datasetAccess('Public');
  }

  /**
   * Gets StoryForm radio selection and show equivalent stored visualizations
   * @param {String} access
   */
  datasetAccess = access => {
    const myDatasets = this.props.myDatasets.filter(dataset => {
      if (access === 'Public') {
        if (access === dataset.access_level) {
          return dataset;
        }
      } else {
        return dataset;
      }
      return null;
    });
    this.setState({ myDatasets });
  };

  render() {
    return (
      <Container>
        <div className="story_creator">
          <Row>
            <Col md={12}>
              <h3 className="page-title">Create a new story</h3>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12} xl={8}>
              <StoryForm setAccess={this.datasetAccess} />
            </Col>
            <Col md={12} lg={12} xl={4}>
              <DraggableDatasets datasets={this.state.myDatasets} />
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    result: state.publicContracts.result,
    myDatasets: state.dataset.myDatasets.datasets,
    fetched: state.dataset.myDatasets.fetched,
    error: state.dataset.error,
    loading: state.dataset.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMyDatasets: data => dispatch(getMyDatasets(data)),
    resetDatasetIndicators: () => dispatch(resetDatasetIndicators()),
    getResult: (filters, from) => dispatch(getResult(filters, from))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewStory);
