import React, { useState, useRef, useEffect, Fragment } from "react";
import {
  Col,
  FormGroup,
  Container,
  ButtonGroup,
  InputGroupButtonDropdown,
  Row,
  Button,
  InputGroup,
  Input,
  InputGroupAddon,
  Form,
  Label,
  Dropdown,
  Table,
  Jumbotron,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/bootstrap/tabulator_bootstrap.css"; // theme
import { ReactTabulator } from "react-tabulator";

const columns = [
  { title: "CPV", field: "key" },
  { title: "Amount", field: "amount" },
  { title: "# Contracts", field: "num_contracts" },
];

const TreeTable = (props) => {
  const [data_columns, setData_columns] = useState([]);

  useEffect(() => {
    // console.log("here are the data", props.data)
    // console.log("here are the data len", props.data.data.length)

    if (props.data.data.length > 0) {
      let datacols = [];
      props.data.data.map((cpv) => {
        let datarow = {};
        datarow["key"] = cpv.key;
        datarow["amount"] = cpv.amount;
        datarow["num_contracts"] = cpv.count;
        let childrens = cpv.additionals.map((add_cpv) => {
          return {
            key: add_cpv.key,
            amount: add_cpv.euro_total.value,
            num_contracts: add_cpv.doc_count,
          };
        });
        datarow["_children"] = childrens;
        datacols.push(datarow);
      });

      setData_columns(datacols);
    }
  }, []);

  return (
    <ReactTabulator
      options={{ dataTree: true }}
      columns={columns}
      data={data_columns}
      // rowClick={this.rowClick}
      // layout={"fitData"}
      responsiveLayout={"hide"}
      // Height="70vh"
    />
  );
};

export default TreeTable;
