import React, { PureComponent } from 'react';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import MailRuIcon from 'mdi-react/MailRuIcon';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';

import { register, resetRedux } from '../../redux/actions/authActions';

// CUSTOM COMPONENTS
import InputField from '../CustomFields/InputField';
import Loader from '../Loader/Loader';

import './RegisterForm.scss';

class RegisterForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      user: { username: '', password: '', email: '' },
      success: {
        message: '',
        redirect: '',
        disableCreate: false
      }
    };
  }

  /**
   * Upon successful register call success message, check token from redux to avoid infinite loop
   */
  componentDidUpdate(nextProps) {
    if (this.props.token !== nextProps.token) {
      this.successMessage();
    }
  }

  /**
   * Reset redux to initial state if error or loading is not null
   */
  componentWillUnmount() {
    if (this.props.error || this.props.loading) {
      this.props.resetRedux();
    }
  }

  /**
   * Displays password
   * @param {*} e
   */
  showPassword = e => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  /**
   * If username, password and email are validated it assigns them to state
   * @param {*} validation
   * @param {*} value
   * @param {*} name
   */
  valueCheck = (value, name) => {
    if (name === 'username') {
      this.setState(prevState => ({
        user: {
          ...prevState.user,
          username: value
        }
      }));
    } else if (name === 'password') {
      this.setState(prevState => ({
        user: {
          ...prevState.user,
          password: value
        }
      }));
    } else if (name === 'email') {
      this.setState(prevState => ({
        user: {
          ...prevState.user,
          email: value
        }
      }));
    }
  };

  /**
   * Set state for success message, disable button and forward redirect
   */
  successMessage = () => {
    this.setState({
      success: {
        ...this.state.success,
        message: 'Successfully registered. Redirecting to app, please wait!',
        disableCreate: true
      }
    });

    setTimeout(
      function() {
        this.setState({ success: { ...this.state.success, redirect: true } });
      }.bind(this),
      2000
    );
  };

  /**
   * Call redux action to register
   * @param {*} e
   */
  register = e => {
    e.preventDefault();
    if (
      this.state.user.username &&
      this.state.user.password &&
      this.state.user.email
    ) {
      this.props.register(this.state.user);
    }
  };

  render() {
    return (
      <>
        <form className="form" onSubmit={event => this.register(event)}>
          <div className="form__form-group">
            <span className="form__form-group-label">Username</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AccountOutlineIcon />
              </div>
              <InputField
                name="username"
                type="text"
                placeholder="Name"
                valueCheck={this.valueCheck}
                required
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">E-mail</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <MailRuIcon />
              </div>
              <InputField
                name="email"
                type="text"
                placeholder="example@email.com"
                valueCheck={this.valueCheck}
                required
              />
            </div>
          </div>
          <div className="form__form-group form__form-group--forgot">
            <span className="form__form-group-label">Password</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <KeyVariantIcon />
              </div>
              <InputField
                name="password"
                type={this.state.showPassword ? 'text' : 'password'}
                placeholder="Password"
                valueCheck={this.valueCheck}
                required
              />
              <button
                type="button"
                className={`form__form-group-button${
                  this.state.showPassword ? ' active' : ''
                }`}
                onClick={e => this.showPassword(e)}
              >
                <EyeIcon />
              </button>
            </div>
          </div>
          <div className="account__btns register__btns">
            <Button
              type="submit"
              color="success"
              className="account__btn"
              disabled={this.state.success.disableCreate}
            >
              Sign Up
            </Button>
          </div>
          <Loader loading={this.props.loading} simple={true}></Loader>
          {this.props.error && !this.props.loading && (
            <div className="error_message">{this.props.error} </div>
          )}
          {this.state.success.message && (
            <div className="success_message">{this.state.success.message}</div>
          )}
          {this.state.success.redirect && <Redirect push to="/" />}
        </form>
        <div className="account__have-account">
          <p>
            Already have an account? <Link to="/log_in">Login</Link>
          </p>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.user.token,
    loading: state.user.loading,
    error: state.user.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    register: user => dispatch(register(user)),
    resetRedux: () => dispatch(resetRedux())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
