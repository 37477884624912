import { apiRequest } from '../../../services/api/index';

const catch_error = 'Please try again later';

/**
 * Strips Powered by from Editor
 * @param {String} data
 */
const stripPoweredBy = story => {
  const strPoweredBy = `<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>`;

  let data = story.data;
  let resultData = data.replace(strPoweredBy, '');
  let newStory = {
    title: story.title,
    access_level: story.access_level,
    data: resultData
  };
  return newStory;
};

/**
 * Calls api with credentials and set tokens in successful login
 * @param {*} user
 */
export const createStory = story => {
  const request = {
    type: 'createStory',
    data: stripPoweredBy(story)
  };
  return async dispatch => {
    try {
      const response = await apiRequest(request);
      if (response && response.status === 200) {
        return response;
      } else {
        return response.errors.data.message;
      }
    } catch (error) {
      return catch_error;
    }
  };
};
