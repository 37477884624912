import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Card, CardBody } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  fetchData,
  setData,
  setError,
} from '../../redux/actions/publicContracts/publicContractsActions';

import { useDispatch, useSelector } from 'react-redux';

// CUSTOM COMPONENTS
import Loader from '../Loader/Loader';
import Error from '../Error/Error';

// CUSTOM SCSS
import './FacetList.scss';
import { getAdditionalCPVs } from '../../redux/actions/publicContracts/additionalCpvActions';

var countriesIso = require('i18n-iso-countries');
countriesIso.registerLocale(require('i18n-iso-countries/langs/en.json'));

const FacetList = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showMore, setShowMore] = useState(true);
  const [error, setError] = useState('');
  const [limit, setLimit] = useState(5);
  const [options, setOptions] = useState([]);
  const { data, selectedValue } = props;
  const prevProps = usePrevious({ data, selectedValue });
  const dispatch = useDispatch();
  let additionalCPVs = useSelector(
    (state) => state.publicContracts.additional_cpvs
  );
  useEffect(() => {
    if (prevProps) {
      if (JSON.stringify(prevProps.data) !== JSON.stringify(props.data)) {
        if (props.data && props.data.data && props.data.data.length > 0) {
          //console.log("here")
          buildOptions(5);
          // if (props.data.id === "cpv") {

          // }
        } else {
          setIsLoading(false);
          setOptions([]);
        }
        setIsLoading(props.data.fetching);
        setError(props.data.error);
      }
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedValue]);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  // For the exception cases where info is missing from the facets,
  // try to figure out the country by the key codes
  function renderFacetName(item) {
    if (
      item.key === 'N/A' &&
      item['hc-key'] === 'n/a' &&
      item.name === undefined
    )
      return 'N/A';
    if (item.key === 'UK') return 'United Kingdom';
    return item.name;
  }

  const buildOptions = (newLimit) => {
    const isCountryData =
      props.data.id && props.data.id.indexOf('Country') >= 0;
    const sortedData = props.data.data.sort((a, b) => {
      return a.name < b.name ? -1 : 1;
    });
    //console.log("here 2:",isCountryData,sortedData)
    let limitedData;
    if (newLimit) {
      limitedData = sortedData.slice(0, 5);
    } else {
      limitedData = sortedData;
    }
    const optionsData = limitedData.map((element) => {
      let item = element;
      if (isCountryData) {
        item.name = countriesIso.getName(element.key, 'en');
      } else {
        item.name = element.key;
      }
      return item;
    });
    setLimit(newLimit ? newLimit : 0);
    setOptions(optionsData);
    setIsLoading(false);
  };

  const renderTree = () => {
    if (additionalCPVs.data.length > 0 && props.data.id == 'cpv') {
      return additionalCPVs.data.map((item) => {
        return (
          <div
            key={'option-' + item.key}
            className={
              'facet_item d-flex justify-content-between align-items-center facet_nested'
            }
          >
            <div
              className="facet_option"
              onClick={() => {
                props.onFilterUpdate(props.data.id, item.code);
              }}
            >
              {item.key}
            </div>
            <div className="facet_value">{item.count.toLocaleString()}</div>
          </div>
        );
      });
      // return (<div>ITEM3</div>)
    }
  };

  const toggleMore = () => {
    setIsLoading(true);
    setShowMore(!showMore);
    if (showMore) {
      buildOptions();
    } else {
      buildOptions(5);
    }
  };

  return (
    <Card>
      <CardBody>
        {props.data && props.data.title && (
          <div className="card__title">
            <h5 className="bold-text" style={{ color: '#5C6A81' }}>
              {props.data.title}
            </h5>
          </div>
        )}
        <Loader loading={isLoading} simple={true} />
        {!isLoading && (
          <>
            <Error error={error} />
            {options.length === 1 && (
              <div className="show_link d-flex justify-content-end">
                <div
                  onClick={() => {
                    props.onFilterUpdate(props.data.id, null);
                    dispatch(setData('additional_cpvs', []));
                  }}
                >
                  Clear
                </div>
              </div>
            )}
            <Scrollbars
              renderTrackHorizontal={(props) => (
                <div {...props} className="track-horizontal" />
              )}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={250}
            >
              {options.map((item) => {
                return (
                  <div
                    key={'option-' + item.key}
                    className={
                      'facet_item d-flex justify-content-between align-items-center' +
                      (props.selectedValue === item.key && ' active')
                    }
                  >
                    <div
                      className="facet_option"
                      onClick={() => {
                        if (props.data.id == 'cpv') {
                          // console.log("ITEM", item)
                          // dispatch(getAdditionalCPVs({ cpv: item.code }, 0))
                          // console.log("pressed cpv")
                          // console.log('CSV?', props.data)

                          props.onFilterUpdate(props.data.id, item.code);
                        } else {
                          props.onFilterUpdate(props.data.id, item.key);
                        }
                      }}
                    >
                      {renderFacetName(item)}
                    </div>
                    <div className="facet_value">
                      {item.count.toLocaleString()}
                    </div>
                  </div>
                );
              })}
              {renderTree()}
            </Scrollbars>
            {options.length >= limit && (
              <div className="show_link d-flex justify-content-end">
                <div onClick={() => toggleMore()}>
                  Show{showMore ? ' more' : ' less'}
                </div>
              </div>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default FacetList;
