import React, { useState, useRef, useEffect } from "react";
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import "./esthonianDetails.css"
import { OpenStreetMapProvider } from 'leaflet-geosearch';
const provider = new OpenStreetMapProvider();
let getlog = async () => {
  const results = await provider.search({ query: "Harju maakond, Tallinn, Nõmme linnaosa, Teelise tn 4" });
  //console.log("results", results)
}
const EsthonianMap = (props) => {
  console.log("PROPS MAPS", props.pos)
  return (<div>
    <Map center={[props.pos.y, props.pos.x]} zoom={14}>
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker key={"popup"} position={[props.pos.y, props.pos.x]}>
        {/* <Popup>
          POP UP DETAILS
          </Popup> */}
      </Marker>
    </Map>
  </div>)

}


export default EsthonianMap;
