import { apiRequest } from "../../../services/api/index";

import { fetchData, setData, setError } from "./publicContractsActions";

// CUSTOM DATA
import { resultsHead } from "../../../services/helpers/resultsHead";
import { resultsTimeHead } from "../../../services/helpers/resultsTimeHead";

export function getResult(filters, from, identifier) {
  //console.log("anesid search: ", filters, identifier);

  // switch (identifier) {
  //   case "buyerCountries":
  //     filters.resultsType = "ISO_COUNTRY_CODE";
  //     break;
  //   case "sellerCountries":
  //     filters.resultsType = "WIN_COUNTRY_CODE";
  //     break;
  //   case "CPVs":
  //     filters.resultsType = "CPV";
  //     break;
  //   default:
  // }

  return async (dispatch) => {
    dispatch(fetchData("result"));
    try {
      let callType = "aggregatePerField";
      let size = 100;
      if (filters.chartType === "line-timeseries") {
        callType = "aggregatePerTimeLine";
      }
      if (filters.chartType === "grid-timeseries") {
        callType = "aggregatePerTimeGrid";
        size = 10;
      }
      if (filters.chartType === "grid") {
        size = 10;
      }
      const params = {
        type: callType,
        data: {
          field: filters.resultsType,
          from: from,
          size: size,
          terms: [],
          ranges: [],
        },
      };
      // Add selected facet's value
      if (filters.buyerCountry) {
        params.data.terms.push({
          ISO_COUNTRY_CODE: filters.buyerCountry,
        });
      }
      if (filters.sellerCountry) {
        params.data.terms.push({
          WIN_COUNTRY_CODE: filters.sellerCountry,
        });
      }
      if (filters.cpv) {
        params.data.terms.push({
          CPV: filters.cpv,
        });
      }
      // Add selected facet's range
      if (filters.year) {
        params.data.ranges.push({
          YEAR: { start: filters.year[0], end: filters.year[1] },
        });
      }
      // if (filters.number) {
      //   params.data.params.number = filters.number;
      // }
      // if (filters.amount) {
      //   params.data.params.amount = filters.amount;
      // }
      //console.log("anesid result: ", params);
      // console.log("This is the error:  ", params);
      const response = await apiRequest(params);
      if (response.status === 200) {
        const payload = {
          data: response.data.data,
          view:
            filters.chartType === "grid-timeseries"
              ? resultsTimeHead
              : resultsHead,
          total: response.data.total,
          from: from + 10,
        };
        dispatch(setData("result", payload));
      } else {
        dispatch(setError("result", "Something went wrong. Please try later!"));
      }
    } catch (error) {
      dispatch(setError("result", "Something went wrong. Please try later!"));
    }
  };
}
