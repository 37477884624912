import React, { useState, useRef, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Highstock from "highcharts/highstock";
import ReactHighcharts from "react-highcharts";
import addTreemapModule from "highcharts/modules/treemap";

// CUSTOM COMPONENTS
import Loader from "../Loader/Loader";
import Error from "../Error/Error";

// CUSTOM DATA
import { barChart } from "../../services/helpers/barChart";
import { pieChart } from "../../services/helpers/pieChart";
import { treemapChart } from "../../services/helpers/treemapChart";
import {
  timeSeriesChartCountry,
  timeSeriesChartOrganization,
} from "../../services/helpers/timeSeriesChartCountry";
import { resultTypes } from "../../services/platform/utils";

// CUSTOM SCSS
import "./Chart.scss";

// COUNTRIES ISO CODES
var countriesIso = require("i18n-iso-countries");
countriesIso.registerLocale(require("i18n-iso-countries/langs/en.json"));

const Charts = ReactHighcharts.withHighcharts(Highstock);

const Chart = (props) => {
  const [title, setTitle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [options, setOptions] = useState({});

  const { data } = props;
  const prevProps = usePrevious({ data });
  useEffect(() => {
    // console.log(
    //   "anesid: The chart will rerender with the following dataset",
    //   data
    // );
    if (prevProps) {
      if (props.title) {
        const resultName = resultTypes.find((x) => x.id === props.title).name;
        setTitle("Contracts per " + resultName);
      } else {
        setTitle(props.data.title);
      }
      if (props.data) {
        if (props.data.fetching !== isLoading) {
          setIsLoading(props.data.fetching);
        } else if (props.data.error !== error) {
          setError(props.data.error);
        }
      }
      if (JSON.stringify(prevProps.data) !== JSON.stringify(props.data)) {
        processChartData();
      }
    } else if (data && data.data.chart && data.data.chart.type) {
      processChartData();
    }
    if (props.data) {
      setIsLoading(props.data.fetching);
      setError(props.data.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function processChartData() {
    let options = {};
    if (props.data && !props.data.fetching && !props.data.error) {
      if (props.filters.data.chartType === "treemap") {
        addTreemapModule(Highcharts);
        options = treemapChart;
        options.series[0].data = [];
        props.data.data.forEach((option, index) => {
          const isCountry = props.filters.data.resultsType.indexOf("COUNTRY");
          const category = isCountry
            ? countriesIso.getName(option.key, "en")
            : option.key;
          options.series[0].data.push({
            id: index,
            name: category ? category : option.key,
            value: option.amount,
          });
        });
        // HERE IS THE PIE
      } else if (props.filters.data.chartType === "pie") {
        // console.log("here: ", props);
        options = pieChart;
        options.series[0].data = [];
        props.data.data.forEach((option) => {
          const isCountry = props.filters.data.resultsType.indexOf("COUNTRY");
          const category = isCountry
            ? countriesIso.getName(option.key, "en")
            : option.key;
          options.series[0].data.push({
            name: category ? category : option.key,
            y: Math.round(option.amount),
          });
        });
      } else if (
        props.filters.data.chartType === "bar" ||
        data.type === "bar"
      ) {
        options = barChart;
        options.xAxis.categories = [];
        options.series[0].data = [];
        options.series[1].data = [];
        if (props.data.data.length > 10) {
          options.xAxis.max = 10;
          options.scrollbar = {
            enabled: true,
          };
        } else {
          options.xAxis.max = props.data.data.length - 1;
          options.scrollbar = {
            enabled: false,
          };
        }
        props.data.data.forEach((option) => {
          const isCountry = props.filters.data.resultsType
            ? props.filters.data.resultsType.indexOf("COUNTRY") !== -1
            : false;
          const category = isCountry
            ? countriesIso.getName(option.key, "en")
            : option.key;
          options.xAxis.categories.push(category ? category : option.key);
          options.series[0].data.push(option.amount);
          options.series[1].data.push(option.count);
        });
      } else if (
        props.filters.data.chartType === "line-timeseries" &&
        props.data.data !== undefined
      ) {
        //console.log("here: ", props);
        //console.log("This is the  data: ", props.data.data);

        if (
          props.filters.data.resultsType === "ISO_COUNTRY_CODE" ||
          props.filters.data.resultsType === "WIN_COUNTRY_CODE"
        ) {
          options = timeSeriesChartCountry;
          options.plotOptions.series.pointStart = props.data.data[0].key;
          let countries = [];
          // Extract Countries
          props.data.data[0].countries.forEach((country) => {
            countries.push(
              countriesIso.getName(country.key, "en") !== undefined
                ? countriesIso.getName(country.key, "en")
                : country.key
            );
          });
          //console.log("Countries: ", countries);

          // For each country
          let serries = [];
          countries.forEach((country) => {
            let serriesPerCountry = [];
            // For each year
            props.data.data.forEach((year) => {
              // Find the country
              year.countries.forEach((_country) => {
                _country.key =
                  countriesIso.getName(_country.key, "en") !== undefined
                    ? countriesIso.getName(_country.key, "en")
                    : _country.key;
                //console.log(_country.key, country.key)
                if (_country.key === country) {
                  serriesPerCountry.push(Math.round(_country.amount));
                }
              });
            });
            serries.push({ name: country, data: serriesPerCountry });
          });
          //console.log("This is the actual data: ", serries);
          options.series = serries;
        } else {
          options = timeSeriesChartOrganization;
          options.plotOptions.series.pointStart = props.data.data[0].key;
          let countries = [];
          // Extract Organizations - CPVs - Tenders
          props.data.data.forEach((year) => {
            year.countries.forEach((country) => {
              countries.push(country.key);
            });
          });
          let uniqueCountries = [...new Set(countries)];
          countries = uniqueCountries;
          // console.log("Organizations - CPVs - Tenders: ", countries);

          // For each country
          let serries = [];
          countries.forEach((country) => {
            let serriesPerCountry = [];
            // For each year
            props.data.data.forEach((year) => {
              // Find the country
              let valid = false;
              year.countries.forEach((_country) => {
                //console.log(_country.key, country.key)
                if (_country.key === country) {
                  serriesPerCountry.push(Math.round(_country.amount));
                  valid = true;
                }
              });
              if (valid === false) serriesPerCountry.push(null);
            });
            serries.push({ name: country, data: serriesPerCountry });
          });
          // console.log("This is the actual data: ", serries);
          options.series = serries;
        }
      }
    }
    // console.log(">>>>>>>>>>>>>>>>>>>>> ANESID INSIDE CHART: ", options);
    setOptions(options);
  }

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  return (
    <Card>
      <CardBody className="card_chart">
        {title && (
          <div className="card__title">
            <h5 className="bold-text">{title}</h5>
          </div>
        )}
        <Loader loading={isLoading} />
        {!isLoading && (
          <>
            <Error error={error} />
            {options.type === "treemap" ||
            (options.chart && options.chart.type === "bubble") ? (
              <HighchartsReact highcharts={Highcharts} options={options} />
            ) : (
              options.series && <Charts isPureConfig={true} config={options} />
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default React.memo(Chart);
