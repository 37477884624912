import {
  FETCH_DATA,
  FETCH_DATA_SUCCESSFUL,
  FETCH_DATA_ERROR,
  SET_NESTED_DATA,
  RESET_FILTERS,
} from "../../actions/publicContracts/publicContractsActions";

import { publicContractsState } from "./initialState/publicContractsState";

const initialState = {
  ...publicContractsState,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_DATA: {
      return {
        ...state,
        [action.stateType]: {
          ...state[action.stateType],
          error: "",
          fetching: true,
          data: [],
          view: [],
        },
      };
    }
    case FETCH_DATA_SUCCESSFUL: {
      return {
        ...state,
        [action.stateType]: {
          ...state[action.stateType],
          data: action.payload.data ? action.payload.data : action.payload,
          view: action.payload.view,
          queryData: action.payload.queryData,
          error: "",
          fetching: false,
          ...(action.payload.total && {
            total: action.payload.total,
          }),
          ...(action.payload.from && {
            from: action.payload.from,
          }),
        },
      };
    }
    case FETCH_DATA_ERROR: {
      return {
        ...state,
        [action.stateType]: {
          ...state[action.stateType],
          error: action.payload,
          fetching: false,
          data: [],
          view: [],
        },
      };
    }
    case SET_NESTED_DATA: {
      return {
        ...state,
        [action.stateType]: {
          ...state[action.stateType],
          [action.nestedType]: {
            ...state[action.stateType][action.nestedType],
            [action.payload.key]: action.payload.value,
          },
        },
      };
    }
    case RESET_FILTERS: {
      return {
        ...state,
        filters: initialState.filters,
      };
    }
    default:
      return state;
  }
}
