import { apiRequest } from "../../services/api/index";

// SERVICES
import { getCountryName } from "../../services/iso/countries";

// CUSTOM DATA
import { searchHead } from "../../services/helpers/searchHead";
import { infoHead } from "../../services/helpers/infoHead";

// ACTION TYPES
export const FETCH_INFO_DATA = "FETCH_INFO_DATA";
export const FETCH_INFO_DATA_SUCCESSFUL = "FETCH_INFO_DATA_SUCCESSFUL";
export const FETCH_INFO_DATA_ERROR = "FETCH_INFO_DATA_ERROR";

function fetchData(dataKey) {
  return {
    type: FETCH_INFO_DATA,
    payload: {
      itemKey: dataKey,
    },
  };
}

function setData(dataKey, response) {
  return {
    type: FETCH_INFO_DATA_SUCCESSFUL,
    payload: { itemKey: dataKey, response: response },
  };
}

function setError(dataKey, errorMsg) {
  return {
    type: FETCH_INFO_DATA_ERROR,
    payload: { itemKey: dataKey, error: errorMsg },
  };
}

// ERROR MESSAGE
const catch_error = "Please try again later";

const TOTALS_TYPE = {
  COUNTRIES: {
    FIELDS: ["CAE_NAME", "WIN_NAME"],
    RETURN_DATA: false,
    TYPE: {
      SELLERS: "ISO_COUNTRY_CODE",
      BUYERS: "WIN_COUNTRY_CODE",
    },
    AMOUNT: "VALUE_EURO_FIN_1",
    VALUE: "COUNTRIES",
    MAP: "ISO_COUNTRY_CODE",
  },
  SELLERS: {
    FIELDS: ["WIN_NAME"],
    RETURN_DATA: true,
    TYPE: "WIN_NAME",
    AMOUNT: "VALUE_EURO_FIN_1",
    VALUE: "SELLERS",
    MAP: "WIN_COUNTRY_CODE",
  },
  BUYERS: {
    FIELDS: ["CAE_NAME"],
    RETURN_DATA: true,
    TYPE: "CAE_NAME",
    AMOUNT: "AWARD_VALUE_EURO_FIN_1",
    VALUE: "BUYERS",
    MAP: "ISO_COUNTRY_CODE",
  },
};

export const createTotals = (totalVar, from, size, type) => {
  const dataTotals = {
    field: totalVar,
    amount: TOTALS_TYPE[type].AMOUNT,
    uniqueCount: TOTALS_TYPE[type].FIELDS,
  };
  const requestTotals = {
    type: "createTotalsPerField",
    data: dataTotals,
  };
  return async (dispatch) => {
    try {
      dispatch(fetchData("dataTotals"));
      const response = await apiRequest(requestTotals);

      if (response && response.status === 200) {
        console.log(
          ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ANESID CREATE_TOTALS: ",
          requestTotals,
          response
        );
        let totalData = {
          view: infoHead[TOTALS_TYPE[type].VALUE],
          data: {},
        };
        if (TOTALS_TYPE[type].VALUE === "COUNTRIES") {
          totalData.data = {
            name: getCountryName(response.data.ISO_COUNTRY_CODE.key),
            code: response.data.ISO_COUNTRY_CODE.key,
            contracts: response.data.ISO_COUNTRY_CODE.count,
            awards: response.data.WIN_COUNTRY_CODE.count,
            expenses: response.data.ISO_COUNTRY_CODE.amount,
            revenues: response.data.WIN_COUNTRY_CODE.amount,
            buyers: response.data.ISO_COUNTRY_CODE.CAE_NAME,
            sellers: response.data.WIN_COUNTRY_CODE.WIN_NAME,
          };
        } else {
          const orgType = TOTALS_TYPE[type].TYPE;
          totalData.data = {
            name:
              TOTALS_TYPE[type].VALUE === "SELLERS"
                ? response.data[orgType].firstHit.WIN_NAME
                : response.data[orgType].firstHit.CAE_NAME,
            country:
              TOTALS_TYPE[type].VALUE === "SELLERS"
                ? getCountryName(
                    response.data[orgType].firstHit.WIN_COUNTRY_CODE
                  )
                : getCountryName(
                    response.data[orgType].firstHit.ISO_COUNTRY_CODE
                  ),
            count: response.data[orgType].count,
            amount: response.data[orgType].amount,
            address:
              TOTALS_TYPE[type].VALUE === "SELLERS"
                ? response.data[orgType].firstHit.WIN_ADDRESS
                : response.data[orgType].firstHit.CAE_ADDRESS,
            town:
              TOTALS_TYPE[type].VALUE === "SELLERS"
                ? response.data[orgType].firstHit.WIN_TOWN
                : response.data[orgType].firstHit.CAE_TOWN,
            code:
              TOTALS_TYPE[type].VALUE === "SELLERS"
                ? response.data[orgType].firstHit.WIN_COUNTRY_CODE
                : response.data[orgType].firstHit.ISO_COUNTRY_CODE,
          };
        }
        dispatch(setData("dataTotals", totalData));
        if (TOTALS_TYPE[type].RETURN_DATA) {
          return totalData;
        }
      } else {
        dispatch(setError("dataTotals", response.errors.data.message));
      }
    } catch (error) {
      dispatch(setError("dataTotals", catch_error));
    }
  };
};

export const createMap = (mapVar, type) => {
  // Refactor in case the map refers to Seller/Organizations
  console.log(
    ">>>>>>>>>>>>>>>>>>>ANESID: This is were the change must happen",
    mapVar,
    type
  );
  if (type === "SELLERS" && mapVar[0].hasOwnProperty("WIN_COUNTRY_CODE")) {
    if (Array.isArray(mapVar[0].WIN_COUNTRY_CODE)) {
      mapVar[0].WIN_COUNTRY_CODE = mapVar[0].WIN_COUNTRY_CODE[0];
    }
  }

  const dataMap = {
    field: mapVar,
    amount: TOTALS_TYPE[type].AMOUNT,
    uniqueCount: TOTALS_TYPE[type].FIELDS,
  };
  const requestMap = {
    type: "createTotalsPerField",
    data: dataMap,
  };

  return async (dispatch) => {
    try {
      dispatch(fetchData("mapData"));
      const response = await apiRequest(requestMap);
      console.log(
        ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ANESID CREATE_MAP: ",
        requestMap,
        response
      );
      if (response && response.status === 200) {
        //console.log('mapData', response);
        let mapType = TOTALS_TYPE[type].MAP;
        let mapData = {
          data: [
            {
              key: response.data[mapType].key,
              count: response.data[mapType].count,
            },
          ],
          map: "World",
        };

        //console.log('mapData', mapData);
        if (response.data[mapType].count === 0) {
          dispatch(setError("mapData", "No data found"));
        } else {
          dispatch(setData("mapData", mapData));
        }
      } else {
        dispatch(setError("mapData", response.errors.data.message));
      }
    } catch (error) {
      dispatch(setError("mapData", catch_error));
    }
  };
};

export const createPerField = (dataTerms, type) => {
  const dataYear = {
    field: "YEAR",
    terms: [dataTerms],
    size: 100,
  };
  const requestYear = {
    type: "aggregatePerField",
    data: dataYear,
  };
  return async (dispatch) => {
    try {
      dispatch(fetchData(type));
      const response = await apiRequest(requestYear);

      if (response && response.status === 200) {
        console.log(
          ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ANESID CREATE_PER_FIELD: ",
          requestYear,
          response
        );
        response.data.data.sort(function (a, b) {
          return a.key - b.key;
        });

        const yearData = { ...response.data };

        dispatch(setData(type, yearData));
      } else {
        dispatch(setError(type, response.errors.data.message));
      }
    } catch (error) {
      dispatch(setError(type, catch_error));
    }
  };
};

export const searchPerField = (type, value, mult, from, size) => {
  const termType = mult ? TOTALS_TYPE[type].TYPE[mult] : TOTALS_TYPE[type].TYPE;
  const requestSearchData = {
    type: "search",
    data: { terms: [{ [termType]: value }], from: from, size: size },
  };

  const searchDataList = mult ? "searchDataCountry" + mult : "searchData";
  return async (dispatch) => {
    try {
      dispatch(fetchData(searchDataList));
      const response = await apiRequest(requestSearchData);
      if (response && response.status === 200) {
        console.log(
          ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ANESID SEARCH_PER_FIELD: ",
          requestSearchData,
          response
        );
        const searchData = { ...response.data };
        searchData.view = searchHead;
        searchData.from = from + 10;
        searchData.total = response.data.total;
        dispatch(setData(searchDataList, searchData));
      } else {
        dispatch(setError(searchDataList, response.errors.data.message));
      }
    } catch (error) {
      dispatch(setError(searchDataList, catch_error));
    }
  };
};
