import { apiRequest } from '../../services/api/index';

/**
 * Action Types
 */
export const SET_VISUALIZATION = 'GET_VISUALIZATION';
export const VISUALIZATION_ERROR = 'VISUALIZATION_ERROR';
export const RESET_VISUALIZATION = 'RESET_VISUALIZATION';
export const FETCHING_API = 'FETCHING_API';

/** Error message for network down */
const catch_error = 'Please try again later';

/**
 *  ACTION CREATORS
 */

/**
 * Sets loading value
 * @param {boolean} loading
 */
export const fetch_api = loading => {
  return {
    type: FETCHING_API,
    payload: loading
  };
};

/**
 * Sets newly added visualization
 * @param {Object} visualization
 */
export const set_visualization = visualization => {
  return {
    type: SET_VISUALIZATION,
    payload: visualization
  };
};

/**
 * Resets recently added dataset
 */
export const resetVisualization = () => {
  return {
    type: RESET_VISUALIZATION
  };
};

/**
 * Sets dataset error message
 * @param {String} message
 */
export const visualization_error = message => {
  return {
    type: VISUALIZATION_ERROR,
    payload: message
  };
};

/**
 * ACTIONS THAT CALL API
 */

/**
 * myDatasets added dataset to database
 */
export const getVisualization = (id, access) => {
  return async dispatch => {
    dispatch(fetch_api());
    try {
      const request = {
        dynamic_url: id,
        type: access ? 'dataset' : 'visualization'
      };
      const response = await apiRequest(request);
      if (response && response.status === 200) {
        dispatch(set_visualization(response.data[0]));
        return response.data[0];
      } else {
        dispatch(visualization_error(response.data.error));
      }
    } catch (error) {
      dispatch(visualization_error(catch_error));
    }
    return false;
  };
};
