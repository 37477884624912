import React, { useState, useRef, useEffect, Fragment } from "react";
import {
  Col,
  FormGroup,
  Container,
  ButtonGroup,
  InputGroupButtonDropdown,
  Row,
  Button,
  InputGroup,
  Input,
  InputGroupAddon,
  Form,
  Label,
  Dropdown,
  Table,
  Jumbotron,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import "./contract.css";
import { FaPrint, FaTimes } from "react-icons/fa";
import classnames from "classnames";

import { useDispatch, useSelector } from "react-redux";
import { getContractsBetween } from "../../../redux/actions/publicContracts/searchActions";

import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/bootstrap/tabulator_bootstrap.css"; // theme
import { ReactTabulator } from "react-tabulator";
// ID_NOTICE_CAN => FUTURE_ID_CAN
import Boost from "highcharts/modules/boost";

import {
  awardColumns,
  tabulatorNoticeCols,
  tabulatorAwardedCols,
  noticeColumns,
  contractingAuthority,
  euInstCode,
  contractType,
  frameworkAggrement,
  frameworkEstimated,
  gpaCoverage,
  topType,
  infoNonAward,
  critCode,
} from "../../../shared/helpers/CollumnsDataset"; // for React 15.x, use import { React15Tabulator }
// import HighchartSankey from 'highcharts/modules/sankey';
// import HighchartsWheel from 'highcharts/modules/dependency-wheel';

import HighchartsReact from "highcharts-react-official";
// import Highcharts from 'highcharts';
// import Highcharts from 'highcharts/modules/dependency-wheel';
import Highcharts from "highcharts";

import HighchartSankey from "highcharts/modules/sankey";
import HighchartsWheel from "highcharts/modules/dependency-wheel";
import ChordDia from "./ChordDia";
HighchartSankey(Highcharts);
HighchartsWheel(Highcharts);
Boost(Highcharts);

const options2 = {
  boost: {
    boostThreshold: 1,
    enabled: true,
  },
  chart: {
    type: "dependencywheel",
  },
  title: {
    text: "My chart",
  },
  series: [
    {
      data: [
        {
          from: "Antonis",
          to: "Anestis",
          weight: 50,
        },
        {
          from: "Anestis",
          to: "Antonis",
          weight: 1,
        },
      ],
    },
  ],
};

// require('highcharts/modules/dependency-wheel')(Highcharts)

var countriesIso = require("i18n-iso-countries");
countriesIso.registerLocale(require("i18n-iso-countries/langs/en.json"));
const cpvs = require("../../../shared/helpers/cpvs.json");
const isoLangs = require("../../../shared/helpers/isoLangs.json");

let rowClick = (e, r) => {
  // console.log(r);
};

let printContr = () => {
  const printableElements = document.getElementById("testPrint").innerHTML;
  const Html =
    '<html><link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous"><head><title></title></head><body><div class="container">' +
    printableElements +
    "</div></body></html>";
  let newTab = window.open();
  newTab.document.write(Html);
  newTab.document.close();
  newTab.print();
  // var pri = document.getElementById("ifmcontentstoprint").contentWindow;
  // pri.document.open();
  // pri.document.write(printableElements);
  // pri.document.close();
  // pri.focus();
  // pri.print();
};

let convertValues = (obj) => {
  if (contractingAuthority[obj["CAE_TYPE"]]) {
    obj["CAE_TYPE"] = contractingAuthority[obj["CAE_TYPE"]];
  }

  if (euInstCode[obj["EU_INST_CODE"]]) {
    obj["EU_INST_CODE"] = euInstCode[obj["EU_INST_CODE"]];
  }

  if (contractType[obj["TYPE_OF_CONTRACT"]]) {
    obj["TYPE_OF_CONTRACT"] = contractType[obj["TYPE_OF_CONTRACT"]];
  }

  if (frameworkAggrement[obj["B_FRA_AGREEMENT"]]) {
    obj["B_FRA_AGREEMENT"] = frameworkAggrement[obj["B_FRA_AGREEMENT"]];
  }

  if (frameworkEstimated[obj["FRA_ESTIMATED"]]) {
    obj["FRA_ESTIMATED"] = frameworkEstimated[obj["FRA_ESTIMATED"]];
  }

  if (topType[obj["TOP_TYPE"]]) {
    obj["TOP_TYPE"] = topType[obj["TOP_TYPE"]];
  }
  if (cpvs[obj["CPV"]]) {
    obj["CPV"] = cpvs[obj["CPV"]];
  } else {
    obj["CPV"] = "Not available";
  }

  if (Array.isArray(obj["ADDITIONAL_CPVS"])) {
    let cpvsNames = obj["ADDITIONAL_CPVS"].map((cpv) => {
      if (cpvs[cpv]) {
        return cpvs[cpv];
      } else {
        return "Not Available";
      }
    });
    obj["ADDITIONAL_CPVS"] = cpvsNames.toString().split(",").join(", ");
  } else {
    obj["ADDITIONAL_CPVS"] = "N/A";
  }

  if (obj["CRIT_CRITERIA"]) {
    obj["CRIT_CRITERIA"] = obj["CRIT_CRITERIA"]
      .toString()
      .split(",")
      .join(", ");
  }

  if (obj["WIN_COUNTRY_CODE"]) {
    if (Array.isArray(obj["WIN_COUNTRY_CODE"])) {
      obj["WIN_COUNTRY_CODE"] = obj["WIN_COUNTRY_CODE"].map((country) => {
        let name =
          country === "UK"
            ? "United Kingdom"
            : countriesIso.getName(country, "en");
        return name;
      });
    }
    obj["WIN_COUNTRY_CODE"] = obj["WIN_COUNTRY_CODE"]
      .toString()
      .split(",")
      .join(", ");
  }

  if (obj["B_CONTRACTOR_SME"]) {
    if (Array.isArray(obj["B_CONTRACTOR_SME"])) {
      obj["B_CONTRACTOR_SME"] = obj["B_CONTRACTOR_SME"].map((sme) =>
        sme === "Y" ? "Yes" : sme === "N/A" ? "N/A" : "NO"
      );
    }
    obj["B_CONTRACTOR_SME"] = obj["B_CONTRACTOR_SME"]
      .toString()
      .split(",")
      .join(", ");
  }

  if (obj["WIN_POSTAL_CODE"]) {
    obj["WIN_POSTAL_CODE"] = obj["WIN_POSTAL_CODE"]
      .toString()
      .split(",")
      .join(", ");
  }

  if (obj["WIN_TOWN"]) {
    obj["WIN_TOWN"] = obj["WIN_TOWN"].toString().split(",").join(", ");
  }

  if (obj["ADMIN_LANGUAGES_TENDER"]) {
    if (Array.isArray(obj["ADMIN_LANGUAGES_TENDER"])) {
      obj["ADMIN_LANGUAGES_TENDER"] = obj["ADMIN_LANGUAGES_TENDER"].map((lan) =>
        isoLangs[lan.toLowerCase()] ? isoLangs[lan.toLowerCase()].name : lan
      );
    }
    obj["ADMIN_LANGUAGES_TENDER"] = obj["ADMIN_LANGUAGES_TENDER"]
      .toString()
      .split(",")
      .join(", ");
  }

  if (obj["ISO_COUNTRY_CODE"]) {
    obj["ISO_COUNTRY_CODE"] =
      obj["ISO_COUNTRY_CODE"] === "UK"
        ? "United Kingdom"
        : countriesIso.getName(obj["ISO_COUNTRY_CODE"], "en")
        ? countriesIso.getName(obj["ISO_COUNTRY_CODE"], "en")
        : "N/A";
  }

  if (obj["ISO_COUNTRY_CODE_ALL"]) {
    if (Array.isArray(obj["ISO_COUNTRY_CODE_ALL"])) {
      obj["ISO_COUNTRY_CODE_ALL"] = obj["ISO_COUNTRY_CODE_ALL"].map(
        (country) => {
          let name =
            country === "UK"
              ? "United Kingdom"
              : countriesIso.getName(country, "en");
          return name;
        }
      );

      obj["ISO_COUNTRY_CODE_ALL"] = obj["ISO_COUNTRY_CODE_ALL"].map((country) =>
        country === "UK"
          ? "United Kingdom"
          : countriesIso.getName(country, "en")
          ? countriesIso.getName(country, "en")
          : country
      );
    }
    obj["ISO_COUNTRY_CODE_ALL"] = obj["ISO_COUNTRY_CODE_ALL"]
      .toString()
      .split(",")
      .join(", ");
  }

  Object.keys(obj).map((data) => {
    if (obj[data] === -1) {
      obj[data] = "N/A";
    }
    if (obj[data] === "N") {
      obj[data] = "No";
    } else if (obj[data] === "Y") {
      obj[data] = "Yes";
    }
  });

  return obj;
};

let renderBetweenDetails = (props) => {
  if (props.data["WIN_NAME"]) {
    return (
      <Col md={12}>
        <hr />
        <Row>
          <Col md={12}>
            <h3>List of contracts between buyer and seller</h3>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={12}>
            <Label>
              <h4>Buyer: {props.data["WIN_NAME"]}</h4>
            </Label>
          </Col>
          <Col md={12}>
            <Label>
              <h4>Seller: {props.data["CAE_NAME"]}</h4>
            </Label>
          </Col>
          {props.data["WIN_NAME"] !== "N/A" &&
            props.data["CAE_NAME"] !== "N/A" && (
              <Col md={12}>
                <Label>
                  <h4>Number of Contracts: {props.between.total}</h4>
                </Label>
              </Col>
            )}
          {(props.data["WIN_NAME"] === "N/A" ||
            props.data["CAE_NAME"] === "N/A") && (
            <Col md={12}>
              <Label>
                <h5>
                  Cannot preview contracts.Seller's or Buyer's name is missing
                </h5>
              </Label>
            </Col>
          )}
        </Row>
      </Col>
    );
  } else {
    return (
      <Col md={12}>
        <hr />
        <Row>
          <Col md={12}>
            <h3>List of seller's contracts notices</h3>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={12}>
            <Label>
              <h4>Seller: {props.data["CAE_NAME"]}</h4>
            </Label>
          </Col>
          <Col md={12}>
            <Label>
              <h4>Number of contracts notices: {props.between.total}</h4>
            </Label>
          </Col>
        </Row>
      </Col>
    );
  }
};

let renderRow = (props) => {
  // console.log("THE PROPS", props)
  // pre process
  // Object.keys(props.data).map(data => {
  //   if (props.data[data] === -1) {
  //     props.data[data] = "N/A"
  //   }
  // })
  let contract = convertValues(Object.assign({}, props.data));
  // props.data = convertValues(props.data)
  // props.data["CAE_TYPE"] = contractingAuthority[props.data["CAE_TYPE"]]
  // props.data["EU_INST_CODE"] = euInstCode[props.data["EU_INST_CODE"]]
  // props.data["TYPE_OF_CONTRACT"] = contractType[props.data["TYPE_OF_CONTRACT"]]
  // props.data["B_FRA_AGREEMENT"] = frameworkAggrement[props.data["B_FRA_AGREEMENT"]]
  // props.data["FRA_ESTIMATED"] = frameworkEstimated[props.data["FRA_ESTIMATED"]]
  // props.data["TOP_TYPE"] = topType[props.data["TOP_TYPE"]]
  // props.data["CRIT_CODE"] = critCode[props.data["CRIT_CODE"]]
  // console.log("TOP_TYPE:", topType[props.data["TOP_TYPE"]], props.data["TOP_TYPE"])
  // console.log("ADDITIONAL", props.data["ADDITIONAL_CPVS"])

  //GET CPV
  // if (cpvs[props.data["CPV"]]) {
  //   props.data["CPV"] = cpvs[props.data["CPV"]]
  // } else {
  //   props.data["CPV"] = "Not available"
  // }

  // if (Array.isArray(props.data["ADDITIONAL_CPVS"])) {
  //   let cpvsNames = props.data["ADDITIONAL_CPVS"].map(cpv => {
  //     if (cpvs[cpv]) {
  //       return cpvs[cpv]
  //     } else {
  //       return "Not Available"
  //     }
  //   })
  //   props.data["ADDITIONAL_CPVS"] = cpvsNames.toString()
  // } else {
  //   props.data["ADDITIONAL_CPVS"] = "Not available"
  // }

  if (props.data.hasOwnProperty("WIN_NAME")) {
    // AWARDED
    // props.data["GPA_COVERAGE"] = gpaCoverage[props.data["GPA_COVERAGE"]]
    // props.data["INFO_ON_NON_AWARD"] = infoNonAward[props.data["INFO_ON_NON_AWARD"]]

    return Object.keys(awardColumns).map((col, i) => {
      return (
        <tr className="d-flex" key={i * i * i}>
          <th className="col-6" key={awardColumns[col]}>
            {awardColumns[col]}
          </th>
          <th className="col-6" key={i}>
            {contract[col]}
          </th>
        </tr>
      );
    });
  } else {
    return Object.keys(noticeColumns).map((col, i) => {
      return (
        <tr key={i * i * i}>
          <th key={noticeColumns[col]}>{noticeColumns[col]}</th>
          <th key={i}>{contract[col]}</th>
        </tr>
      );
    });
  }
};

let renderRelatedNotice = (notice) => {
  // console.log("THE PROPS", props)
  // pre process
  // notice.data =
  // let data = notice.data[0]
  // console.log("THE PROPS 22", notice.data)

  if (typeof notice.data === "object" && !Array.isArray(notice.data)) {
    // console.log("THE PROPS 22 inside notice object if", notice.data)

    let Relatednotice = convertValues(Object.assign({}, notice.data));
    Object.keys(Relatednotice).map((data) => {
      if (Relatednotice[data] === -1) {
        Relatednotice[data] = "N/A";
      }
    });
    const cols = notice.data["WIN_NAME"] ? awardColumns : noticeColumns;
    // console.log("COLS", cols, Relatednotice)
    if (Relatednotice.hasOwnProperty("WIN_NAME")) {
      // AWARDED
      // props.data["GPA_COVERAGE"] = gpaCoverage[props.data["GPA_COVERAGE"]]
      // props.data["INFO_ON_NON_AWARD"] = infoNonAward[props.data["INFO_ON_NON_AWARD"]]

      return Object.keys(awardColumns).map((col, i) => {
        return (
          <tr key={i * i * i}>
            <th key={awardColumns[col]}>{awardColumns[col]}</th>
            <th key={i}>{Relatednotice[col]}</th>
          </tr>
        );
      });
    } else {
      return Object.keys(noticeColumns).map((col, i) => {
        return (
          <tr key={i * i * i}>
            <th key={noticeColumns[col]}>{noticeColumns[col]}</th>
            <th key={i}>{Relatednotice[col]}</th>
          </tr>
        );
      });
    }
  }

  if (notice.data.length === 1) {
    // let Relatednotice = []
    // if (typeof notice.data === "object") {
    //   console.log("Related Notice Object")
    //   Relatednotice = convertValues(Object.assign({}, notice.data))

    // } else {
    //   console.log("Related Notice Array")

    //   Relatednotice = convertValues(Object.assign({}, notice.data[0]))
    // }
    let Relatednotice = convertValues(Object.assign({}, notice.data[0]));

    // console.log("THE PROPS 22 inside notice if", notice.data, Relatednotice)
    Object.keys(Relatednotice).map((data) => {
      if (Relatednotice[data] === -1) {
        Relatednotice[data] = "N/A";
      }
    });

    // Relatednotice["CAE_TYPE"] = contractingAuthority[Relatednotice["CAE_TYPE"]] ? contractingAuthority[Relatednotice["CAE_TYPE"]] : Relatednotice["CAE_TYPE"]

    // Relatednotice["EU_INST_CODE"] = euInstCode[Relatednotice["EU_INST_CODE"]]
    // Relatednotice["TYPE_OF_CONTRACT"] = contractType[Relatednotice["TYPE_OF_CONTRACT"]]
    // Relatednotice["B_FRA_AGREEMENT"] = frameworkAggrement[Relatednotice["B_FRA_AGREEMENT"]]
    // Relatednotice["FRA_ESTIMATED"] = frameworkEstimated[Relatednotice["FRA_ESTIMATED"]]
    // Relatednotice["TOP_TYPE"] = topType[Relatednotice["TOP_TYPE"]] ? topType[Relatednotice["TOP_TYPE"]] : Relatednotice["TOP_TYPE"]
    // Relatednotice["CRIT_CODE"] = critCode[Relatednotice["CRIT_CODE"]]
    // console.log("TOP_TYPE RELATED:", topType[Relatednotice["TOP_TYPE"]], Relatednotice["TOP_TYPE"])
    // console.log("ADDITIONAL", notice.data["ADDITIONAL_CPVS"])

    //GET CPV
    // if (cpvs[Relatednotice["CPV"]]) {
    //   Relatednotice["CPV"] = cpvs[Relatednotice["CPV"]]
    // } else {
    //   Relatednotice["CPV"] = "Not available"
    // }

    // if (Array.isArray(Relatednotice["ADDITIONAL_CPVS"])) {
    //   let cpvsNames = Relatednotice["ADDITIONAL_CPVS"].map(cpv => {
    //     if (cpvs[cpv]) {
    //       return cpvs[cpv]
    //     } else {
    //       return "Not Available"
    //     }
    //   })
    //   Relatednotice["ADDITIONAL_CPVS"] = cpvsNames.toString()
    // } else {
    //   Relatednotice["ADDITIONAL_CPVS"] = "Not available"
    // }

    if (Relatednotice.hasOwnProperty("WIN_NAME")) {
      // AWARDED
      // props.data["GPA_COVERAGE"] = gpaCoverage[props.data["GPA_COVERAGE"]]
      // props.data["INFO_ON_NON_AWARD"] = infoNonAward[props.data["INFO_ON_NON_AWARD"]]

      return Object.keys(awardColumns).map((col, i) => {
        return (
          <tr key={i * i * i}>
            <th key={awardColumns[col]}>{awardColumns[col]}</th>
            <th key={i}>{Relatednotice[col]}</th>
          </tr>
        );
      });
    } else {
      return Object.keys(noticeColumns).map((col, i) => {
        return (
          <tr key={i * i * i}>
            <th key={noticeColumns[col]}>{noticeColumns[col]}</th>
            <th key={i}>{Relatednotice[col]}</th>
          </tr>
        );
      });
    }
  }
};

let renderConnections = (props) => {
  if (props.connections.data.length > 0) {
    let dataSeries = [];
    let from = "";
    if (Array.isArray(props.data["CAE_NAME"])) {
      from = props.data["CAE_NAME"][0];
    } else {
      from = props.data["CAE_NAME"];
    }
    props.connections.data.map((data) => {
      dataSeries.push({
        from: from,
        to: data.key,
        weight: data.count,
      });
    });
    const options = {
      boost: {
        boostThreshold: 1,
        enabled: true,
      },
      chart: {
        type: "dependencywheel",
      },
      title: {
        text: "Connections Between Seller and Buyers",
      },
      series: {
        animation: false,
        data: dataSeries,
      },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
};

const Contract = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [relatedNoticess, setRelatedNotice] = useState({});
  const [optionsSeries, setOptionsSeries] = useState({});
  const { connections } = props;
  const cols = props.data["WIN_NAME"]
    ? tabulatorAwardedCols
    : tabulatorNoticeCols;
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    setRelatedNotice({});
  };

  // let renderConnections = (props) => {
  //   console.log("PROPSSSSSS", props.connections)
  //   if (props.connections.data.length > 0) {
  //     let dataSeries = []
  //     let from = ""
  //     if (Array.isArray(props.data["CAE_NAME"])) {
  //       from = props.data["CAE_NAME"][0]
  //     } else {
  //       from = props.data["CAE_NAME"]

  //     }
  //     props.connections.data.map(data => {
  //       dataSeries.push({
  //         from: from,
  //         to: data.key,
  //         weight: data.count
  //       })
  //     })
  //     const options = {
  //       chart: {
  //         type: 'dependencywheel'
  //       },
  //       title: {
  //         text: 'Connections Between Seller and Buyers'
  //       },
  //       series: {
  //         data: dataSeries
  //       }
  //     }
  //     setOptionsSeries(options)
  //     console.log("PROPSSSSSS ds", dataSeries)

  //     return (
  //       <HighchartsReact
  //         highcharts={Highcharts}
  //         options={optionsSeries}
  //       />
  //     )
  //   }
  // }
  // useEffect(() => {
  //   // Update the document title using the browser API
  //   // document.title = `You clicked ${count} times`;
  //   if (props.connections.data.length > 0) {
  //     let dataSeries = []
  //     let from = ""
  //     if (Array.isArray(props.data["CAE_NAME"])) {
  //       from = props.data["CAE_NAME"][0]
  //     } else {
  //       from = props.data["CAE_NAME"]

  //     }
  //     props.connections.data.map(data => {
  //       dataSeries.push({
  //         from: from,
  //         to: data.key,
  //         weight: data.count
  //       })
  //     })
  //     const options = {
  //       chart: {
  //         type: 'dependencywheel'
  //       },
  //       title: {
  //         text: 'Connections Between Seller and Buyers'
  //       },
  //       series: {
  //         data: dataSeries
  //       }
  //     }
  //     setOptionsSeries(options)
  //     console.log("PROPSSSSSS ds", dataSeries)

  //   }
  // }, [connections]);

  // console.log("STATE", relatedNoticess, Object.keys(relatedNoticess).length)
  // console.log("THIS IS THE PROPS", props)
  const renderArrayRelatedNotice = (related) => {
    return (
      <div>
        <Row className="mt-2">
          <Col md={12}>
            <Label>
              There are more that one related Notices.Click row for preview
            </Label>
          </Col>
        </Row>
        <ReactTabulator
          columns={tabulatorNoticeCols}
          data={related.data}
          rowClick={(e, r) => {
            setRelatedNotice({ data: r._row.data });
          }}
          // layout={"fitData"}
          responsiveLayout={"hide"}
          // Height="70vh"
        />
      </div>
    );
  };
  // let msg = ""
  // if (props.data["WIN_NAME"]) {
  //   console.log("AWARD1")
  //   msg = "List of contracts between buyer and seller"
  // } else {
  //   msg = "List of seller's contracts"
  //   console.log("AWARD2")
  // }

  // const dispatch = useDispatch();
  // let contractsBetweenVar = useSelector(
  //   (state) => state.publicContracts.constractsBetween
  // );

  // useEffect(() => {

  //   // pre process
  //   // console.log("COUNT", count)
  //   // props.data["TED_NOTICE_URL"] = "MALAKAS"
  //   props.data["CAE_TYPE"] = contractingAuthority[props.data["CAE_TYPE"]]
  //   props.data["EU_INST_CODE"] = euInstCode[props.data["EU_INST_CODE"]]
  //   props.data["TYPE_OF_CONTRACT"] = contractType[props.data["TYPE_OF_CONTRACT"]]
  //   props.data["B_FRA_AGREEMENT"] = frameworkAggrement[props.data["B_FRA_AGREEMENT"]]
  //   props.data["FRA_ESTIMATED"] = frameworkEstimated[props.data["FRA_ESTIMATED"]]
  //   props.data["TOP_TYPE"] = topType[props.data["TOP_TYPE"]]
  //   props.data["CRIT_CODE"] = critCode[props.data["CRIT_CODE"]]
  //   if (cpvs[props.data["CPV"]]) {
  //     props.data["CPV"] = cpvs[props.data["CPV"]]
  //   } else {
  //     props.data["CPV"] = "Not available"
  //   }

  //   if (Array.isArray(props.data["ADDITIONAL_CPVS"])) {
  //     let cpvsNames = props.data["ADDITIONAL_CPVS"].map(cpv => {
  //       if (cpvs[cpv]) {
  //         return cpvs[cpv]
  //       } else {
  //         return "Not Available"
  //       }
  //     })
  //     props.data["ADDITIONAL_CPVS"] = cpvsNames.toString()
  //   } else {
  //     props.data["ADDITIONAL_CPVS"] = "Not available"
  //   }
  //   // dispatch(getContractsBetween(
  //   //   {
  //   //     cae_name: props.data["CAE_NAME"],
  //   //     win_name: props.data["WIN_NAME"]
  //   //   },
  //   //   100, "CONTRACT_AWARD_NOTICES"))
  //   console.log("UNMOUNT?", props.data["TOP_TYPE"], topType[props.data["TOP_TYPE"]])
  // }, [])
  // console.log("BETWEEN?", contractsBetweenVar)

  // dispatch(getContractsBetween(
  //   {
  //     cae_name: props.data["CAE_NAME"],
  //     win_name: props.data["WIN_NAME"]
  //   },
  //   100, "CONTRACT_AWARD_NOTICES"))
  // dispatch(getContractsBetween(
  //   {
  //     cae_name: props.data["CAE_NAME"],
  //     win_name: props.data["WIN_NAME"]
  //   },
  //   100, "CONTRACT_AWARD_NOTICES"))

  // HighchartsWheel(Highcharts);

  // console.log("THE PROPS", props.data)
  return (
    <div id="details" className="container">
      {/* <HighchartsReact
        highcharts={Highcharts}
        options={options}
      /> */}
      {/* <iframe id="ifmcontentstoprint" title="print" className="print"></iframe> */}
      <Row>
        <Col md={6}>
          <h3>Details</h3>
        </Col>
        <Col md={6}>
          <InputGroup className="d-flex justify-content-end">
            <Button size="xs" color="primary mr-1" onClick={printContr}>
              <FaPrint size="14px" />
            </Button>
            {/* <Button size="sm" color="primary"  ><FaPrint /></Button> */}
            {/* <Button size="xs" color="primary"  ><FaTimes /></Button> */}
          </InputGroup>
        </Col>
      </Row>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" }, "navTab")}
            onClick={() => {
              toggle("1");
            }}
          >
            {props.data.hasOwnProperty("WIN_NAME")
              ? "Contract Awarded"
              : "Contract Notice"}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" }, "navTab")}
            onClick={() => {
              toggle("2");
            }}
            disabled={props.related.data.length > 0 ? false : true}
          >
            {props.data.hasOwnProperty("WIN_NAME")
              ? "Related Notice"
              : "Related Award Contract"}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" }, "navTab")}
            onClick={() => {
              toggle("3");
            }}
            // disabled={props.related.data.length > 0 ? false : true}
          >
            {props.data.hasOwnProperty("WIN_NAME")
              ? "Contracts Between Buyer and Seller"
              : "Seller's Contracts"}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames(
              {
                active: activeTab === "4",
                invisible: !props.data.hasOwnProperty("WIN_NAME"),
              },
              "navTab"
            )}
            onClick={() => {
              toggle("4");
            }}
            // disabled={props.related.data.length > 0 ? false : true}
          >
            Collaboration Graph
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row id="testPrint">
            <Col md={12}>
              <Table>
                <thead>
                  <tr className="d-flex">
                    <th className="col-6">Label</th>
                    <th className="col-6">Value</th>
                  </tr>
                </thead>
                <tbody>{renderRow(props)}</tbody>
              </Table>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          {props.related.data.length > 1 &&
            renderArrayRelatedNotice(props.related)}
          <Row>
            <Col md={12}>
              <Table>
                {(props.related.data.length === 1 ||
                  Object.keys(relatedNoticess).length > 0) && (
                  <thead>
                    <tr>
                      <th>Label</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {props.related.data.length === 1 &&
                    renderRelatedNotice(props.related)}
                  {Object.keys(relatedNoticess).length > 0 &&
                    renderRelatedNotice(relatedNoticess)}
                </tbody>
              </Table>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>{renderBetweenDetails(props)}</Row>

          <Row>
            <Col md={12}>
              {props.between.data.length > 0 &&
                props.data["WIN_NAME"] !== "N/A" &&
                props.data["CAE_NAME"] !== "N/A" && (
                  <ReactTabulator
                    columns={cols}
                    data={props.between.data}
                    rowClick={rowClick}
                    // layout={"fitData"}
                    responsiveLayout={"hide"}
                    // Height="70vh"
                  />
                )}
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row>
            <Col md={12}>
              {/* {props.connections.data.length > 0 && props.data["WIN_NAME"] && renderConnections(props)} */}
              {/* {props.connections.data.length > 0 && props.data["WIN_NAME"] &&
            <HighchartsReact
              highcharts={Highcharts}
              options={optionsSeries}
            />} */}
              {props.connections.data.length > 0 && props.data["WIN_NAME"] && (
                <ChordDia
                  data={props.data}
                  connections={props.connections.data}
                />
              )}
            </Col>
          </Row>
        </TabPane>
      </TabContent>

      {/* {this.renderContractsBetween()} */}
      {/* </Jumbotron> */}
    </div>
  );
};

export default Contract;
