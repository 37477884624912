import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';

// CUSTOM COMPONENTS
import Avatar from '../../../components/Avatar/Avatar';

const ProfileMain = props => (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody className="profile__card">
        <div className="profile__information">
          <Avatar username={props.user.username} size="md" />
          <div className="profile__data">
            <p className="profile__name">{props.user.username}</p>
            <p className="profile__contact">{props.user.email}</p>
          </div>
        </div>
        <div className="profile__stats">
          <div className="profile__stat">
            <p className="profile__stat-number">{props.counter.query}</p>
            <p className="profile__stat-title">Queries</p>
          </div>
          <div className="profile__stat">
            <p className="profile__stat-number">{props.counter.dataset}</p>
            <p className="profile__stat-title">Data sets</p>
          </div>
          <div className="profile__stat">
            <p className="profile__stat-number">
              {props.counter.visualization}
            </p>
            <p className="profile__stat-title">Visualizations</p>
          </div>
        </div>
      </CardBody>
    </Card>
  </Col>
);

export default ProfileMain;
