/* eslint-disable prefer-destructuring */
import "rc-slider/assets/index.css";
import React, { PureComponent } from "react";
import { Card, CardBody } from "reactstrap";
import Slider from "rc-slider";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// CUSTOM COMPONENTS
import Loader from "../Loader/Loader";
import Error from "../Error/Error";

// CUSTOM DATA
import { rangeChart } from "../../services/helpers/rangeChart";

// CUSTOM SCSS
import "./Range.scss";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

// **********************************************************  //
// This is only fot the Public European Contracts.             //
// Later on when  other datasets are added we need to specify. //
const RANGE_MIN_VALUE = 2006;
const RANGE_MAX_VALUE = 2019;
// **********************************************************  //

export default class RangeTheme extends PureComponent {
  state = {
    min: null,
    max: null,
    value: [],
    isLoading: true,
    error: "",
  };

  static propTypes = {
    marks: PropTypes.shape(),
    tipFormatter: PropTypes.func,
    step: PropTypes.number,
  };

  static defaultProps = {
    marks: {},
    tipFormatter: (value) => value,
  };

  componentDidMount() {
    if (this.props.data && this.props.data.data) {
      this.setState({
        isLoading: this.props.data.fetching,
        error: this.props.data.error,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
      if (this.props.data && this.props.data.data) {
        // Sort data by key value
        const options = this.props.data.data.sort((a, b) => {
          return a.key > b.key ? 1 : -1;
        });
        // Create chart object
        const chart = rangeChart;
        chart.xAxis.categories = [];
        chart.series = [];
        // Fill chart with options values
        const series = {
          data: [],
        };
        options.forEach((option) => {
          chart.xAxis.categories.push(option.key);
          series.data.push(option.count);
        });
        chart.series.push(series);
        if (options.length > 0) {
          this.setState({
            data: chart,
            min: RANGE_MIN_VALUE,
            max: RANGE_MAX_VALUE,
            value: [options[0].key, options[options.length - 1].key],
            isLoading: this.props.data.fetching,
          });
        }
      } else {
        this.setState({
          min: null,
          max: null,
          value: [],
        });
      }
      this.setState({
        isLoading: this.props.data.fetching,
        error: this.props.data.error,
      });
    }
  }

  handleChange = (range) => {
    this.setState({
      isLoading: true,
    });
    const newRange = [];
    newRange.push(range[0]);
    newRange.push(range[1]);
    this.props.onFilterUpdate(this.props.data.id, newRange);
  };

  render() {
    // console.log("anesid Inside Range State: ", this.state);
    // console.log("anesid Inside Range Props: ", this.props);

    return (
      <Card className="slider__card">
        <CardBody>
          {this.props.data && (
            <div className="card__title">
              <h5 className="bold-text" style={{ color: "#5C6A81" }}>
                {this.props.data.title}
              </h5>
            </div>
          )}
          <Loader loading={this.state.isLoading} simple={true} />
          {!this.state.isLoading && (
            <>
              <Error error={this.state.error} />
              {this.state.value.length > 0 && (
                <>
                  {this.props.chart && (
                    <HighchartsReact
                      highcharts={Highcharts}
                      className="short-chart"
                      options={this.state.data}
                    />
                  )}
                  <div className="slider">
                    <div className="slider__min">
                      <p>
                        {this.props.tipFormatter
                          ? this.props.tipFormatter(this.state.min)
                          : this.state.min}
                      </p>
                    </div>
                    <div className="slider__max">
                      <p>
                        {this.props.tipFormatter
                          ? this.props.tipFormatter(this.state.max)
                          : this.state.max}
                      </p>
                    </div>
                    <div className="range_container">
                      <Range
                        min={this.state.min}
                        max={this.state.max}
                        defaultValue={this.state.value}
                        tipFormatter={this.props.tipFormatter}
                        marks={this.props.marks}
                        step={this.props.step}
                        onAfterChange={this.handleChange}
                        allowCross={false}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </CardBody>
      </Card>
    );
  }
}
