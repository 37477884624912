import React from 'react';
import Chart from '../Chart/Chart';

const Visualization = props => {
  return (
    <div className="result">
      <Chart
        data={props.data}
        draggable={props.draggable}
        onFilterUpdate={() => {}}
        filters={props.filters}
        title={props.filters.data.resultsType}
      ></Chart>
    </div>
  );
};

export default Visualization;
