let timeSeriesChartCountry = {
  chart: {
    type: "line",
  },
  title: {
    text: "",
  },
  yAxis: [
    {
      title: {
        text: "Amount (€)",
      },
      type: "logarithmic",
    },
  ],
  xAxis: {
    accessibility: {
      rangeDescription: "",
    },
  },
  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "middle",
  },

  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      pointStart: 2006,
    },
  },

  series: [],

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
          },
        },
      },
    ],
  },
};

let timeSeriesChartOrganization = {
  chart: {
    type: "line",
  },
  title: {
    text: "",
  },
  yAxis: [
    {
      title: {
        text: "Amount (€)",
      },
      type: "logarithmic",
    },
  ],
  xAxis: {
    accessibility: {
      rangeDescription: "",
    },
  },
  legend: {
    enabled: false,
  },

  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      pointStart: 2006,
    },
  },

  series: [],

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
          },
        },
      },
    ],
  },
};

export { timeSeriesChartCountry, timeSeriesChartOrganization };
