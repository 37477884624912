import React from 'react';
import PropTypes from 'prop-types';

// CUSTOM SCSS
import './Error.scss';

const Error = ({ error, page }) => {
  return (
    <>
      {error && (
        <div className={`error-container ${page ? ' page-error' : ''}`}>
          {error}
        </div>
      )}
    </>
  );
};

Error.propTypes = {
  error: PropTypes.string
};

Error.defaultProps = {
  error: ''
};

export default Error;
