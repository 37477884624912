import React, { useState } from "react";
import {
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

// COMPONENTS
import Chart from "../Chart/Chart";
//import MatTable from "../MatTable/MatTable";
import GridTable from "../GridTable/GridTable";

const filters = {
  data: {
    chartType: "bar",
  },
};

const CountryInfo = (props) => {
  const { info } = props;

  const [activeChartTab, setActiveChartTab] = useState(0);
  const [activeTableTab, setActiveTableTab] = useState(0);

  const toggleTab = (type, tab) => {
    if (type === "chart") {
      setActiveChartTab(tab);
    } else {
      setActiveTableTab(tab);
    }
  };

  // const updateSearchGrid = (identifier) => {
  //   console.log(identifier, " was pressed");
  // };

  return (
    <>
      <Row>
        <Col>
          <div className="tabs tabs_results">
            <div className="tabs__wrap">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeChartTab === 0,
                    })}
                    onClick={() => {
                      toggleTab("chart", 0);
                    }}
                  >
                    Buyers
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeChartTab === 1,
                    })}
                    onClick={() => {
                      toggleTab("chart", 1);
                    }}
                  >
                    Sellers
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeChartTab}>
                <TabPane tabId={0}>
                  <div className="result">
                    <Chart data={info.yearDataISO} filters={filters}></Chart>
                  </div>
                </TabPane>
                <TabPane tabId={1}>
                  <div className="result">
                    <Chart data={info.yearDataWIN} filters={filters}></Chart>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="tabs tabs_results">
            <div className="tabs__wrap">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTableTab === 0,
                    })}
                    onClick={() => {
                      toggleTab("table", 0);
                    }}
                  >
                    Awards
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTableTab === 1,
                    })}
                    onClick={() => {
                      toggleTab("table", 1);
                    }}
                  >
                    Contracts
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTableTab}>
                <TabPane tabId={0}>
                  {/* <div className="result">
                    <MatTable
                      data={info.searchDataCountrySELLERS}
                      rows={10}
                      onNextSearchPage={() => {
                        props.getNextSearchPage(
                          "searchDataCountrySELLERS",
                          info.searchDataCountrySELLERS.from,
                          info.dataTotals.data.code
                        );
                      }}
                      checkBox={false}
                      export={true}
                      innerPath={true}
                    ></MatTable>
                  </div>
                  <br /> */}
                  <div
                    className="result"
                    style={{
                      marginLeft: "20px",
                      marginRight: "20px",
                      marginTop: "20px",
                    }}
                  >
                    <GridTable
                      identifier="allDataResult"
                      data={info.searchDataCountrySELLERS}
                      updateSearchGrid={(identifier) => {
                        let from =
                          identifier === "next"
                            ? info.searchDataCountrySELLERS.from
                            : info.searchDataCountrySELLERS.from - 20;
                        props.getNextSearchPage(
                          "searchDataCountrySELLERS",
                          from,
                          info.dataTotals.data.code
                        );
                      }}
                      export={true}
                    />
                  </div>
                </TabPane>
                <TabPane tabId={1}>
                  {/* <div className="result">
                    <MatTable
                      data={info.searchDataCountryBUYERS}
                      rows={10}
                      onNextSearchPage={() => {
                        props.getNextSearchPage(
                          "searchDataCountryBUYERS",
                          info.searchDataCountryBUYERS.from,
                          info.dataTotals.data.code
                        );
                      }}
                      checkBox={false}
                      export={true}
                      innerPath={true}
                    ></MatTable>
                  </div>
                  <br /> */}
                  <div
                    className="result"
                    style={{
                      marginLeft: "20px",
                      marginRight: "20px",
                      marginTop: "20px",
                    }}
                  >
                    <GridTable
                      identifier="allDataResult"
                      data={info.searchDataCountryBUYERS}
                      updateSearchGrid={(identifier) => {
                        let from =
                          identifier === "next"
                            ? info.searchDataCountryBUYERS.from
                            : info.searchDataCountryBUYERS.from - 20;
                        props.getNextSearchPage(
                          "searchDataCountryBUYERS",
                          from,
                          info.dataTotals.data.code
                        );
                      }}
                      export={true}
                    />
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CountryInfo;
