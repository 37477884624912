import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { RTLProps } from "../../shared/prop-types/ReducerProps";
import ColumnResizer from "react-column-resizer";

class MatTableHead extends PureComponent {
  state = {
    heads: [],
    subheads: [],
  };

  static propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rtl: RTLProps.isRequired,
  };

  componentDidMount() {
    this.setState({
      heads: this.props.subheads
        ? this.props.heads
        : this.convert2dArrayTo1d(this.props.heads),
      subheads: this.props.subheads,
    });
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.heads) !== JSON.stringify(prevProps.heads)) {
      if (this.props.heads && this.props.heads.length > 0) {
        this.setState({
          heads: this.props.subheads
            ? this.props.heads
            : this.convert2dArrayTo1d(this.props.heads),
          subheads: this.props.subheads,
        });
      }
    }
  }

  convert2dArrayTo1d = (array) => {
    let concatArray = [];
    array.forEach((element, index) => {
      if (element.length > 0) {
        concatArray = concatArray.concat(element);
      } else {
        concatArray = concatArray.concat([
          {
            id: "empty-subhead-" + index,
            label: "",
            disablePadding: false,
          },
        ]);
      }
    });
    return concatArray;
  };

  createSortHandler = (property) => (event) => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, rtl } = this.props;

    return (
      <TableHead>
        <TableRow>
          {this.props.checkBox && <TableCell padding="checkbox"></TableCell>}
          {this.state.heads.map(
            (col, index) => (
              <TableCell
                className="material-table__cell material-table__cell--sort material-table__cell-right"
                key={col.id}
                align={rtl.direction === "rtl" ? "right" : "left"}
                padding={col.disablePadding ? "none" : "default"}
                sortDirection={orderBy === col.id ? order : false}
                colSpan={
                  this.state.subheads ? this.state.subheads[index].length : "1"
                }
              >
                {/* {console.log("wtf", col, index)} */}
                <TableSortLabel
                  active={orderBy === col.id}
                  direction={order}
                  onClick={this.createSortHandler(col.id)}
                  className="material-table__sort-label"
                  dir="ltr"
                >
                  <div style={{ width: col.width + " px" }}>{col.label}</div>
                </TableSortLabel>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

export default connect((state) => ({
  rtl: state.rtl,
}))(MatTableHead);
