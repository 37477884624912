import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Alerts from '../../../containers_/UI/Alerts/index';
import Buttons from '../../../containers_/UI/Buttons/index';
import Carousel from '../../../containers_/UI/Carousel/index';
import Collapse from '../../../containers_/UI/Collapse/index';
import Grids from '../../../containers_/UI/Grids';
import Modals from '../../../containers_/UI/Modals/index';
import Notifications from '../../../containers_/UI/Notification/index';
import Panels from '../../../containers_/UI/Panels/index';
import ProgressBars from '../../../containers_/UI/ProgressBars/index';
import RangeSliders from '../../../containers_/UI/RangeSliders/index';
import Tabs from '../../../containers_/UI/Tabs/index';
import Timeline from '../../../containers_/UI/Timeline/index';
import Tooltips from '../../../containers_/UI/Tooltips/index';
import Typography from '../../../containers_/UI/Typography/index';

export default () => (
  <Switch>
    <Route path="/ui/alerts" component={Alerts} />
    <Route path="/ui/buttons" component={Buttons} />
    <Route path="/ui/carousel" component={Carousel} />
    <Route path="/ui/collapse" component={Collapse} />
    <Route path="/ui/grids" component={Grids} />
    <Route path="/ui/modals" component={Modals} />
    <Route path="/ui/notifications" component={Notifications} />
    <Route path="/ui/panels" component={Panels} />
    <Route path="/ui/progress_bars" component={ProgressBars} />
    <Route path="/ui/range_sliders" component={RangeSliders} />
    <Route path="/ui/tabs" component={Tabs} />
    <Route path="/ui/timeline" component={Timeline} />
    <Route path="/ui/tooltips" component={Tooltips} />
    <Route path="/ui/typography" component={Typography} />
  </Switch>
);
