import {
  apiRequest
} from '../../../services/api/index';

import {
  fetchData,
  setData,
  setError,
  setNestedData,
  resetFilters
} from './publicContractsActions';
import {
  getAdditionalCPVs
} from './additionalCpvActions';

export const SET_FILTER = 'SET_FILTER';
export const RESET_FILTERS = 'RESET_FILTERS';

export function setFilter(payload) {
  return async dispatch => {
    if (payload.key === "cpv" &&
      payload.value != null) {
      await dispatch(getAdditionalCPVs({
        cpv: payload.value
      }))
    }
    await dispatch(setNestedData('filters', 'data', payload));
  };
}

export function reset_filters() {
  return async dispatch => {
    // dispatch(setData('cpvsTable', []))
    dispatch(setData('cpvsTable', []))
    dispatch(setData('additional_cpvs', []))
    dispatch(resetFilters());
  };
}

/**
 * Get filters from dataset
 * @param {string} datasetId
 */
export const getFilters = datasetId => {
  return async dispatch => {
    dispatch(fetchData('filters'));
    try {
      const request = {
        type: 'dataset',
        dynamic_url: datasetId
      };
      const response = await apiRequest(request);
      if (response && response.status === 200) {
        var filterData = {
          data: response.data[0].data[0]
        };
        dispatch(setData('filters', filterData));
      } else {
        dispatch(setError('filters', response.data.error));
      }
    } catch (error) {
      dispatch(setError('filters', 'Something went wrong. Please try later!'));
    }
  };
};