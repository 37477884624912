import { apiRequest } from '../../services/api/index';

/**
 * Action Types
 */

export const MY_DATASETS = 'MY_DATASETS';
export const ADD_DATASET = 'ADD_DATASET';
export const DATASET_ERROR = 'DATASET_ERROR';
export const RESET_ADD_DATASET = 'RESET_ADD_DATASET';
export const FETCHING_API = 'FETCHING_API';
export const RESET_MY_DATASETS = 'RESET_MY_DATASETS';
export const RESET_DATASET_INDICATORS = 'RESET_DATASET_INDICATORS';

/** Error message for network down */
const catch_error = 'Please try again later';

/**
 *  ACTION CREATORS
 */

/**
 * Sets loading value
 * @param {boolean} loading
 */
export const fetch_api = loading => {
  return {
    type: FETCHING_API,
    payload: loading
  };
};

/**
 * Sets datasets retrieved
 * @param {Array} my_datasets
 */
export const my_datasets = my_datasets => {
  return {
    type: MY_DATASETS,
    payload: my_datasets
  };
};

/**
 * Sets newly added dataset
 * @param {Object} dataset
 */
export const add_dataset = dataset => {
  return {
    type: ADD_DATASET,
    payload: dataset
  };
};

/**
 * Resets recently added dataset
 */
export const reset_add_dataset = () => {
  return {
    type: RESET_ADD_DATASET
  };
};

/**
 * Sets dataset error message
 * @param {String} message
 */
export const dataset_error = message => {
  return {
    type: DATASET_ERROR,
    payload: message
  };
};

/**
 * Resets loading and error
 */
export const resetDatasetIndicators = () => {
  return {
    type: RESET_DATASET_INDICATORS
  };
};

/**
 * ACTIONS THAT CALL API
 */

/**
 * myDatasets added dataset to database
 */
export const getMyDatasets = params => {
  return async dispatch => {
    dispatch(fetch_api(true));
    try {
      const request = {
        type: 'myDatasets',
        data: params ? params : {}
      };
      const response = await apiRequest(request);
      if (response && response.status === 200) {
        if (response.data.length > 0) {
          dispatch(my_datasets(response.data));
        }
        dispatch(fetch_api(false));
        return true;
      } else {
        dispatch(dataset_error(response.data.error));
      }
    } catch (error) {
      dispatch(dataset_error(catch_error));
    }
    dispatch(fetch_api(false));
    return false;
  };
};

/**
 * Saves new added dataset to database
 * @param {Object} dataset
 */
export const addDataset = dataset => {
  return async dispatch => {
    dispatch(fetch_api(true));
    try {
      const request = {
        type: 'create',
        data: dataset
      };
      const response = await apiRequest(request);
      if (response && response.status === 200) {
        dispatch(add_dataset(response.data));
        dispatch(fetch_api(false));
        return true;
      } else {
        dispatch(dataset_error(response.data.error));
      }
    } catch (error) {
      dispatch(dataset_error(catch_error));
    }
    dispatch(fetch_api(false));
    return false;
  };
};
