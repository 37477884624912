import {
  FETCHING_API,
  SET_VISUALIZATION,
  VISUALIZATION_ERROR,
  RESET_VISUALIZATION
} from '../actions/visualizationActions';

const initialState = {
  item: {
    id: '',
    title: '',
    type: '',
    access_level: '',
    tags: [],
    data: []
  },
  fetching: false,
  error: ''
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCHING_API:
      return {
        ...state,
        item: {
          ...state.item,
          id: '',
          title: '',
          type: '',
          access_level: '',
          tags: [],
          data: []
        },
        fetching: true,
        error: ''
      };
    case SET_VISUALIZATION:
      return {
        ...state,
        item: {
          id: action.payload.id,
          title: action.payload.title,
          type: action.payload.type,
          access_level: action.payload.access_level,
          tags: action.payload.tags,
          data: action.payload.data
        },
        fetching: false
      };
    case RESET_VISUALIZATION:
      return {
        ...state,
        item: {
          ...state.item,
          id: '',
          title: '',
          type: '',
          access_level: '',
          tags: [],
          data: []
        }
      };
    case VISUALIZATION_ERROR:
      return {
        ...state,
        item: {
          ...state.item,
          id: '',
          title: '',
          type: '',
          access_level: '',
          tags: [],
          data: []
        },
        error: action.payload,
        fetching: false
      };
    default:
      return state;
  }
}
