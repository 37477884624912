/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Topbar from './topbar/Topbar';
import TopbarWithNavigation from './topbar_with_navigation/TopbarWithNavigation';
import Sidebar from './sidebar/Sidebar';
import SidebarMobile from './topbar_with_navigation/sidebar_mobile/SidebarMobile';
import {
  changeMobileSidebarVisibility,
  changeSidebarVisibility
} from '../../redux/actions/sidebarActions';
import {
  changeThemeToDark,
  changeThemeToLight
} from '../../redux/actions/themeActions';
import {
  changeDirectionToRTL,
  changeDirectionToLTR
} from '../../redux/actions/rtlActions';
import {
  changeBorderRadius,
  toggleBoxShadow,
  toggleTopNavigation
} from '../../redux/actions/customizerActions';
import {
  CustomizerProps,
  SidebarProps,
  UserProps
} from '../../shared/prop-types/ReducerProps';

import authStorage from '../../services/authentication/authStorage';

class Layout extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebar: SidebarProps.isRequired,
    customizer: CustomizerProps.isRequired,
    user: UserProps.isRequired
  };

  changeSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeSidebarVisibility());
  };

  changeMobileSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeMobileSidebarVisibility());
  };

  changeToDark = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToLight());
  };

  changeToRTL = () => {
    const { dispatch } = this.props;
    dispatch(changeDirectionToRTL());
  };

  changeToLTR = () => {
    const { dispatch } = this.props;
    dispatch(changeDirectionToLTR());
  };

  toggleTopNavigation = () => {
    const { dispatch } = this.props;
    dispatch(toggleTopNavigation());
  };

  changeBorderRadius = () => {
    const { dispatch } = this.props;
    dispatch(changeBorderRadius());
  };

  toggleBoxShadow = () => {
    const { dispatch } = this.props;
    dispatch(toggleBoxShadow());
  };

  render() {
    const { customizer, sidebar, user } = this.props;
    const auth = authStorage.getAuth('auth');
    const layoutClass = classNames({
      layout: true,
      'layout--collapse': auth ? sidebar.collapse : true,
      'layout--top-navigation': customizer.topNavigation
    });

    return (
      <div className={layoutClass}>
        {customizer.topNavigation ? (
          <TopbarWithNavigation
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          />
        ) : (
          <Topbar
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            changeSidebarVisibility={this.changeSidebarVisibility}
            user={user}
          />
        )}
        {auth && (
          <>
            {customizer.topNavigation ? (
              <SidebarMobile
                sidebar={sidebar}
                changeToDark={this.changeToDark}
                changeToLight={this.changeToLight}
                changeMobileSidebarVisibility={
                  this.changeMobileSidebarVisibility
                }
              />
            ) : (
              <Sidebar
                sidebar={sidebar}
                changeToDark={this.changeToDark}
                changeToLight={this.changeToLight}
                changeMobileSidebarVisibility={
                  this.changeMobileSidebarVisibility
                }
              />
            )}
          </>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(state => ({
    customizer: state.customizer,
    sidebar: state.sidebar,
    user: state.user
  }))(Layout)
);
