import React from "react";
import { Route } from "react-router-dom";
import Layout from "../../Layout/Layout";
import Commerce from "./Commerce";
import Crypto from "./Crypto";
import Documentation from "./Documentation";
import DefaultPages from "./DefaultPages";
import Account from "./Account";
import ECommerce from "./ECommerce";
import Maps from "./Maps";
import Charts from "./Charts";
import Tables from "./Tables";
import Forms from "./Forms";
import UI from "./UI";

import Chat from "../../../containers_/Chat/index";
import Todo from "../../../containers_/Todo/index";

import FitnessDashboard from "../../../containers_/Dashboards/Fitness/index";
import DefaultDashboard from "../../../containers_/Dashboards/Default/index";
import MobileAppDashboard from "../../../containers_/Dashboards/MobileApp/index";
import BookingDashboard from "../../../containers_/Dashboards/Booking/index";

import Mail from "../../../containers_/Mail/index";

import Home from "../../Home/Home";
import Profile from "../../Profile/Profile";
import Details from "../../Details/Details";
import PublicContracts from "../../PublicContracts/PublicContracts";
// anesid: Import Components to render for the split Public Contracts refactor //
//
import BuyerCountries from "../../PublicContracts/BuyerCountries";
import SellerCountries from "../../PublicContracts/SellerCountries";
import CPVs from "../../PublicContracts/CPVs";
import searchContract from "../../PublicContracts/searchContract"
import CountryComparison from "../../PublicContracts/CountryComparison"
//
// anesid
import Datasets from "../../Datasets/Datasets";
import Visualizations from "../../Visualizations/Visualizations";
import Stories from "../../Stories/Stories";
import Story from "../../Story/Story";
import NewStory from "../../NewStory/NewStory";

export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route exact path="/" component={Home} />
      <Route exact path="/public_contracts" component={PublicContracts} />
      <Route path={`/public_contracts/:id`} component={PublicContracts} />
      <Route path={`/countries/:id`} component={Details} />
      <Route path={`/sellers/:id`} component={Details} />
      <Route path={`/buyers/:id`} component={Details} />

      {/* anesid: Add routes for the split Public Contracts refactor  */}
      <Route exact path="/buyer_countries" component={BuyerCountries} />
      <Route path={`/buyer_countries/:id`} component={BuyerCountries} />
      <Route exact path="/seller_countries" component={SellerCountries} />
      <Route path={`/seller_countries/:id`} component={SellerCountries} />
      <Route exact path="/cpvs" component={CPVs} />
      <Route path={`/cpvs/:id`} component={CPVs} />
      <Route path={'/country_comparison'} component={CountryComparison} />
      <Route exact path="/searchContract" component={searchContract} />


      {/* anesid */}

      {/* Visualizations page will be or not. So re-route to Home for now */}
      <Route exact path="/visualization" component={Home} />
      <Route path={`/visualization/:id`} component={Visualizations} />
      <Route path={`/dataset/:id`} component={Datasets} />
      <Route path="/profile" component={Profile} />

      <Route path="/stories" component={Stories} />
      <Route path="/story/:id" component={Story} />
      <Route exact path="/new_story" component={NewStory} />

      <Route path="/dashboard_default" component={DefaultDashboard} />
      <Route path="/dashboard_e_commerce" component={Commerce} />
      <Route path="/dashboard_fitness" component={FitnessDashboard} />
      <Route path="/dashboard_crypto" component={Crypto} />
      <Route
        exact
        path="/dashboard_mobile_app"
        component={MobileAppDashboard}
      />
      <Route path="/dashboard_booking" component={BookingDashboard} />
      <Route path="/ui" component={UI} />
      <Route path="/mail" component={Mail} />
      <Route path="/chat" component={Chat} />
      <Route path="/todo" component={Todo} />
      <Route path="/forms" component={Forms} />
      <Route path="/tables" component={Tables} />
      <Route path="/charts" component={Charts} />
      <Route path="/maps" component={Maps} />
      <Route path="/account" component={Account} />
      <Route path="/e-commerce" component={ECommerce} />
      <Route path="/default_pages" component={DefaultPages} />
      <Route path="/documentation" component={Documentation} />
    </div>
  </div>
);
