import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ChartsJS from '../../../containers_/Charts/ChartJs/index';
import ReactVis from '../../../containers_/Charts/ReactVis/index';
import Recharts from '../../../containers_/Charts/Recharts/index';

export default () => (
  <Switch>
    <Route path="/charts/charts_js" component={ChartsJS} />
    <Route path="/charts/react_vis" component={ReactVis} />
    <Route path="/charts/recharts" component={Recharts} />
  </Switch>
);
