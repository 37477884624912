import React, { useState, useRef, useEffect, Fragment } from "react";
import { Col, Container, Row, Button } from "reactstrap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Loader from "../Loader/Loader";
import { Card, CardBody } from "reactstrap";
import DrillDown from "highcharts/modules/drilldown";
import HighchartsSunburst from "highcharts/modules/sunburst";
import Boost from "highcharts/modules/boost";

Boost(Highcharts);
HighchartsSunburst(Highcharts);
// HighchartsWheel(Highcharts);

const colors = [
  "#63b598",
  "#ce7d78",
  "#ea9e70",
  "#a48a9e",
  "#c6e1e8",
  "#648177",
  "#0d5ac1",
  "#f205e6",
  "#1c0365",
  "#14a9ad",
  "#4ca2f9",
  "#a4e43f",
  "#d298e2",
  "#6119d0",
  "#d2737d",
  "#c0a43c",
  "#f2510e",
  "#651be6",
  "#79806e",
  "#61da5e",
  "#cd2f00",
  "#9348af",
  "#01ac53",
  "#c5a4fb",
  "#996635",
  "#b11573",
  "#4bb473",
  "#75d89e",
  "#2f3f94",
  "#2f7b99",
  "#da967d",
  "#34891f",
  "#b0d87b",
  "#ca4751",
  "#7e50a8",
  "#c4d647",
  "#e0eeb8",
  "#11dec1",
  "#289812",
  "#566ca0",
  "#ffdbe1",
  "#2f1179",
  "#935b6d",
  "#916988",
  "#513d98",
  "#aead3a",
  "#9e6d71",
  "#4b5bdc",
  "#0cd36d",
  "#250662",
  "#cb5bea",
  "#228916",
  "#ac3e1b",
  "#df514a",
  "#539397",
  "#880977",
  "#f697c1",
  "#ba96ce",
  "#679c9d",
  "#c6c42c",
  "#5d2c52",
  "#48b41b",
  "#e1cf3b",
  "#5be4f0",
  "#57c4d8",
  "#a4d17a",
  "#225b8",
  "#be608b",
  "#96b00c",
  "#088baf",
  "#f158bf",
  "#e145ba",
  "#ee91e3",
  "#05d371",
  "#5426e0",
  "#4834d0",
  "#802234",
  "#6749e8",
  "#0971f0",
  "#8fb413",
  "#b2b4f0",
  "#c3c89d",
  "#c9a941",
  "#41d158",
  "#fb21a3",
  "#51aed9",
  "#5bb32d",
  "#807fb",
  "#21538e",
  "#89d534",
  "#d36647",
  "#7fb411",
  "#0023b8",
  "#3b8c2a",
  "#986b53",
  "#f50422",
  "#983f7a",
  "#ea24a3",
  "#79352c",
  "#521250",
  "#c79ed2",
  "#d6dd92",
  "#e33e52",
  "#b2be57",
  "#fa06ec",
];
const DonutPie = (props) => {
  const [optionsSeries, setOptionsSeries] = useState({});
  const [load, setLoad] = useState(true);
  useEffect(() => {
    // var colors = Highcharts.getOptions().colors;
    // console.log("Donut Pie props", props.data)
    let data = [];
    props.data.data.map((cpv, index) => {
      // console.log("COLORSSS", Highcharts.getOptions().colors[index])
      data.push({
        id: index.toString(),
        tooltipIncluded: true,
        color: colors[index],
        name: cpv.key,
        value: cpv.amount,
      });

      if (Array.isArray(cpv.additionals) && cpv.additionals.length > 0) {
        cpv.additionals.map((addCPV, i) => {
          data.push({
            id: i + "a" + index,
            parent: index.toString(),
            name: addCPV.key,
            value: addCPV.euro_total.value,
            tooltipIncluded: true,
          });
        });
      }
    });
    const chartOptions = {
      title: { text: "" },
      // chart: {
      //   events: {
      //     load() {
      //       console.log("Loaded event")
      //       // alert("haha")
      //       // this.showLoading();
      //       // setTimeout(this.hideLoading.bind(this), 2000);
      //     }
      //   },
      //   margin: [0, 0, 0, 0],
      //   spacingTop: 0,
      //   spacingBottom: 0,
      //   spacingLeft: 0,
      //   spacingRight: 0,
      //   // boost: {
      //   //   enabled: true,
      //   //   debug: { timeSetup: true }
      //   // },

      // },

      series: {
        type: "sunburst",
        boostThreshold: 1,
        data: data,
        allowDrillToNode: true,
        cursor: "pointer",
        // allowPointDrilldown: true
      },
    };
    setOptionsSeries(chartOptions);
    setLoad(props.data.fetching);
  }, [props.data]);

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Amount Per Cpv (€) (Top 20)</h5>
        </div>
        <Loader loading={load} />

        {optionsSeries && (
          <Fragment>
            <HighchartsReact
              highcharts={Highcharts}
              options={optionsSeries}
              onRender={(e) => {
                console.log(" ");
              }}
            />
          </Fragment>
        )}
      </CardBody>
    </Card>
  );
};

export default DonutPie;
