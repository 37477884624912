import React, { PureComponent } from "react";
import { Col, Container, Row, Button } from "reactstrap";
import { MenuItem, FormControl, Select } from "@material-ui/core";
import { withRouter } from "react-router";

// REDUX
import { connect } from "react-redux";
import {
  setFilter,
  reset_filters,
  getFilters,
} from "../../redux/actions/publicContracts/filtersActions";
import { getResult } from "../../redux/actions/publicContracts/resultActions";
import { getBuyers } from "../../redux/actions/publicContracts/buyersActions";
import { getFacets } from "../../redux/actions/publicContracts/facetActions";
import { getSellers } from "../../redux/actions/publicContracts/sellersActions";
import { getCPVs } from "../../redux/actions/publicContracts/cpvsActions";
import { getSearch } from "../../redux/actions/publicContracts/searchActions";

// CUSTOM COMPONENTS
import Chart from "../../components/Chart/Chart";
import ChartProps from "../../components/ChartProps/ChartProps";
import FacetList from "../../components/FacetList/FacetList";
import MapChart from "../../components/MapChart/MapChart";
import MatTable from "../../components/MatTable/MatTable";
import Range from "../../components/Range/Range";
import SelectRange from "../../components/SelectRange/SelectRange";
import Collapse from "../../components/Collapse/Collapse";
import ModalDataset from "../../components/ModalDataset/ModalDataset";
import Loader from "../../components/Loader/Loader";
import Error from "../../components/Error/Error";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { fadeIn } from "react-animations";
import Radium, { StyleRoot } from "radium";
import GridTable from "../../components/GridTable/GridTable";

// CUSTOM DATA
import {
  buyerCountriesrResultTypes,
  resultTypes,
} from "../../services/platform/utils";
import QueryDetails from "../../components/QueryDetails/QueryDetails";

// CUSTOM SCSS
import "./BuyerCountries.scss";

// ANIMATIONS
const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
  },
};

class BuyerCountries extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  state = {
    enableReset: false,
    isLoading: false,
    initialFilters: false,
    error: "",
    activeTab: "1",
  };

  componentDidMount() {
    //console.log("anesid: ", this.props.match.params.id);
    if (this.props.match.params.id) {
      // Loads preselected filters from dataset
      this.props.getFilters(this.props.match.params.id);
      this.setState({ isLoading: true, initialFilters: true });
    } else {
      // Loads initial data
      //console.log("anesid intial load: ", this.props.filters);
      this.props.setFilter({ key: "resultsType", value: "WIN_COUNTRY_CODE" });
      this.loadApiData();
    }
  }

  componentWillUnmount() {
    this.props.resetFilters();
  }

  componentDidUpdate(prevProps) {
    // Loads new data when filters are updated
    if (
      JSON.stringify(this.props.filters) !== JSON.stringify(prevProps.filters)
    ) {
      if (this.props.filters.fetching !== prevProps.filters.fetching) {
        this.setState({ isLoading: this.props.filters.fetching });
      }
      if (this.props.filters.error !== prevProps.filters.error) {
        this.setState({ error: this.props.filters.error });
      }
      if (
        (this.props.filters.data.chartType !==
          prevProps.filters.data.chartType ||
          this.props.filters.data.resultsType !==
            prevProps.filters.data.resultsType) &&
        !this.props.filters.fetching
      ) {
        if (this.state.initialFilters) {
          // If it is the first time a saved query is load, get all data from api
          this.loadApiData();
          this.setState({ initialFilters: false });
        } else {
          // Update only results for chart and results type change
          this.props.getResult(this.props.filters.data, 0, "buyerCountries");
        }
      } else {
        if (!this.state.initialFilters) {
          this.loadApiData();
        }
      }
    }
  }

  /**
   * Loads all data from Redux/API
   */
  loadApiData = () => {
    this.props.getFacets(this.props.filters.data, "buyerCountries");
    this.props.getBuyers(this.props.filters.data, 0);
    this.props.getSellers(this.props.filters.data, 0);
    this.props.getCPVs(this.props.filters.data, 0);

    // Handle the case of the intial load with checking this.props.match.params.id

    this.props.getSearch(this.props.filters.data, 0, "buyerCountries");
    this.props.getResult(this.props.filters.data, 0, "buyerCountries");
  };

  /**
   * Updates a pair of filters in Redux
   * @param {*} key
   * @param {*} value
   */
  updateFilter = (key, value) => {
    if (this.state.enableReset === false) {
      this.setState({ enableReset: true });
    }
    //console.log("filter ", key, value);
    this.props.setFilter({ key, value });
  };

  /**
   * Resets all filters in Redux
   */
  resetFilters = () => {
    this.setState({ enableReset: false });
    this.props.resetFilters();
  };

  /**
   * Loads next page search data from API
   */
  getNextSearchPage = () => {
    this.props.getSearch(
      this.props.filters.data,
      this.props.search.from,
      "buyerCountries"
    );
  };

  updateSearchGrid = (identifier) => {
    let from =
      identifier === "next"
        ? this.props.search.from
        : this.props.search.from - 20;
    this.props.getSearch(this.props.filters.data, from, "buyerCountries");
  };

  /**
   * Loads next page cpvs data from API
   */
  getNextCPVsPage = () => {
    this.props.getCPVs(this.props.filters.data, this.props.cpvsTable.from);
  };

  /**
   * Loads next page sellers data from API
   */
  getNextSellersPage = () => {
    this.props.getSellers(this.props.filters.data, this.props.sellers.from);
  };

  /**
   * Loads next page buyers data from API
   */
  getNextBuyersPage = () => {
    this.props.getBuyers(this.props.filters.data, this.props.buyers.from);
  };

  /**
   * Loads next page result data from API
   */
  getNextResultsPage = () => {
    this.props.getResult(
      this.props.filters.data,
      this.props.result.from,
      "buyerCountries"
    );
  };

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const { t } = this.props;
    const { activeTab } = this.state;
    //console.log("anesid query thingies: ", this.props.search);
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">
              Public Contracts {">"} Buyer Countries
            </h3>
          </Col>
        </Row>
        <Row></Row>
        <Loader loading={this.state.isLoading} page={true} />
        {!this.state.isLoading && (
          <>
            {this.state.error ? (
              <Error error={this.state.error} page={true} />
            ) : (
              <Row className="dashboard_container">
                <Col className="filters" md={3}>
                  <Collapse
                    title="Filters"
                    subtitle="Click data below to update results"
                    className="with-shadow filters_collapse"
                  >
                    <div></div>
                  </Collapse>
                  <div className="reset_all d-flex justify-content-between">
                    <Button
                      disabled={!this.state.enableReset}
                      color="success"
                      outline
                      size="sm"
                      onClick={() => this.resetFilters()}
                    >
                      Reset all
                    </Button>
                    <ModalDataset data={this.props.filters.data} />
                  </div>
                  <div className="filter">
                    <FacetList
                      data={this.props.buyerCountries}
                      onFilterUpdate={(key, value) =>
                        this.updateFilter(key, value)
                      }
                      selectedValue={this.props.filters.data.buyerCountry}
                    ></FacetList>
                    {/* <FacetList
                      data={this.props.sellerCountries}
                      onFilterUpdate={(key, value) =>
                        this.updateFilter(key, value)
                      }
                      selectedValue={this.props.filters.data.sellerCountry}
                    ></FacetList>
                    <FacetList
                      data={this.props.cpvs}
                      onFilterUpdate={(key, value) =>
                        this.updateFilter(key, value)
                      }
                      selectedValue={this.props.filters.data.cpv}
                    ></FacetList> */}
                    <Range
                      data={this.props.time}
                      step={1}
                      tipFormatter={(value) => `${value}`}
                      onFilterUpdate={(key, value) =>
                        this.updateFilter(key, value)
                      }
                      selectedValue={this.props.filters.data.year}
                      chart={true}
                    />
                    {/* <Range
                      data={this.props.numberOfTrends}
                      step={10000}
                      tipFormatter={value => {
                        if (value === 1000000) {
                          return `Max`;
                        } else {
                          return `${value}`;
                        }
                      }}
                      onFilterUpdate={(key, value) =>
                        this.updateFilter(key, value)
                      }
                      selectedValue={this.props.filters.data.numberOfTenders}
                      chart={false}
                    />
                    <SelectRange
                      data={this.props.amount}
                      selectedValue={this.props.filters.data.amount}
                      onFilterUpdate={(key, value) =>
                        this.updateFilter(key, value)
                      }
                    /> */}
                  </div>
                </Col>
                <Col md={9}>
                  <Card>
                    <CardBody>
                      <div className="tabs tabs--bordered-bottom">
                        <div className="tabs__wrap">
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "1",
                                })}
                                onClick={() => {
                                  this.toggle("1");
                                }}
                              >
                                Contracts
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "2",
                                })}
                                onClick={() => {
                                  this.toggle("2");
                                }}
                              >
                                Charts
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "3",
                                })}
                                onClick={() => {
                                  this.toggle("3");
                                }}
                              >
                                Map
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "4",
                                })}
                                onClick={() => {
                                  this.toggle("4");
                                }}
                              >
                                Buyers
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "5",
                                })}
                                onClick={() => {
                                  this.toggle("5");
                                }}
                              >
                                Query Info
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent activeTab={activeTab}>
                            <TabPane tabId="2">
                              <StyleRoot>
                                <div
                                  className="tabs tabs_results"
                                  style={styles.fadeIn}
                                >
                                  <div className="tabs__wrap">
                                    <div className="result">
                                      <div className="card__title">
                                        <h5 className="bold-text">
                                          Contracts per
                                          <FormControl className="title-select">
                                            <Select
                                              value={
                                                this.props.filters.data
                                                  .resultsType
                                              }
                                              onChange={(event) =>
                                                this.updateFilter(
                                                  "resultsType",
                                                  event.target.value
                                                )
                                              }
                                              displayEmpty
                                              MenuProps={{
                                                disableScrollLock: true,
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                  vertical: "bottom",
                                                  horizontal: "left",
                                                },
                                                transformOrigin: {
                                                  vertical: "top",
                                                  horizontal: "left",
                                                },
                                              }}
                                            >
                                              {buyerCountriesrResultTypes.map(
                                                (item) => {
                                                  return (
                                                    <MenuItem
                                                      key={item.id}
                                                      value={item.id}
                                                    >
                                                      {item.name}
                                                    </MenuItem>
                                                  );
                                                }
                                              )}
                                            </Select>
                                          </FormControl>
                                        </h5>
                                      </div>
                                      <ChartProps
                                        onFilterUpdate={(key, value) =>
                                          this.updateFilter(key, value)
                                        }
                                        filters={this.props.filters}
                                      ></ChartProps>
                                      {this.props.filters.data.chartType.indexOf(
                                        "grid"
                                      ) >= 0 ? (
                                        <MatTable
                                          data={this.props.result}
                                          rows={10}
                                          onFilterUpdate={() => {}}
                                          onNextSearchPage={() => {
                                            this.getNextResultsPage();
                                          }}
                                          export={true}
                                          checkBox={false}
                                          resultsType={
                                            this.props.filters.data.resultsType
                                          }
                                        ></MatTable>
                                      ) : (
                                        <Chart
                                          onFilterUpdate={(key, value) =>
                                            this.updateFilter(key, value)
                                          }
                                          filters={this.props.filters}
                                          data={this.props.result}
                                        ></Chart>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </StyleRoot>
                            </TabPane>
                            <TabPane tabId="3">
                              <StyleRoot>
                                <div className="result" style={styles.fadeIn}>
                                  <MapChart
                                    data={this.props.buyerCountries}
                                    onFilterUpdate={() => {}}
                                    selectedValue={
                                      this.props.filters.data.buyerCountry
                                    }
                                  ></MapChart>
                                </div>
                              </StyleRoot>
                            </TabPane>
                            <TabPane tabId="4">
                              <StyleRoot>
                                <div className="result" style={styles.fadeIn}>
                                  <MatTable
                                    identifier="buyerCountries"
                                    data={this.props.buyers}
                                    rows={10}
                                    onFilterUpdate={() => {}}
                                    onNextSearchPage={() => {
                                      this.getNextBuyersPage();
                                    }}
                                    selectedValue={this.props.buyer}
                                    checkBox={false}
                                  ></MatTable>
                                </div>
                              </StyleRoot>
                            </TabPane>
                            <TabPane tabId="1">
                              <StyleRoot>
                                <div className="result" style={styles.fadeIn}>
                                  {/* <MatTable
                                    identifier="buyerCountriesResult"
                                    data={this.props.search}
                                    rows={10}
                                    onFilterUpdate={() => {}}
                                    onNextSearchPage={() => {
                                      this.getNextSearchPage();
                                    }}
                                    checkBox={false}
                                    export={true}
                                  ></MatTable> */}
                                  <GridTable
                                    identifier="buyerCountriesResult"
                                    data={this.props.search}
                                    updateSearchGrid={this.updateSearchGrid}
                                    export={true}
                                  />
                                </div>
                              </StyleRoot>
                            </TabPane>
                            <TabPane tabId="5">
                              <StyleRoot>
                                <div className="result" style={styles.fadeIn}>
                                  <QueryDetails
                                    queryInfo={this.props.search.queryData}
                                  />
                                </div>
                              </StyleRoot>
                            </TabPane>
                          </TabContent>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filters: state.publicContracts.filters,
    result: state.publicContracts.result,
    buyerCountries: state.publicContracts.buyerCountries,
    sellerCountries: state.publicContracts.sellerCountries,
    buyers: state.publicContracts.buyers,
    sellers: state.publicContracts.sellers,
    time: state.publicContracts.time,
    cpvs: state.publicContracts.cpvs,
    cpvsTable: state.publicContracts.cpvsTable,
    amount: state.publicContracts.amount,
    number: state.publicContracts.number,
    search: state.publicContracts.search,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getResult: (filters, from, identifier) =>
      dispatch(getResult(filters, from, identifier)),
    getFacets: (payload, identifier) =>
      dispatch(getFacets(payload, identifier)),
    getBuyers: (filters, from) => dispatch(getBuyers(filters, from)),
    getSellers: (filters, from) => dispatch(getSellers(filters, from)),
    getCPVs: (filters, from) => dispatch(getCPVs(filters, from)),
    setFilter: (payload) => dispatch(setFilter(payload)),
    getSearch: (filters, from, identifier) =>
      dispatch(getSearch(filters, from, identifier)),
    resetFilters: () => dispatch(reset_filters()),
    getFilters: (payload) => dispatch(getFilters(payload)),
  };
};

export default withTranslation("common")(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(BuyerCountries))
);
