import React, { useState } from 'react';
import { checkPerName } from '../../services/validationFields/validationFields';
import ErrorInput from './ErrorInput';
import TextField from '@material-ui/core/TextField';

const InputField = (props) => {
  /** InputField state */
  const [errorMessage, setError] = useState();
  /** Setup props for input */
  const inputProps = {
    name: props.name,
    type: props.type,
    label: props.label,
    placeholder: props.placeholder,
    required: props.required ? props.required : false,
  };

  /**
   * Validates input, displays error message and sends check to parent component
   * @param {*} value
   */
  const checkValue = (value) => {
    var errorMessage = checkPerName(props.name, value);
    if (errorMessage) {
      setError(errorMessage);
    }
    props.valueCheck(value, props.name);
  };

  /**
   * Removes error display upon change value
   */
  const changeValue = (value) => {
    console.log(value);
    var errorMessage = checkPerName(props.name, value);
    if (errorMessage) {
      setError(errorMessage);
    }
    props.valueCheck(value, props.name);

    if (errorMessage) {
      setError();
    }
  };

  /**
   * Theme uses different types of input fields depending on route
   * so are we by texture props
   * @param {String} texture
   */
  const inputType = (texture) => {
    if (texture === 'material') {
      return (
        <TextField
          className="material-form__field"
          label={props.label}
          placeholder={props.placeholder}
          error={errorMessage}
          onChange={() => changeValue()}
          onBlur={(e) => checkValue(e.target.value)}
          variant="outlined"
          {...inputProps}
        />
      );
    } else {
      return (
        <>
          <label>{props.label}</label>
          <input
            {...inputProps}
            onBlur={(e) => checkValue(e.target.value)}
            onChange={(e) => changeValue(e.target.value)}
          />
          <ErrorInput errorMessage={errorMessage} />
        </>
      );
    }
  };

  return <>{inputType(props.texture)}</>;
};

export default InputField;
