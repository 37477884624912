import {
  FETCH_STORIES_DATA,
  FETCH_STORIES_DATA_SUCCESSFUL,
  FETCH_STORY_DATA_SUCCESSFUL,
  FETCH_STORIES_DATA_ERROR
} from '../actions/storiesActions';

const initialState = {
  fetching: false,
  error: '',
  list: [],
  listCount: null,
  story: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_STORIES_DATA:
      return {
        ...state,
        fetching: true,
        error: '',
        list: [],
        story: {}
      };
    case FETCH_STORIES_DATA_SUCCESSFUL:
      return {
        ...state,
        fetching: false,
        error: '',
        list: action.payload.stories,
        listCount: action.payload.totalCount
      };
    case FETCH_STORY_DATA_SUCCESSFUL:
      return {
        ...state,
        fetching: false,
        error: '',
        story: action.payload
      };
    case FETCH_STORIES_DATA_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
        list: [],
        listCount: null,
        story: {}
      };
    default:
      return state;
  }
}
