import React from 'react';
import { CardBody, Card } from 'reactstrap';
import Panel from '../../components/Panel/Panel';

// ICONS
import DragIcon from 'mdi-react/DragIcon';

// CSS
import './DraggableDatasets.scss';

const DraggableDatasets = ({ datasets }) => {
  const draggedData = (e, id) => {
    e.dataTransfer.setData('Text', '_DATASET' + id + 'IFRAME_');
  };

  const mapDatasets = datasets => {
    const draggableDatasets = datasets.map((data, index) => {
      return (
        <div
          key={'drag-visual-' + index}
          className="drag_panel d-flex align-items-center"
          draggable={true}
          onDragStart={e => {
            draggedData(e, data.id);
          }}
        >
          <DragIcon />
          <Panel
            key={'visual-' + index}
            divider={false}
            title={data.title}
            type="visualization"
            id={data._id}
            dataPanel={true}
            collapse={true}
            label={data.access_level}
          >
            <div className="d-flex tags_container">
              <div className="label">Tags: </div>
              {data.tags.map((tag, index) => {
                return (
                  <div key={`visual-tag-${index}`} className="tag">
                    {tag}
                  </div>
                );
              })}
            </div>
          </Panel>
        </div>
      );
    });
    return <>{draggableDatasets}</>;
  };

  return (
    <Card className="drag_visualizations">
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">My visualizations</h5>
        </div>
        <div className="card__subtitle">
          Drag any visualization inside text editor to add it in your story
        </div>
        {mapDatasets(datasets)}
      </CardBody>
    </Card>
  );
};

export default DraggableDatasets;
