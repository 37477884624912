import { apiRequest } from "../../../services/api/index";

import { fetchData, setData, setError } from "./publicContractsActions";

// CUSTOM DATA
import { searchHead } from "../../../services/helpers/searchHead";

export function getSearch(filters, from, identifier) {
  switch (identifier) {
    case "buyerCountries":
      filters.resultsType = "ISO_COUNTRY_CODE";
      break;
    case "sellerCountries":
      filters.resultsType = "WIN_COUNTRY_CODE";
      break;
    case "CPVs":
      filters.resultsType = "CPV";
      break;
    default:
  }
  //console.log("anesid search: ", filters, identifier);
  //Case: Buyer Countriess
  return async (dispatch) => {
    dispatch(fetchData("search"));
    try {
      const params = {
        type: "search",
        data: {
          terms: [],
          ranges: [],
          size: 10,
          from: from,
        },
      };
      // Add selected facet's value
      if (filters.buyerCountry) {
        params.data.terms.push({
          ISO_COUNTRY_CODE: filters.buyerCountry,
        });
      }
      if (filters.sellerCountry) {
        params.data.terms.push({
          WIN_COUNTRY_CODE: filters.sellerCountry,
        });
      }
      if (filters.cpv) {
        params.data.terms.push({
          CPV: filters.cpv,
        });
      }
      // Add selected facet's range
      if (filters.year) {
        params.data.ranges.push({
          YEAR: {
            start: filters.year[0],
            end: filters.year[1],
          },
        });
      }
      const response = await apiRequest(params);
      if (response.status === 200) {
        //console.log("anesid search response: ", response);
        //console.log("anesid link: ", response.data.queryData.body);
        //console.log("anesid method: ", response.data.queryData.method);
        // console.log(
        //   "anesid path: ",
        //   decodeURIComponent(response.data.queryData.path)
        // );

        const payload = {
          data: response.data.data,
          view: searchHead,
          total: response.data.total,
          from: from + 10,
          queryData: response.data.queryData,
        };

        // ***** anesid: make a rework on date not to be invalid
        let pl = payload.data.map((contract) => {
          let date = contract.DT_AWARD.split("-");
          date = date[2] + "-" + date[1] + "-" + date[0];
          contract.DT_AWARD = date;

          date = contract.DT_DISPATCH.split("-");
          date = date[2] + "-" + date[1] + "-" + date[0];
          contract.DT_DISPATCH = date;

          return contract;
        });

        payload.data = pl;

        dispatch(setData("search", payload));
      } else {
        dispatch(setError("search", "Something went wrong. Please try later!"));
      }
    } catch (error) {
      dispatch(setError("search", "Something went wrong. Please try later!"));
    }
  };
}

export function userSearch(
  filters,
  from,
  identifier,
  rows,
  indece,
  sortBy = null
) {
  // console.log("anesid search: ", filters, identifier);
  // let rows = filters.rows
  //console.log("ROWS", from, rows);
  //console.log("INDECE", indece);
  // rows = 20;
  switch (identifier) {
    case "buyerCountries":
      filters.resultsType = "WIN_COUNTRY_CODE";
      break;
    case "sellerCountries":
      filters.resultsType = "ISO_COUNTRY_CODE";
      break;
    case "CPVs":
      filters.resultsType = "CPV";
      break;
    case "CAE_NAME":
      filters.resultsType = "ISO_COUNTRY_CODE";
      break;
    default:
  }

  //Case: Buyer Countriess
  return async (dispatch) => {
    dispatch(fetchData("userSearch"));
    try {
      const params = {
        type: "search",
        data: {
          terms: [],
          ranges: [],
          size: rows,
          from: from,
          indece: indece,
          sortBy: sortBy,
        },
      };
      // Add selected facet's value
      if (filters.cae_name) {
        params.data.terms.push({
          CAE_NAME: filters.cae_name,
        });
      }

      if (filters.win_name) {
        params.data.terms.push({
          WIN_NAME: filters.win_name,
        });
      }

      if (filters.title) {
        params.data.terms.push({
          TITLE: filters.title,
        });
      }
      if (indece !== "CONTRACT_NOTICES") {
        if (filters.sellerCountry) {
          params.data.terms.push({
            WIN_COUNTRY_CODE: filters.sellerCountry,
          });
        }
      }
      if (filters.buyerCountry) {
        params.data.terms.push({
          ISO_COUNTRY_CODE: filters.buyerCountry,
        });
      }

      if (filters.cpv) {
        params.data.terms.push({
          CPV: filters.cpv,
        });
      }
      // Add selected facet's range
      // if (filters.year) {
      //   params.data.ranges.push({
      //     YEAR: {
      //       start: filters.year[0],
      //       end: filters.year[1]
      //     },
      //   });
      // }
      if (filters.year) {
        params.data.terms.push({
          YEAR: filters.year,
        });
      }

      // if (searchBy){

      // }
      // if (filters.number) {
      //   params.data.params.number = filters.number;
      // }
      // if (filters.amount) {
      //   params.data.params.amount = filters.amount;
      // }
      //console.log("anesid search: ", params);

      const response = await apiRequest(params);
      if (response.status === 200) {
        const payload = {
          data: response.data.data,
          view: searchHead,
          total: response.data.total,
          from: from + rows,
        };
        //console.log("GAVGAVGAV", payload);
        dispatch(setData("userSearch", payload));
      } else {
        dispatch(
          setError("userSearch", "Something went wrong. Please try later!")
        );
      }
    } catch (error) {
      dispatch(
        setError("userSearch", "Something went wrong. Please try later!")
      );
    }
  };
}

export function getContractsBetween(filters, rows, indece) {
  //console.log("anesid search: ", filters);
  // let rows = filters.rows
  //console.log("ROWS", rows);
  //console.log("INDECE", indece);
  // rows = 20;
  // switch (identifier) {
  //   case "buyerCountries":
  //     filters.resultsType = "WIN_COUNTRY_CODE";
  //     break;
  //   case "sellerCountries":
  //     filters.resultsType = "ISO_COUNTRY_CODE";
  //     break;
  //   case "CPVs":
  //     filters.resultsType = "CPV";
  //     break;
  //   case "CAE_NAME":
  //     filters.resultsType = "ISO_COUNTRY_CODE";
  //     break;
  //   default:
  // }

  //Case: Buyer Countriess
  return async (dispatch) => {
    dispatch(fetchData("constractsBetween"));
    try {
      const params = {
        type: "search",
        data: {
          terms: [],
          ranges: [],
          size: 100,
          from: 0,
          indece: indece,
        },
      };
      // Add selected facet's value
      if (filters.cae_name) {
        params.data.terms.push({
          CAE_NAME: filters.cae_name,
        });
      }

      if (filters.win_name) {
        params.data.terms.push({
          WIN_NAME: filters.win_name,
        });
      }

      if (filters.iso_country_code) {
        params.data.terms.push({
          ISO_COUNTRY_CODE: filters.iso_country_code,
        });
      }

      if (filters.win_country_code) {
        params.data.terms.push({
          WIN_COUNTRY_CODE: filters.win_country_code,
        });
      }

      // Add selected facet's range
      // if (filters.year) {
      //   params.data.ranges.push({
      //     YEAR: {
      //       start: filters.year[0],
      //       end: filters.year[1]
      //     },
      //   });
      // }

      // if (filters.number) {
      //   params.data.params.number = filters.number;
      // }
      // if (filters.amount) {
      //   params.data.params.amount = filters.amount;
      // }
      // console.log("anesid search: ", params);

      const response = await apiRequest(params);
      if (response.status === 200) {
        const payload = {
          data: response.data.data,
          view: searchHead,
          total: response.data.total,
          from: 0,
        };
        //console.log("Get Contracts Between", payload)
        dispatch(setData("constractsBetween", payload));
      } else {
        dispatch(
          setError(
            "constractsBetween",
            "Something went wrong. Please try later!"
          )
        );
      }
    } catch (error) {
      dispatch(
        setError("constractsBetween", "Something went wrong. Please try later!")
      );
    }
  };
}

export function getRelatedNotice(filters, indece) {
  return async (dispatch) => {
    dispatch(fetchData("relatedNotice"));
    try {
      const params = {
        type: "search",
        data: {
          terms: [],
          ranges: [],
          size: 100,
          from: 0,
          indece: indece,
        },
      };

      // Add selected facet's value
      if (filters.id_notice_can && indece === "CONTRACT_NOTICES") {
        params.data.terms.push({
          FUTURE_CAN_ID: filters.id_notice_can,
        });
      }
      //console.log("filters.future_can_id", filters.future_can_id, indece)

      if (filters.future_can_id && indece === "CONTRACT_AWARD_NOTICES") {
        //console.log("filters.future_can_id", filters.future_can_id)
        params.data.terms.push({
          ID_NOTICE_CAN: filters.future_can_id,
        });
      }
      //console.log("anesid search: ", params);

      const response = await apiRequest(params);
      if (response.status === 200) {
        const payload = {
          data: response.data.data,
          view: searchHead,
          total: response.data.total,
          from: 0,
        };
        //console.log("Get Contracts Between", payload)
        dispatch(setData("relatedNotice", payload));
      } else {
        dispatch(
          setError("relatedNotice", "Something went wrong. Please try later!")
        );
      }
    } catch (error) {
      dispatch(
        setError("relatedNotice", "Something went wrong. Please try later!")
      );
    }
  };
}

export function getConnections(payload) {
  return async (dispatch) => {
    dispatch(fetchData("connections"));
    try {
      const params = {
        type: "aggregatePerField",
        data: {
          // field: "WIN_NAME",
          field: "CAE_NAME",

          terms: [],
          from: 0,
          size: 10000,
          ranges: [],
        },
      };

      // if (payload.cae_name) {
      //   console.log("additional  cpv inside", payload.cae_name);
      //   // if (Array.isArray(payload.cae_name)) {
      //   //   params.data.terms.push({
      //   //     CAE_NAME: payload.cae_name[0]
      //   //   });
      //   // } else {
      //   params.data.terms.push({
      //     CAE_NAME: payload.cae_name,
      //   });
      //   // }
      // }
      if (payload.win_name) {
        // console.log("additional  cpv inside", payload.win_name);
        // if (Array.isArray(payload.cae_name)) {
        //   params.data.terms.push({
        //     CAE_NAME: payload.cae_name[0]
        //   });
        // } else {
        params.data.terms.push({
          WIN_NAME: payload.win_name[0],
        });
        // }
      }
      //console.log('params', params)
      const response = await apiRequest(params);
      if (response.status === 200) {
        //console.log("RES", response)
        dispatch(setData("connections", response.data));
      }
    } catch (e) {
      console.log(e);
    }
  };
}
