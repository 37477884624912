import React, { PureComponent } from "react";
import "./searchContracts.css";

import CPVs from "./CPVs";
import {
  Col,
  FormGroup,
  Container,
  ButtonGroup,
  InputGroupButtonDropdown,
  Row,
  Button,
  InputGroup,
  Input,
  InputGroupAddon,
  Form,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Select from "react-select";

// import SearchIcon from "../../shared/img/icons/search.svg"
import { FaSearch } from "react-icons/fa";

import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  userSearch,
  getConnections,
  getRelatedNotice,
  getContractsBetween,
} from "../../redux/actions/publicContracts/searchActions";
import MatTable from "../../components/MatTable/MatTable";

import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/bootstrap/tabulator_bootstrap.css"; // theme
import { ReactTabulator } from "react-tabulator"; // for React 15.x, use import { React15Tabulator }
import Modal from "react-modal";
import Contract from "./modal/contract";
import {
  tabulatorAwardedCols,
  tabulatorNoticeCols,
  TabulatorNoticeAndAwarded,
} from "../../shared/helpers/CollumnsDataset";
Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const { countries } = require("i18n-iso-countries/langs/en.json");
const years = [
  2006,
  2007,
  2008,
  2009,
  2010,
  2011,
  2012,
  2013,
  2014,
  2015,
  2016,
  2017,
  2018,
  2019
];

const FIELDS = {
  CAE_NAME: "Buyer",
  WIN_NAME: "Seller",
  TITLE: "Title",
};

class searchContract extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapseSearchBy: false,
      collapseRowCountShow: false,
      searchBy: "CAE_NAME",
      additionalFilters: {},
      searchValue: "",
      search: false,
      page: 1,
      filters: {},
      indece: "Both",
      rowNum: 10, //Default is 10 rows
      contractModal: false,
      rowData: {},
    };
    this.handleSearchBy = this.handleSearchBy.bind(this);
    this.fetchRows = this.fetchRows.bind(this);
  }

  openViewContractModal = () => {
    this.setState({
      contractModal: true,
    });
  };

  closeViewContractModal = () => {
    this.setState({
      contractModal: false,
      rowData: {},
    });
  };

  selectIndice = (v) => {
    // console.log("SELECTED", v, v.target.value);
    this.setState({ indece: v.target.value });
  };

  handleSearchBy = (v) => {
    // console.log("HANDLER")
    // console.log(v.currentTarget.getAttribute("value"))
    if (FIELDS[v.currentTarget.getAttribute("value")]) {
      this.setState({ searchBy: v.currentTarget.getAttribute("value") });
    }
  };

  rowDropDownToggle = () => {
    // console.log("TOGLE", this.state.collapseRowCountShow);
    const { collapseRowCountShow } = this.state;
    this.setState({ collapseRowCountShow: !collapseRowCountShow });
  };

  fetchRows = (v) => {
    // console.log("CLICKED FETCH ROWS", v);
    // let rows = parseInt(v.currentTarget.getAttribute("value"))
    let rows = parseInt(v.target.value);
    this.setState({ rowNum: rows, page: 1 });
    this.props.userSearch(
      this.state.filters,
      0,
      this.state.searchBy,
      rows,
      this.state.indece
    );
  };

  toggle = () => {
    const { collapseSearchBy } = this.state;
    this.setState({ collapseSearchBy: !collapseSearchBy });
  };

  searchContracts = () => {
    // console.log("SEARCH", this.state.searchBy, this.state.searchValue)
    // console.log("AM I IN?")
    let filters = {
      [this.state.searchBy.toLowerCase()]: this.state.searchValue,
      ...this.state.additionalFilters,
    };
    // console.log("FILTERS TO SEARCH", filters);
    this.props.userSearch(
      filters,
      0,
      this.state.searchBy,
      this.state.rowNum,
      this.state.indece
    );
    this.setState({ search: true, page: 1, filters });
  };

  handleSearch = (event) => {
    this.setState({ searchValue: event.target.value });
  };

  getNextSearchPage = () => {
    // console.log("holla", this.props.filters.data);
    // console.log("holla2", this.props.userSearchRes);

    // let filters = { cae_name: this.state.searchValue }
    this.setState({ page: this.state.page + 1 });
    this.props.userSearch(
      this.state.filters,
      this.props.userSearchRes.from,
      this.state.searchBy,
      this.state.rowNum,
      this.state.indece
    );
  };
  getPrevSearchPage = () => {
    // let filters = { cae_name: this.state.searchValue }
    this.setState({ page: this.state.page - 1 });
    this.props.userSearch(
      this.state.filters,
      this.props.userSearchRes.from - 2 * this.state.rowNum,
      this.state.searchBy,
      this.state.rowNum
    );
  };

  rowClick = (e, row) => {
    // console.log("Data Row", row)
    // this.props.getConnections({ cae_name: row._row.data.CAE_NAME })
    this.props.getConnections({ win_name: row._row.data.WIN_NAME });
    // console.log("CLIIIIICK", row._row.data.WIN_NAME)

    if (row._row.data.WIN_NAME) {
      this.props.getContractsBetween(
        {
          cae_name: row._row.data.CAE_NAME,
          win_name: row._row.data.WIN_NAME,
          // iso_country_code: row._row.data.ISO_COUNTRY_CODE,
          // win_country_code: row._row.data.WIN_COUNTRY_CODE
        },
        100,
        "CONTRACT_AWARD_NOTICES"
      );

      this.props.getRelatedNotice(
        { id_notice_can: row._row.data.ID_NOTICE_CAN },
        "CONTRACT_NOTICES"
      );
    } else {
      console.log("");
      this.props.getRelatedNotice(
        { future_can_id: row._row.data.FUTURE_CAN_ID },
        "CONTRACT_AWARD_NOTICES"
      );
      this.props.getContractsBetween(
        {
          cae_name: row._row.data.CAE_NAME,
          // win_name: row._row.data.WIN_NAME
          // iso_country_code: row._row.data.ISO_COUNTRY_CODE,
          // win_country_code: row._row.data.WIN_COUNTRY_CODE
        },
        100,
        "CONTRACT_NOTICES"
      );
    }
    // console.log("get CELL", row.getCell())
    if (e.target.textContent.includes("ted.europa.eu")) {
      return;
    }
    this.setState({ rowData: Object.assign({}, row._row.data) });
    this.openViewContractModal();
  };

  // renderContractModal = (data) => {

  // }

  renderPageCount = () => {
    if (this.props.userSearchRes.total > 0) {
      return (
        <span>
          Page: {this.state.page} of{" "}
          {Math.ceil(this.props.userSearchRes.total / this.state.rowNum)}
        </span>
      );
    }
  };

  renderSearchBy = () => {
    return (
      // <Dropdown isOpen={this.state.collapseSearchBy} toggle={this.toggle}>
      <span>
        <DropdownToggle caret>{FIELDS[this.state.searchBy]}</DropdownToggle>
        <DropdownMenu>
          {/* <DropdownItem header>Header</DropdownItem>
        <DropdownItem>Some Action</DropdownItem>
        <DropdownItem disabled>Action (disabled)</DropdownItem>
        <DropdownItem divider /> */}
          <DropdownItem value="CAE_NAME" onClick={this.handleSearchBy}>
            Buyer
          </DropdownItem>
          <DropdownItem value="WIN_NAME" onClick={this.handleSearchBy}>
            Seller
          </DropdownItem>
          <DropdownItem value="TITLE" onClick={this.handleSearchBy}>
            Title
          </DropdownItem>
        </DropdownMenu>
      </span>
      // </Dropdown>
    );
  };

  renderYearCountryFilter = () => {
    let OptionsYears = years.map((year) => {
      let YearOption = {
        value: year,
        label: year,
      };
      return YearOption;
    });
    return (
      <Col md={4}>
        <Label>Year:</Label>
        <Select
          onChange={this.YearCountryFilter}
          closeMenuOnSelect={false}
          options={OptionsYears}
          isMulti
        />
      </Col>
    );
  };

  YearCountryFilter = (values) => {
    if (values) {
      let arrValues = values.map((val) => {
        return val.value;
      });
      // console.log("YEAR VALUES", arrValues);
      this.setState((prev) => {
        let additionalFilters = Object.assign(this.state.additionalFilters);
        additionalFilters["year"] = arrValues;
        if (arrValues.length == 0) {
          delete additionalFilters.year;
        }
        return additionalFilters;
      });
    }

    if (!values && this.state.additionalFilters.year) {
      // console.log("Additional Filters Buyer zero values and win");
      this.setState((prev) => {
        let additionalFilters = Object.assign(this.state.additionalFilters);
        delete additionalFilters.year;
        return additionalFilters;
      });
    }

    // console.log("Additional Filters year", this.state.additionalFilters);
  };

  buyerCountriesFilter = (values) => {
    // console.log("Additional Filters Buyer IN");
    if (values) {
      let arrValues = values.map((val) => {
        return val.value;
      });
      this.setState((prev) => {
        let additionalFilters = Object.assign(this.state.additionalFilters);
        additionalFilters["buyerCountry"] = arrValues;
        if (arrValues.length == 0) {
          delete additionalFilters.buyerCountry;
        }
        return additionalFilters;
      });
    }
    // console.log(
    //   "Additional Filters Buyer values",
    //   values,
    //   this.state.additionalFilters.buyerCountry
    // );

    if (!values && this.state.additionalFilters.buyerCountry) {
      // console.log("Additional Filters Buyer zero values and win");
      this.setState((prev) => {
        let additionalFilters = Object.assign(this.state.additionalFilters);
        delete additionalFilters.buyerCountry;
        return additionalFilters;
      });
    }
    // console.log("Additional Filters Buyer", this.state.additionalFilters);
  };

  renderBuyerCountryFilter = () => {
    let OptionsCountries = Object.keys(countries).map((country) => {
      let CountryOption = {
        value: country,
        label: countries[country],
      };
      return CountryOption;
    });
    return (
      <Col md={4}>
        <Label>Buyer Country:</Label>
        <Select
          onChange={this.buyerCountriesFilter}
          closeMenuOnSelect={false}
          options={OptionsCountries}
          isMulti
          // isDisabled={this.state.indece === "CONTRACT_NOTICES" ? true : false}
        />
      </Col>
    );
  };

  sellerCountriesFilter = (values) => {
    if (values) {
      let arrValues = values.map((val) => {
        return val.value;
      });
      this.setState((prev) => {
        let additionalFilters = Object.assign(this.state.additionalFilters);
        additionalFilters["sellerCountry"] = arrValues;
        if (arrValues.length == 0) {
          delete additionalFilters.sellerCountry;
        }
        return additionalFilters;
      });
    }

    if (!values && this.state.additionalFilters.sellerCountry) {
      // console.log("Additional Filters Buyer zero values and win");
      this.setState((prev) => {
        let additionalFilters = Object.assign(this.state.additionalFilters);
        delete additionalFilters.sellerCountry;
        return additionalFilters;
      });
    }
    // console.log("Additional Filters Seller", this.state.additionalFilters);
  };

  // This function is for reactstrap dropdown that is innactive right now
  // renderRowShow = () => {
  //   return (
  //     <Dropdown size="sm" isOpen={this.state.collapseRowCountShow} toggle={this.rowDropDownToggle}>
  //       <DropdownToggle caret>
  //         {this.state.rowNum}
  //       </DropdownToggle>
  //       <DropdownMenu>
  //         <DropdownItem onClick={this.fetchRows} value="10">10</DropdownItem>
  //         <DropdownItem onClick={this.fetchRows} value="20">20</DropdownItem>
  //         <DropdownItem onClick={this.fetchRows} value="50">50</DropdownItem>
  //         <DropdownItem onClick={this.fetchRows} value="100">100</DropdownItem>

  //       </DropdownMenu>
  //     </Dropdown>
  //   );

  // }

  renderSellerCountryFilter = () => {
    let OptionsCountries = Object.keys(countries).map((country) => {
      let CountryOption = {
        value: country,
        label: countries[country],
      };
      return CountryOption;
    });
    return (
      <Col md={4}>
        <Label>Seller Country:</Label>
        <Select
          onChange={this.sellerCountriesFilter}
          closeMenuOnSelect={false}
          options={OptionsCountries}
          isMulti
          isDisabled={this.state.indece === "CONTRACT_NOTICES" ? true : false}
        />
      </Col>
    );
  };

  renderArray = () => {
    let columns = [];
    if (this.state.indece == "CONTRACT_AWARD_NOTICES") {
      columns = tabulatorAwardedCols;
      // columns = [
      //   { title: 'Name', field: 'TED_NOTICE_URL', formatter: "link", width: 150, formatterParams: { urlPrefix: "https://", target: "_blank" } },
      //   { title: 'Seller', field: 'CAE_NAME', hozAlign: 'left' },
      //   { title: 'Seller Country', field: 'ISO_COUNTRY_CODE'},
      //   { title: 'Buyer', field: 'WIN_NAME' },
      //   { title: 'Buyer Country', field: 'WIN_COUNTRY_CODE' },
      //   { title: 'Award Date', field: 'DT_AWARD' },
      //   { title: 'Dispatch Date', field: 'DT_DISPATCH' },
      //   { title: 'Amount', field: 'AWARD_VALUE_EURO', formatter: function (cell) { return cell.getValue() == -1 ? "N/A" : cell.getValue() + ' €' } },
      //   {
      //     title: 'Aprox Amount', field: 'AWARD_VALUE_EURO', formatter: function (cell) {
      //       let value = cell.getValue()
      //       if (Math.abs(Number(value)) > 1e15) {
      //         value = Math.floor((Math.abs(Number(value)) / 1e15) * 100) / 100 + "Q"
      //       } else if (Math.abs(Number(value)) > 1e12) {
      //         value = Math.floor((Math.abs(Number(value)) / 1e12) * 100) / 100 + "T"
      //       } else if (Math.abs(Number(value)) > 1e9) {
      //         value = Math.floor((Math.abs(Number(value)) / 1e9) * 100) / 100 + "B"
      //       } else if (Math.abs(Number(value)) > 1e6) {
      //         value = Math.floor((Math.abs(Number(value)) / 1e6) * 100) / 100 + "M"
      //       } else if (Math.abs(Number(value)) > 1e3) {
      //         value = Math.floor((Math.abs(Number(value)) / 1e3) * 100) / 100 + "K"
      //       }
      //       return value
      //     }
      //   },
      //   { title: 'Year', field: "YEAR" },
      //   { title: 'Main Activity', field: "MAIN_ACTIVITY" }]
    } else if (this.state.indece == "CONTRACT_NOTICES") {
      columns = tabulatorNoticeCols;
      // columns = [
      //   { title: 'Name', field: 'TED_NOTICE_URL', formatter: "link", width: 150, formatterParams: { urlPrefix: "https://", target: "_blank" } },
      //   { title: 'Seller', field: 'CAE_NAME', hozAlign: 'left' },
      //   { title: 'Seller Country', field: 'ISO_COUNTRY_CODE' },
      //   { title: 'Start Date', field: 'CONTRACT_START' },
      //   { title: 'End Date', field: 'CONTRACT_COMPLETION' },
      //   { title: 'Amount', field: 'VALUE_EURO_FIN_1', formatter: function (cell) { return cell.getValue() == -1 ? "N/A" : cell.getValue() + ' €' } },
      //   {
      //     title: 'Aprox Amount', field: 'VALUE_EURO_FIN_1', formatter: function (cell) {
      //       let value = cell.getValue()
      //       if (Math.abs(Number(value)) > 1e15) {
      //         value = Math.floor((Math.abs(Number(value)) / 1e15) * 100) / 100 + "Q"
      //       } else if (Math.abs(Number(value)) > 1e12) {
      //         value = Math.floor((Math.abs(Number(value)) / 1e12) * 100) / 100 + "T"
      //       } else if (Math.abs(Number(value)) > 1e9) {
      //         value = Math.floor((Math.abs(Number(value)) / 1e9) * 100) / 100 + "B"
      //       } else if (Math.abs(Number(value)) > 1e6) {
      //         value = Math.floor((Math.abs(Number(value)) / 1e6) * 100) / 100 + "M"
      //       } else if (Math.abs(Number(value)) > 1e3) {
      //         value = Math.floor((Math.abs(Number(value)) / 1e3) * 100) / 100 + "K"
      //       }
      //       return value + ' €'
      //     }
      //   },
      //   { title: 'Year', field: "YEAR" },
      //   { title: 'Main Activity', field: "MAIN_ACTIVITY" }]
    } else {
      columns = TabulatorNoticeAndAwarded;
      // columns = [
      //   { title: 'Name', field: 'TED_NOTICE_URL', formatter: "link", width: 150, formatterParams: { urlPrefix: "https://", target: "_blank" } },
      //   { title: 'Seller', field: 'CAE_NAME', hozAlign: 'left' },
      //   { title: 'Seller Country', field: 'ISO_COUNTRY_CODE' },
      //   { title: 'Buyer', field: 'WIN_NAME' },
      //   { title: 'Buyer Country', field: 'WIN_COUNTRY_CODE' },
      //   { title: 'Start Date', field: 'DT_AWARD' },
      //   { title: 'End Date', field: 'DT_DISPATCH' },
      //   { title: 'Amount', field: 'AWARD_VALUE_EURO', formatter: function (cell) { return cell.getValue() == -1 ? "N/A" : cell.getValue() + ' €' } },
      //   {
      //     title: 'Aprox Amount', field: 'VALUE_EURO_FIN_1', formatter: function (cell) {
      //       let value = cell.getValue()
      //       if (Math.abs(Number(value)) > 1e15) {
      //         value = Math.floor((Math.abs(Number(value)) / 1e15) * 100) / 100 + "Q"
      //       } else if (Math.abs(Number(value)) > 1e12) {
      //         value = Math.floor((Math.abs(Number(value)) / 1e12) * 100) / 100 + "T"
      //       } else if (Math.abs(Number(value)) > 1e9) {
      //         value = Math.floor((Math.abs(Number(value)) / 1e9) * 100) / 100 + "B"
      //       } else if (Math.abs(Number(value)) > 1e6) {
      //         value = Math.floor((Math.abs(Number(value)) / 1e6) * 100) / 100 + "M"
      //       } else if (Math.abs(Number(value)) > 1e3) {
      //         value = Math.floor((Math.abs(Number(value)) / 1e3) * 100) / 100 + "K"
      //       }
      //       return value + ' €'
      //     }
      //   },
      //   { title: 'Year', field: "YEAR" },
      //   { title: 'Main Activity', field: "MAIN_ACTIVITY" }]
    }

    if (this.state.search) {
      // if (this.props.userSearchRes.data.length > 0) {
      return (
        // <MatTable
        //   data={this.props.userSearchRes}
        //   rows={10}
        //   onFilterUpdate={() => { }}
        //   onNextSearchPage={() => {
        //     this.getNextSellersPage();
        //   }}
        //   options={{
        //     resizable: true
        //   }}
        //   checkBox={false}
        // ></MatTable>
        <div>
          <ReactTabulator
            columns={columns}
            data={this.props.userSearchRes.data}
            rowClick={this.rowClick}
            // layout={"fitData"}
            responsiveLayout={"hide"}
            // Height="70vh"
          />
          <Row className="arrayBar">
            <Col md={4}>
              <Row>
                <Col md={4}>Total: {this.props.userSearchRes.total}</Col>
                <Col md={4}>
                  <label> Rows: </label>
                  {/* {this.renderRowShow()} */}
                  <select onChange={this.fetchRows}>
                    <option onClick={this.fetchRows} value="10">
                      10
                    </option>
                    <option onClick={this.fetchRows} value="20">
                      20
                    </option>
                    <option onClick={this.fetchRows} value="50">
                      50
                    </option>
                    <option onClick={this.fetchRows} value="100">
                      100
                    </option>
                  </select>
                </Col>
                {/* <Col md={4} className="d-flex justify-content-start"> */}
                {/* </Col> */}
              </Row>
            </Col>
            <Col md={4} className="d-flex justify-content-center">
              {this.renderPageCount()}
            </Col>
            <Col md={4} className="d-flex justify-content-end">
              <Button
                onClick={this.getPrevSearchPage}
                disabled={this.state.page < 2 ? true : false}
              >
                {" "}
                Previous{" "}
              </Button>
              <Button
                onClick={this.getNextSearchPage}
                disabled={
                  this.state.page >
                  Math.ceil(
                    this.props.userSearchRes.total / this.state.rowNum
                  ) -
                    1
                    ? true
                    : false
                }
              >
                Next
              </Button>
            </Col>
          </Row>
        </div>

        // <ReactTable
        //   data={this.props.userSearchRes.data}
        //   columns={cols2}
        //   showPagination={false}
        //   defaultPageSize={2}
        // />
      );
    }
  };

  render() {
    // console.log("ok?", this.props.getRelatedNoticeres)

    // console.log("ok2?", this.props.userSearchRes.data.length)
    return (
      <div className="container">
        <Modal
          isOpen={this.state.contractModal}
          onRequestClose={this.closeViewContractModal}
          style={customStyles}
          contentLabel="Contract"
        >
          {Object.keys(this.state.rowData).length > 0 && (
            <Contract
              data={Object.assign({}, this.state.rowData)}
              between={this.props.getContractsBetweenres}
              related={Object.assign({}, this.props.getRelatedNoticeres)}
              connections={Object.assign({}, this.props.getConn)}
            />
          )}
        </Modal>
        <Row id="search">
          <Col md={12}>
            <Row>
              <Col md={12}>
                {/* <input class="form-control" type="text" placeholder="Search" /> */}
                <InputGroup>
                  <Input
                    style={{ marginTop: "5px", marginRight: "10px" }}
                    value={this.state.searchValue}
                    onChange={this.handleSearch}
                    placeholder="Search"
                  />
                  <InputGroupButtonDropdown
                    addonType="append"
                    isOpen={this.state.collapseSearchBy}
                    toggle={this.toggle}
                  >
                    <div style={{ marginTop: "3px" }}>
                      {this.renderSearchBy()}
                    </div>
                  </InputGroupButtonDropdown>
                  <br />
                  <InputGroupAddon
                    addonType="append"
                    style={{ marginTop: "4px" }}
                  >
                    <div style={{ marginTop: "4px" }}>
                      <Button
                        color="success"
                        onClick={this.searchContracts}
                        style={{ marginTop: "-5px" }}
                      >
                        <FaSearch />
                      </Button>
                    </div>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row className="below">
                  <Col md={4}>
                    <FormGroup check inline>
                      <Label check inline>
                        <Input
                          type="radio"
                          name="indices"
                          value="CONTRACT_AWARD_NOTICES"
                          onChange={this.selectIndice}
                        />{" "}
                        Award Notices
                      </Label>
                    </FormGroup>
                    {/* </Col> */}
                    {/* <Col md={4}> */}
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="indices"
                          value="CONTRACT_NOTICES"
                          onChange={this.selectIndice}
                        />{" "}
                        Contract Notices
                      </Label>
                    </FormGroup>

                    {/* </Col> */}
                    {/* <Col md={4}> */}
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="indices"
                          value="Both"
                          onChange={this.selectIndice}
                          checked={this.state.indece === "Both" ? true : false}
                        />{" "}
                        Both
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          {/* {this.renderSearchBy()} */}
          {this.renderBuyerCountryFilter()}
          {this.renderSellerCountryFilter()}
          {this.renderYearCountryFilter()}
        </Row>
        <br />
        {/* <Row> */}
        {this.renderArray()}
        {/* </Row> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filters: state.publicContracts.filters,
    result: state.publicContracts.result,
    buyerCountries: state.publicContracts.buyerCountries,
    sellerCountries: state.publicContracts.sellerCountries,
    buyers: state.publicContracts.buyers,
    sellers: state.publicContracts.sellers,
    time: state.publicContracts.time,
    cpvs: state.publicContracts.cpvs,
    cpvsTable: state.publicContracts.cpvsTable,
    amount: state.publicContracts.amount,
    number: state.publicContracts.number,
    userSearchRes: state.publicContracts.userSearch,
    getContractsBetweenres: state.publicContracts.constractsBetween,
    getRelatedNoticeres: state.publicContracts.relatedNotice,
    getConn: state.publicContracts.connections,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getResult: (filters, from, identifier) =>
    //   dispatch(getResult(filters, from, identifier)),
    getRelatedNotice: (filters, indece) =>
      dispatch(getRelatedNotice(filters, indece)),
    getContractsBetween: (filters, pag, indices) =>
      dispatch(getContractsBetween(filters, pag, indices)),
    userSearch: (filters, from, identifier, rows, indece) =>
      dispatch(userSearch(filters, from, identifier, rows, indece)),
    getConnections: (payload) => dispatch(getConnections(payload)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(searchContract)
);
