import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { UserProps } from '../../../shared/prop-types/ReducerProps';
import authStorage from '../../../services/authentication/authStorage';

// CUSTOM COMPONENTS
import TopbarMenuLink from './TopbarMenuLink';
import Avatar from '../../../components/Avatar/Avatar';

class TopbarProfile extends PureComponent {
  static propTypes = {
    user: UserProps.isRequired
  };

  constructor() {
    super();
    this.state = {
      collapse: false
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  logout = () => {
    this.props.logout();
    authStorage.removeAuth('auth');
  };

  render() {
    const { user } = this.props;
    const { collapse } = this.state;

    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" type="button" onClick={this.toggle}>
          <Avatar username={user.username} size="sm" />
          <p className="topbar__avatar-name">{user.username}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && (
          <button
            className="topbar__back"
            type="button"
            onClick={this.toggle}
          />
        )}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink
              title="My Profile"
              icon="user"
              path="/profile"
              onClick={this.toggle}
            />
            <div className="topbar__menu-divider" />
            <TopbarMenuLink
              title="Log Out"
              icon="exit"
              path="/log_in"
              onClick={this.logout}
            />
          </div>
        </Collapse>
      </div>
    );
  }
}

export default TopbarProfile;
