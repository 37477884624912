var countriesIso = require('i18n-iso-countries');
var allCountries = require('i18n-iso-countries/langs/en.json');
countriesIso.registerLocale(allCountries);

export const getCountryName = countryISO => {
  return countriesIso.getName(countryISO, 'en');
};

export const getCountries = () => {
  return allCountries.countries;
};
