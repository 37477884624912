import React, { useState, useRef, createRef } from 'react';
import {
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import classnames from 'classnames';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import ShareIcon from 'mdi-react/ShareIcon';

import './ShareTabs.scss';

/** Set Frontend URL & Facebook app Id */
const frontendURL = process.env.REACT_APP_PROD_URL
  ? process.env.REACT_APP_PROD_URL
  : 'https://data-stories.eu/';

const ShareTabs = ({ id, url, fields }) => {
  const [activeTab, setActiveTab] = useState(0);

  /** Create dynamic refs based on the fields input */
  const inputRefs = useRef(
    fields.map(() => {
      return createRef();
    })
  );

  /** Toggle tab panel */
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  /**NOTE: navFields and tabFields they are separated for readability and flexibility */

  /** Create dynamic navFields */
  const navFields = () => {
    return fields.map((field, index) => {
      return (
        <NavItem key={`${field.name}_${index}`}>
          <NavLink
            className={classnames({ active: activeTab === index })}
            onClick={() => toggleTab(index)}
          >
            {field.name.toUpperCase()}
          </NavLink>
        </NavItem>
      );
    });
  };

  /** Create dynamic tabFields, use of inputRefs.current attribute */
  const tabFields = () => {
    return fields.map((field, index) => {
      var url = shareUrl(field.name);
      return (
        <TabPane tabId={index} key={`${field.name}_${index}`}>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center input_container">
                <input
                  id={`myInput_${field.name}`}
                  ref={el => (inputRefs.current[index] = el)}
                  type="text"
                  className="share_input"
                  value={url}
                  readOnly
                />
                {urlAction(field.type, url)}
              </div>
            </CardBody>
          </Card>
        </TabPane>
      );
    });
  };

  const urlAction = (type, url) => {
    return (
      <>
        {type === 'copy' ? (
          <div
            className="d-flex align-items-center pointer"
            onClick={() => copyToClipboard()}
          >
            <ContentCopyIcon />
          </div>
        ) : (
          <div className="d-flex align-items-center pointer">
              <a target="_blank" href={url} rel="noopener noreferrer">
              <ShareIcon />
            </a>
          </div>
        )}
      </>
    );
  };

  /** Determines what url to use for sharing (it points to production not in localhost) */
  const shareUrl = fieldName => {
    var share_url = frontendURL + url + id;
    if (fieldName === 'share') {
    } else if (fieldName === 'embed') {
      share_url = `<iframe width="560" height="315" src="${share_url}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    } else if (fieldName === 'twitter') {
      share_url = `https://twitter.com/intent/tweet?url=${share_url}`;
    } else if (fieldName === 'facebook') {
      share_url = `https://www.facebook.com/dialog/share?app_id=436012797083856&display=popup&href=${share_url}&redirect_uri=${share_url}`;
    }
    return share_url;
  };

  /**
   * Copies current input field to clipboard
   */
  const copyToClipboard = () => {
    /** Get active tab field */
    var element = inputRefs.current[activeTab];
    element.select();
    element.setSelectionRange(0, 99999); /*For mobile devices*/
    /* Copy the text inside the text field */
    document.execCommand('copy');
  };

  return (
    <div className="share_fields">
      <div className="tabs">
        <div className="tabs__wrap">
          <Nav tabs>{navFields()}</Nav>
          <TabContent activeTab={activeTab}>{tabFields()}</TabContent>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ShareTabs);
