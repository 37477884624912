import React, { useState, useRef, useEffect } from "react";

import { Card, CardBody } from "reactstrap";
import {
  Col,
  CardTitle,
  FormGroup,
  Container,
  ButtonGroup,
  InputGroupButtonDropdown,
  Row,
  Button,
  InputGroup,
  Input,
  InputGroupAddon,
  Form,
  Label,
  Dropdown,
  Table,
  Jumbotron,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import "../Details.scss";
// import "./esthonianDetails.css"
import { OpenStreetMapProvider } from "leaflet-geosearch";
import EsthonianMap from "./esthonianMap";

const provider = new OpenStreetMapProvider();
let getlog = async () => {
  const results = await provider.search({
    query: "Harju maakond, Tallinn, Nõmme linnaosa, Teelise tn 4",
  });
  console.log("results", results);
};

const EsthonianToEnglishCols = {
  nimi: "Name",
  ariregistri_kood: "Registry code",
  kmkr_nr: "Value Added Tax identification number",
  ettevotja_staatu: "Business Status ??",
  ettevotja_staatus_tekstina: "Business Status Text ??",
  ettevotja_esmakande_kpv: "Date of the first entry",
  asukoht_ettevotja_aadressis: "Address",
  asukoha_ehak_kood: "Postal Code ?",
  asukoha_ehak_tekstina: "City/Town",
  indeks_ettevotja_aadressis: "Address Number",
  ads_adr_id: "ads_adr_id",
  ads_ads_oid: "ads_ads_oid",
  ads_normaliseeritud_taisaadress: "Full Address",
  teabesysteemi_link: "URL",
};

const EsthonianColsView = {
  nimi: "Name",
  ariregistri_kood: "Registry Code",
  ads_normaliseeritud_taisaadress: "Address",
  teabesysteemi_link: "URL",
};

const renderCols = (props) => {};

const EsthonianDetails = (props) => {
  const [position, setPosition] = useState([]);

  useEffect(() => {
    async function getLatLog() {
      const res = await provider.search({
        query: props.data.data[0]["ads_normaliseeritud_taisaadress"],
      });
      // console.log("ANTONISMPANTIS222", res, props.data.data[0]["ads_normaliseeritud_taisaadress"])
      setPosition(res);
    }

    getLatLog();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log("propsdata", props.data.data)
  // console.log("ANTONISMPANTIS", position)
  return (
    <div>
      <Row>
        <Col md={12}>
          <div className="details_sidebar">
            <Card>
              <CardBody className="card_box">
                {/* <h4>Esthonian Dataset</h4> */}
                {/* <div className="cardTitle">Esthonian Registry</div> */}
                <div className="card__title">
                  <h5>Esthonian Registry</h5>
                </div>
                <Table>
                  <tbody>
                    {Object.keys(EsthonianColsView).map((col, i) => {
                      if (EsthonianColsView[col] === "URL") {
                        return (
                          <tr key={"data-row-" + i}>
                            <th>{EsthonianColsView[col]}</th>
                            <th>
                              <a href={props.data.data[0][col]} target="_blank">
                                {props.data.data[0][col]}
                              </a>{" "}
                            </th>
                          </tr>
                        );
                      } else {
                        return (
                          <tr key={"data-row-" + i}>
                            <th>{EsthonianColsView[col]}</th>
                            <th>{props.data.data[0][col]}</th>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {position.length > 0 && <EsthonianMap pos={position[0]} />}
        </Col>
      </Row>
    </div>
  );
};

export default EsthonianDetails;
