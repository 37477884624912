import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import FileDocumentBoxIcon from 'mdi-react/FileDocumentBoxIcon';
import { connect } from 'react-redux';

// CUSTOM COMPONENTS
import { BasicNotification } from '../../components/Notification/Notification';

// CUSTOM SCSS
import './Home.scss';

let notification = null;

const showNotification = rtl => {
  notification.notice({
    content: (
      <BasicNotification
        title="Welcome to the Data Stories!"
        message="This dashboard allows you to explore big data. Choose your filters and find the data for your DataStory!"
      />
    ),
    duration: 5,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: `right-up ${rtl}-support`
  });
};
class Home extends PureComponent {
  componentDidMount() {
    const { rtl } = this.props;
    NotificationSystem.newInstance(
      { style: { top: 65 } },
      n => (notification = n)
    );
    setTimeout(() => showNotification(rtl.direction), 700);
  }

  componentWillUnmount() {
    notification.destroy();
  }

  render() {
    return (
      <Container className="home">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Welcome to Data Stories!</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={3} lg={6} xs={12}>
            <div className="home__card active">
              <FileDocumentBoxIcon />
              <h4 className="home__card-title">
                <Link to="/public_contracts">Public Contracts</Link>
              </h4>
              <p>
                - Visualize data
                <br />- Apply easily multiple filters
              </p>
            </div>
          </Col>
          <Col md={12} xl={3} lg={6} xs={12}>
            <div className="home__card">
              <FileDocumentBoxIcon />
              <h4 className="home__card-title">
                <Link to="/public_contracts">More Data</Link>
              </h4>
              <p>Coming soon...</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    rtl: state.rtl
  };
};

export default withRouter(connect(mapStateToProps)(Home));
