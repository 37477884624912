const pieChart = {
  chart: {
    type: "pie",
  },
  title: {
    text: "",
  },
  colors: ["#FF8660", "#FFB760", "#E05486", "#4481A5", "#9FE657"],
  legend: {
    enabled: true,
    shadow: false,
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">value: <b>{point.y:,.0f}</b>,&nbsp;</td><td style="padding:0">&nbsp;<b>({point.percentage:,.1f} %)</b></td></tr>',
    footerFormat: "</table>",
    useHTML: true,
  },
  xAxis: {
    title: {
      enabled: false,
    },
    type: "category",
  },
  plotOptions: {
    series: {
      dataLabels: {
        useHTML: 1,
        format: "{point.name}",
      },
    },
    pie: {
      dataLabels: {
        useHTML: 1,
        format: "{point.name}",
      },
    },
  },
  yAxis: {
    title: {
      enabled: false,
    },
    type: "linear",
  },
  series: [
    {
      name: "none",
      data: [],
    },
  ],
  credits: {
    enabled: true,
  },
};

export { pieChart };
