const treemapChart = {
  type: 'treemap',
  allowDrillToNode: true,
  layoutAlgorithm: 'squarified',
  title: {
    text: ''
  },
  colors: ['#FF8660', '#FFB760', '#E05486', '#4481A5', '#9FE657'],
  series: [
    {
      type: 'treemap',
      layoutAlgorithm: 'squarified',
      allowDrillToNode: true,
      animationLimit: 100,
      dataLabels: {
        enabled: false
      },
      levelIsConstant: false,
      allowPointSelect: true,
      levels: [
        {
          level: 1,
          dataLabels: {
            enabled: true
          },
          borderWidth: 3
        }
      ],
      data: []
    }
  ]
};

export { treemapChart };
