import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import {
  addDataset,
  resetDatasetIndicators
} from '../../redux/actions/datasetActions';

// CUSTOM COMPONENTS
import InputField from '../CustomFields/InputField';
import RadioField from '../CustomFields/RadioField';
import TagField from '../CustomFields/TagField';
import Loader from '../Loader/Loader';

// CUSTOM SCSS
import './AddDatasetForm.scss';

class AddDataset extends Component {
  constructor() {
    super();
    this.state = {
      dataset: {
        title: '',
        type: 'Query',
        access_level: 'Public',
        tags: []
      },
      action_result: false
    };
  }

  /** Avoids re render of child components */
  shouldComponentUpdate(nextProps, nextState) {
    var result =
      nextProps !== this.props
        ? true
        : nextState.action_result !== this.state.action_result;
    return result;
  }

  /**
   * Reset redux to initial state if error or loading is not null
   */
  componentWillUnmount() {
    if (this.props.error || this.props.loading) {
      this.props.resetDatasetIndicators();
    }
  }

  /**
   * If field is evaluated true then set state depending on name of the input
   * @param {Boolean} validation
   * @param {String} value
   * @param {String} name
   */
  valueCheckAssign = (value, name) => {
    this.setState(prevState => ({
      dataset: {
        ...prevState.dataset,
        [name]: value
      }
    }));
  };

  /**
   * Call redux action to login only if title is filled. Does not check other fields
   * @param {event} e
   */
  addDataset = async e => {
    e.preventDefault();
    if (this.state.dataset.title) {
      var datasetObj = {
        ...this.state.dataset,
        data: [this.props.data]
      };
      var action_result = await this.props.addDataset(datasetObj);
      if (action_result && !this.props.error) {
        this.setState({ action_result });
        setTimeout(() => {
          this.props.closeModal();
        }, 1500);
      }
    }
  };

  render() {
    return (
      <form
        className="form add-dataset-form"
        onSubmit={event => this.addDataset(event)}
      >
        <div className="form__form-group">
          <div className="form__form-group-field">
            <InputField
              valueCheck={(value, type) => this.valueCheckAssign(value, type)}
              type="text"
              name="title"
              placeholder="Enter a title"
              label="Title"
              texture="material"
              required
            />
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <TagField
              valueCheck={(value, type) => this.valueCheckAssign(value, type)}
              type="text"
              name="tags"
              placeholder="Separate tags by commas"
              label="Tags"
              texture="material"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Type</span>
          <div className="form__form-group-field">
            <RadioField
              name="type"
              type="radio"
              values={['Query', 'Dataset', 'Visualization']}
              selectedRadio={(value, type) =>
                this.valueCheckAssign(value, type)
              }
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Access level</span>
          <div className="form__form-group-field">
            <RadioField
              name="access_level"
              type="radio"
              values={['Public', 'Private']}
              selectedRadio={(value, type) =>
                this.valueCheckAssign(value, type)
              }
            />
          </div>
        </div>
        <div className="button-container d-flex justify-content-end">
          <Button className="account__btn" color="primary" type="submit">
            Save
          </Button>
        </div>
        <Loader loading={this.props.loading} simple={true}></Loader>
        {this.state.action_result && (
          <div className="success_message">Data were successfully saved</div>
        )}
        {this.props.error && !this.props.loading && (
          <div className="error_message">{this.props.error} </div>
        )}
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.dataset.loading,
    error: state.dataset.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addDataset: dataset => dispatch(addDataset(dataset)),
    resetDatasetIndicators: () => dispatch(resetDatasetIndicators())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDataset);
