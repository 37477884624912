import React from 'react';
import LogInForm from './LogInForm';

const LoginCard = () => {
  return (
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">
            Welcome to
            <span className="account__logo">
              Data
              <span className="account__logo-accent">Stories</span>
            </span>
          </h3>
          <h4 className="account__subhead subhead">Login or register</h4>
        </div>
        <LogInForm form="log_in_form" />
      </div>
    </div>
  );
};

export default LoginCard;
