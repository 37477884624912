const cpvsHead = [
  {
    header: 'Name',
    type: 'string',
    attribute: 'key'
  },
  {
    header: 'Amount',
    type: 'number',
    attribute: 'amount'
  },
  {
    header: 'Count',
    type: 'number',
    attribute: 'count'
  }
];

export { cpvsHead };
