import { apiRequest } from '../../services/api/index';

/**
 * Action Types
 */
export const FETCH_STORIES_DATA = 'FETCH_STORIES_DATA';
export const FETCH_STORIES_DATA_SUCCESSFUL = 'FETCH_STORIES_DATA_SUCCESSFUL';
export const FETCH_STORY_DATA_SUCCESSFUL = 'FETCH_STORY_DATA_SUCCESSFUL';
export const FETCH_STORIES_DATA_ERROR = 'FETCH_STORIES_DATA_ERROR';

/** Error message for network down */
const SERVER_ERROR = 'Something went wrong. Please try later!';

/**
 *  ACTION CREATORS
 */

function fetchData(payload) {
  return {
    type: FETCH_STORIES_DATA,
    payload
  };
}

function setData(payload) {
  return {
    type: FETCH_STORIES_DATA_SUCCESSFUL,
    payload
  };
}

function setStory(payload) {
  return {
    type: FETCH_STORY_DATA_SUCCESSFUL,
    payload
  };
}

function setError(payload) {
  return {
    type: FETCH_STORIES_DATA_ERROR,
    payload
  };
}

/**
 * ACTIONS THAT CALL API
 */

/**
 * Get all my stories
 */
export const getMyStories = (pageSize, page) => {
  return async dispatch => {
    dispatch(fetchData());
    try {
      const params = {
        type: 'myStories',
        data: {
          params: {
            page_size: pageSize,
            page
          }
        }
      };
      const response = await apiRequest(params);
      if (response && response.status === 200) {
        dispatch(setData(response.data));
      } else {
        dispatch(setError(response.data.error));
      }
    } catch (error) {
      dispatch(setError(SERVER_ERROR));
    }
  };
};

/**
 * Get all my stories and all public stories
 */
export const getStories = (pageSize, page) => {
  return async dispatch => {
    dispatch(fetchData());
    try {
      const params = {
        type: 'stories',
        data: {
          params: {
            page_size: pageSize,
            page
          }
        }
      };
      const response = await apiRequest(params);
      if (response && response.status === 200) {
        dispatch(setData(response.data));
      } else {
        dispatch(setError(response.data.error));
      }
    } catch (error) {
      dispatch(setError(SERVER_ERROR));
    }
  };
};

/**
 * Get single story
 */
export const getStory = id => {
  return async dispatch => {
    dispatch(fetchData());
    try {
      const params = {
        dynamic_url: id,
        type: 'story'
      };
      const response = await apiRequest(params);
      if (response && response.status === 200) {
        dispatch(setStory(response.data[0]));
      } else {
        dispatch(setError(response.data.error));
      }
    } catch (error) {
      dispatch(setError(SERVER_ERROR));
    }
  };
};
