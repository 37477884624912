import { combineReducers, createStore, applyMiddleware } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
/** import DataStories Reducers  */
import {
  PublicContractsReducer,
  detailsReducer,
  cryptoTableReducer,
  newOrderTableReducer,
  sidebarReducer,
  themeReducer,
  customizerReducer,
  todoReducer,
  rtlReducer,
  authReducer,
  datasetReducer,
  visualizationReducer,
  storiesReducer,
} from "./redux/reducers/index";

const reducer = combineReducers({
  publicContracts: PublicContractsReducer,
  details: detailsReducer,
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  rtl: rtlReducer,
  sidebar: sidebarReducer,
  cryptoTable: cryptoTableReducer,
  newOrder: newOrderTableReducer,
  customizer: customizerReducer,
  todos: todoReducer,
  user: authReducer,
  dataset: datasetReducer,
  visualization: visualizationReducer,
  stories: storiesReducer,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
