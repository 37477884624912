import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';

// CUSTOM SCSS
import './RadioField.scss';

const RadioField = props => {
  const [selectedRadio, setSelectedRadio] = useState(props.values[0]);

  const selectedValue = value => {
    setSelectedRadio(value);
    props.selectedRadio(value, props.name);
  };

  const radioRender = () => {
    var Radios = props.values.map(radio => {
      return (
        <div key={radio} className="radio">
          <Radio
            key={radio}
            name={props.name}
            value={radio}
            onClick={() => selectedValue(radio)}
            checked={selectedRadio === radio}
          />
          <label className="label_text" onClick={() => selectedValue(radio)}>
            {radio}
          </label>
        </div>
      );
    });
    return Radios;
  };

  return <div className="radios-container d-flex">{radioRender()}</div>;
};

export default RadioField;
