// ACTION TYPES
import {
  FETCH_INFO_DATA,
  FETCH_INFO_DATA_SUCCESSFUL,
  FETCH_INFO_DATA_ERROR
} from '../actions/detailsActions';

const initialState = {
  dataTotals: {
    data: [],
    error: '',
    fetching: false,
    view: []
  },
  mapData: {
    id: 'infoMap',
    data: [],
    error: '',
    fetching: false,
    title: 'Contracts',
    map: 'World',
    view: []
  },
  yearDataISO: {
    type: 'bar',
    data: [],
    error: '',
    fetching: false,
    title: 'Contracts & expenses per year',
    view: []
  },
  yearDataWIN: {
    type: 'bar',
    data: [],
    error: '',
    fetching: false,
    title: 'Awards & revenues per year',
    view: []
  },
  searchData: {
    data: [],
    error: '',
    fetching: false,
    title: 'Contracts',
    view: [],
    from: 0,
    total: 0
  },
  searchDataCountrySELLERS: {
    data: [],
    error: '',
    fetching: false,
    title: 'Contracts',
    view: [],
    from: 0,
    total: 0
  },
  searchDataCountryBUYERS: {
    data: [],
    error: '',
    fetching: false,
    title: 'Awarded Contracts',
    view: [],
    from: 0,
    total: 0
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_INFO_DATA: {
      return {
        ...state,
        [action.payload.itemKey]: {
          ...state[action.payload.itemKey],
          fetching: true
        }
      };
    }
    case FETCH_INFO_DATA_SUCCESSFUL: {
      return {
        ...state,
        [action.payload.itemKey]: {
          ...state[action.payload.itemKey],
          ...action.payload.response,
          error: '',
          fetching: false
        }
      };
    }

    case FETCH_INFO_DATA_ERROR: {
      return {
        ...state,
        [action.payload.itemKey]: {
          data: [],
          error: action.payload.error,
          fetching: false
        }
      };
    }
    default:
      return state;
  }
}
