/** Route maps */
export const mapRoutes = {
  login: {
    url: '/auth/login',
    method: 'post'
  },
  register: {
    url: '/auth/register',
    method: 'post'
  },
  me: {
    url: '/me',
    method: 'get'
  },
  create: {
    url: '/datasets/create/',
    method: 'post'
  },
  myDatasets: {
    url: '/datasets/myDatasets/',
    method: 'get'
  },
  dataset: {
    url: '/datasets/dataset/',
    method: 'get'
  },
  visualization: {
    url: '/datasets/visualization/',
    method: 'get'
  },
  createStory: {
    url: '/stories/create/',
    method: 'post'
  },
  stories: {
    url: '/stories/',
    method: 'get'
  },
  myStories: {
    url: '/stories/myStories/',
    method: 'get'
  },
  story: {
    url: '/stories/story/',
    method: 'get'
  },
  facets: {
    url: '/elastic/createFacets/',
    method: 'post'
  },
  search: {
    url: '/elastic/searchData/',
    method: 'post'
  },
  grid: {
    url: '/elastic/createGrid/',
    method: 'post'
  },
  aggregatePerField: {
    url: '/elastic/aggregatePerField/',
    method: 'post'
  },
  aggregatePerTimeLine: {
    url: '/elastic/aggregatePerTimeLine/',
    method: 'post'
  },
  aggregatePerTimeGrid: {
    url: '/elastic/aggregatePerTimeGrid/',
    method: 'post'
  },
  createTotalsPerField: {
    url: '/elastic/createTotalsPerField/',
    method: 'post'
  },
  aggregateCPVsAdditionalCpvs: {
    url: '/elastic/aggregateCPVs/',
    method: 'post'
  },
  searchEsthonianRegistry: {
    url: '/elastic/esthonianRegistry/',
    method: 'post'
  }
};


// const mapRoutes = {
//   login: {
//     url: '/auth/login',
//     method: 'post'
//   },
//   register: {
//     url: '/auth/register',
//     method: 'post'
//   },
//   me: {
//     url: '/me',
//     method: 'get'
//   },
//   create: {
//     url: '/datasets/create/',
//     method: 'post'
//   },
//   myDatasets: {
//     url: '/datasets/myDatasets/',
//     method: 'get'
//   },
//   dataset: {
//     url: '/datasets/dataset/',
//     method: 'get'
//   },
//   visualization: {
//     url: '/datasets/visualization/',
//     method: 'get'
//   },
//   createStory: {
//     url: '/stories/create/',
//     method: 'post'
//   },
//   stories: {
//     url: '/stories/',
//     method: 'get'
//   },
//   myStories: {
//     url: '/stories/myStories/',
//     method: 'get'
//   },
//   story: {
//     url: '/stories/story/',
//     method: 'get'
//   },
//   facets: {
//     url: '/elastic/createFacets/',
//     method: 'post'
//   },
//   search: {
//     url: '/elastic/searchData/',
//     method: 'post'
//   },
//   grid: {
//     url: '/elastic/createGrid/',
//     method: 'post'
//   },
//   aggregatePerField: {
//     url: '/elastic/aggregatePerField/',
//     method: 'post'
//   },
//   aggregatePerTimeLine: {
//     url: '/elastic/aggregatePerTimeLine/',
//     method: 'post'
//   },
//   aggregatePerTimeGrid: {
//     url: '/elastic/aggregatePerTimeGrid/',
//     method: 'post'
//   },
//   aggregateCPVsAdditionalCpvs: {
//     url: '/elastic/aggregateCPVs/',
//     method: 'post'
//   }
// };