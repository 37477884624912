import {
  apiRequest
} from '../../../services/api/index';

import {
  fetchData,
  setData,
  setError
} from './publicContractsActions';

import {
  cpvsHead
} from '../../../services/helpers/cpvsHead';


export function getAdditionalCPVs(payload) {
  return async dispatch => {
    dispatch(fetchData('additional_cpvs'))
    try {
      const params = {
        type: 'facets',
        data: {
          fields: ['CPV'],
          terms: [],
          aggregation: ['ADDITIONAL_CPVS'],
          ranges: []
        }
      };

      if (payload.cpv) {
        console.log("additional  cpv inside", payload)
        params.data.terms.push({
          CPV: payload.cpv
        });
      }
      console.log('params', params)
      const response = await apiRequest(params);
      if (response.status === 200) {
        dispatch(setData('additional_cpvs', response.data['ADDITIONAL_CPVS']))
      }

    } catch (e) {
      console.log(e)
    }
  }
}


export function getAdditionalCPVsPagination(payload, from) {
  console.log('Get cpv paginated', from)
  return async dispatch => {
    dispatch(fetchData('additional_cpv'))

    try {
      const params = {
        type: 'aggregatePerField',
        data: {
          field: 'ADDITIONAL_CPVS',
          terms: [],
          ranges: [],
          size: 10,
          from: from
        }
      };

      if (payload.cpv) {
        console.log("additional  cpv inside", payload)
        params.data.terms.push({
          CPV: payload.cpv
        });
      }

      if (payload.buyerCountry) {
        params.data.terms.push({
          ISO_COUNTRY_CODE: payload.buyerCountry,
        });
      }

      console.log('params', params)
      const response = await apiRequest(params);
      console.log("cpvPagres", response)
      if (response.status === 200) {
        // dispatch(setData('additionalCpvsTable', response.data))
        const payload = {
          data: response.data.data,
          view: cpvsHead,
          total: response.data.total,
          from: from + 10
        };
        dispatch(setData('additionalCpvsTable', payload));

      }
    } catch (error) {
      dispatch(
        setError('cpvsTable', 'Something went wrong. Please try later!')
      );
    }



  }
}