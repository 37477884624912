const rangeChart = {
  chart: {
    type: "column",
    backgroundColor: "transparent",
    margin: [0, 0, 0, 0],
    height: 100,
  },
  title: {
    text: "",
  },
  colors: ["#9DE5C0"],
  legend: {
    enabled: false,
    shadow: false,
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat: '<tr><td style="padding:0"><b>{point.y:,.0f}</b></td></tr>',
    footerFormat: "</table>",
    useHTML: true,
  },
  xAxis: {
    title: {
      enabled: false,
    },
    type: "category",
    categories: [],
    tickWidth: 0,
    labels: {
      rotation: -90,
      y: -5,
      x: 0,
      reserveSpace: false,
      align: "left",
      style: {
        whiteSpace: "nowrap",
        textOverflow: "none",
      },
    },
  },
  exporting: {
    enabled: false,
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      maxPointWidth: 30,
    },
    series: {
      dataLabels: {},
    },
  },
  yAxis: {
    title: {
      enabled: false,
    },
    type: "linear",
    gridLineWidth: 0,
    labels: {
      enabled: false,
    },
  },
  series: [],
  credits: {
    enabled: false,
  },
};

export { rangeChart };
