import React, { PureComponent } from "react";
import QueriesTable from "../QueriesTable/QueriesTable";

export default class GridTable extends PureComponent {
  state = {
    count: 0,
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (
      this.props.identifier === "buyerCountriesResult" ||
      this.props.identifier === "sellerCountriesResult" ||
      this.props.identifier === "allDataResult" ||
      this.props.identifier === "CPVsCountriesResult"
    ) {
      if (JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
        if (this.props.data && this.props.data.data) {
          //console.log("anesid_inside_Grid: ", this.props);
          let count = this.state.count++;
          this.setState({ count });
        } else {
          //   this.setState({});
        }
      }
    }
  }

  render() {
    //console.log("anesid IQuery Details component: ", this.props);
    // console.log("anesid Inside Range Props: ", this.props);
    return (
      <div>
        <div className="page-title details-header">
          Queries on DataStories Data Repository
        </div>
        <div className="details-tagline">
          The following table indicates the queries run by the component in
          order to fetch data. These include:
        </div>
        <div className="details-list">
          <ul>
            <li>
              <strong>Repository:</strong> Elastic Search.
            </li>
            <li>
              <strong>Method:</strong> GET or POST.
            </li>
            <li>
              <strong>URL:</strong> The actual publicly accessible url of the
              query.
            </li>
            <li>
              <strong>Query:</strong> The parameteres passed to the body of the
              API request.
            </li>
            <li>
              <strong>Run:</strong> Press the play button for details on how to
              run the query in the querying interface of the respective
              repository.
            </li>
          </ul>
        </div>
        <div className="queries-table">
          <QueriesTable />
        </div>
      </div>
    );
  }
}
