import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';

// CUSTOM SCSS
import './Story.scss';

const Story = props => {
  const truncateText = text => {
    const tmp = document.createElement('div');
    tmp.innerHTML = text;
    text = tmp.textContent || tmp.innerText;
    const maxPos = props.textSize ? props.textSize : 150;
    if (text.length > maxPos) {
      const lastPos = maxPos - 3;
      return text.substring(0, lastPos) + '...';
    } else {
      return text;
    }
  };

  const formatDate = date => {
    date = new Date(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return (date = dd + '/' + mm + '/' + yyyy);
  };

  return (
    <div className={`stories__card ${props.className}`}>
      <h4 className="d-flex align-items-center stories__card-title">
        <Link to={`/story/${props.story.id}`}>{props.story.title}</Link>
        <Badge
          className={`${props.story.access_level.toLowerCase()} story__label`}
        >
          {props.story.access_level}
        </Badge>
      </h4>
      <div className="story_info d-flex justify-content-between">
        <div>by {props.story.owner}</div>
        <div>{formatDate(props.story.createdAtUTC)}</div>
      </div>
      <div className="story_description">{truncateText(props.story.data)}</div>
      <div className="d-flex justify-content-end">
        <Link className="outline-link" to={`/story/${props.story.id}`}>
          Read more
        </Link>
      </div>
    </div>
  );
};
export default Story;
