import React from 'react';

// CUSTOM SCSS
import './Avatar.scss';

const Avatar = props => (
  <div
    className={`size-${props.size} d-flex align-items-center justify-content-center profile_avatar`}
  >
    {props.username.substring(0, 1)}
  </div>
);

export default Avatar;
