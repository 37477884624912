import React, { PureComponent } from 'react';
import { Card, CardBody } from 'reactstrap';
import { MenuItem, FormControl, Select } from '@material-ui/core';

// CUSTOM COMPONENTS
import Loader from '../Loader/Loader';

// CUSTOM DATA
import { amount } from '../../services/platform/utils';

// CUSTOM SCSS
import './SelectRange.scss';

export default class SelectRange extends PureComponent {
  state = {
    start: '',
    end: '',
    isLoading: true
  };

  componentDidMount() {
    if (this.props.selectedValue) {
      this.setState({
        start: this.props.selectedValue[0],
        end: this.props.selectedValue[1]
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedValue !== prevProps.selectedValue &&
      this.props.selectedValue
    ) {
      this.setState({
        start: this.props.selectedValue[0],
        end: this.props.selectedValue[1]
      });
    }

    if (this.props.data) {
      this.setState({
        isLoading: this.props.data.fetching
      });
    }
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value }, () => {
      const newRange = [];
      newRange.push(this.state.start);
      newRange.push(this.state.end);
      this.props.onFilterUpdate(this.props.data.id, newRange);
    });
  };

  render() {
    return (
      <Card>
        <CardBody>
          {this.props.data && (
            <div className="card__title">
              <h5 className="bold-text">{this.props.data.title}</h5>
            </div>
          )}
          {this.state.isLoading ? (
            <Loader loading={this.state.isLoading} simple={true} />
          ) : (
            <div className="select_range d-flex">
              <div className="dropdown">
                <FormControl className="range-select" variant="outlined">
                  <Select
                    value={this.state.start}
                    onChange={event =>
                      this.handleChange('start', event.target.value)
                    }
                    displayEmpty
                    MenuProps={{
                      disableScrollLock: true,
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                      }
                    }}
                  >
                    {amount.map(item => {
                      return (
                        <MenuItem key={'start-' + item} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="dropdown">
                <FormControl className="range-select" variant="outlined">
                  <Select
                    value={this.state.end}
                    onChange={event =>
                      this.handleChange('end', event.target.value)
                    }
                    displayEmpty
                    MenuProps={{
                      disableScrollLock: true,
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                      }
                    }}
                  >
                    {amount.map(item => {
                      return (
                        <MenuItem key={'end-' + item} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    );
  }
}
