import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import TablePagination from '@material-ui/core/TablePagination';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import PlaylistPlusIcon from 'mdi-react/PlaylistPlusIcon';

// REDUX
import { connect } from 'react-redux';
import { getStories } from '../../redux/actions/storiesActions';

// CUSTOM COMPONENTS
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error/Error';
import Story from '../../components/Story/Story';

// CUSTOM SCSS
import './Stories.scss';

const ROWS_PER_PAGE = 7;

class Stories extends PureComponent {
  state = {
    isLoading: false,
    error: '',
    page: 0
  };

  componentDidMount() {
    this.props.getStories(ROWS_PER_PAGE, this.state.page);
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetching !== prevProps.fetching) {
      this.setState({ isLoading: this.props.fetching });
    }
    if (this.props.error !== prevProps.error) {
      this.setState({ error: this.props.error });
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, () => {
      this.props.getStories(ROWS_PER_PAGE, this.state.page);
    });
  };

  render() {
    return (
      <Container className="stories">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Stories</h3>
          </Col>
        </Row>
        <Loader loading={this.state.isLoading} page={true} />
        {!this.state.isLoading && (
          <>
            {this.state.error ? (
              <Error error={this.state.error} page={true} />
            ) : (
              <>
                <Row>
                  {this.state.page === 0 && (
                    <Col md={6} xl={3} lg={4} xs={12}>
                      <Link
                        to="/new_story"
                        className="d-flex flex-column justify-content-center align-items-center add_story"
                      >
                        <PlaylistPlusIcon />
                        <div className="text">Create new story</div>
                      </Link>
                    </Col>
                  )}
                  {this.props.stories.list.map(story => (
                    <Col md={6} xl={3} lg={4} xs={12} key={story._id}>
                      <Story
                        story={story}
                        className="col_story"
                        textSize={150}
                      />
                    </Col>
                  ))}
                </Row>

                {this.props.stories.listCount && (
                  <TablePagination
                    component="div"
                    className="material-table__pagination"
                    count={this.props.stories.listCount}
                    rowsPerPage={ROWS_PER_PAGE}
                    page={this.state.page}
                    backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                    nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                    onChangePage={this.handleChangePage}
                    rowsPerPageOptions={[]}
                    dir="ltr"
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true
                    }}
                  />
                )}
              </>
            )}
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    fetching: state.stories.fetching,
    error: state.stories.error,
    stories: state.stories
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getStories: (pageSize, page) => dispatch(getStories(pageSize, page))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Stories)
);
