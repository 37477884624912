/** Creates and then downloads Sheet created file */
const createSheet = (type, head, rows) => {
  /** sort and merge head & rows */
  const SheetRows = sortRows(head, rows);
  var SheetFile = '';
  for (var i = 0; i < SheetRows.length; i++) {
    SheetFile += processRow(SheetRows[i]);
  }

  var filename = 'Dataset_' + new Date().getTime();

  /** set filetype depending of selected type - Excel or CSV */
  var fileType =
    type === 'Excel'
      ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      : 'text/csv;charset=utf-8';

  var blob = new Blob([SheetFile], {
    type: fileType
  });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  return true;
};

/** Processing Sheet rows */
const processRow = row => {
  var finalVal = '';
  for (var j = 0; j < row.length; j++) {
    var innerValue = row[j]
      ? row[j].toString()
      : row[j] === undefined
      ? 'undefined'
      : '';
    if (row[j] instanceof Date) {
      innerValue = row[j].toLocaleString();
    }
    var result = innerValue.replace(/"/g, '""');
    if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
    if (j > 0) finalVal += ',';
    finalVal += result;
  }
  return finalVal + '\n';
};

/** Sorts Rows by head and merge head */
const sortRows = (head, rows) => {
  var finalRows = [];
  rows.forEach(rowValue => {
    var sortedRows = [];
    head.forEach(headValue => {
      sortedRows.push(rowValue[headValue.id]);
    });
    finalRows.push(sortedRows);
  });

  /** merge finalRows with head labels with unshift */
  const headRows = head.map((headValue, headIndex) => {
    return head[headIndex].label;
  });
  finalRows.unshift(headRows);

  return finalRows;
};

export { createSheet };
