import React, { useState, useRef } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Redirect } from 'react-router-dom';

// CUSTOM COMPONENTS
import Loader from '../Loader/Loader';
import InputField from '../CustomFields/InputField';
import RadioField from '../CustomFields/RadioField';

// DISPATCH
import { useDispatch } from 'react-redux';

// API CALL
import { createStory } from '../../redux/actions/stories/storyActions';

// FROALA
import FroalaEditor from 'react-froala-wysiwyg';
import { Button } from 'reactstrap';

// Require Froala Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Froala Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import './StoryForm.scss';

/** Backend URL */
const my_frontend = process.env.REACT_APP_PROD_URL
  ? process.env.REACT_APP_PROD_URL
  : 'http://data-stories.eu/';

const StoryForm = ({ setAccess, saveStory }) => {
  /** Set hook state */
  const [model, setModel] = useState('');
  const [story, setStory] = useState({
    title: '',
    access_level: 'Public',
    data: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [savedId, setSavedId] = useState('');
  const [redirect, setRedirect] = useState(false);

  /** import dispatch for usage */
  const dispatch = useDispatch();

  const froala = useRef();

  /** Regex to capture datasets inside froala editor */
  const regEx = /_DATASET(.*?)IFRAME_/;

  const iframeURL = id => {
    return `<iframe width="545" height="620" src="${my_frontend}iframe/${id}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
  };

  /**
   * If field is evaluated true then set state depending on name of the input
   * @param {String} value
   * @param {String} name
   */
  const valueCheckAssign = (value, type) => {
    if (type === 'access_level') {
      setAccessLevel(value);
    }
    setStory(prevState => {
      return { ...prevState, [type]: value };
    });
  };

  /**
   * Validates editor data, saves created story and redirects to it
   * @param {Event} e
   */
  const saveStoryForm = async e => {
    setInitialState();
    setLoading(true);
    e.preventDefault();
    if (story.data === '') {
      setLoading(false);
      setError('Empty editor');
      return;
    }
    if (story.title) {
      const response = await dispatch(createStory(story));
      setLoading(false);
      if (response.status) {
        setSavedId(response.data.story.id);
        setTimeout(() => {
          setRedirect(true);
        }, 2500);
      } else {
        setError(response);
      }
    }
  };

  /**
   * Resets initial hook state
   */
  const setInitialState = () => {
    setLoading(false);
    setError('');
    setSavedId('');
    setRedirect(false);
  };

  /**
   * Sets data from froala editor and process it if editor contains any dataset iframe
   * @param {String} data
   */
  const handleModelChange = data => {
    let currentText = froala.current.element.value;
    if (currentText.indexOf('_DATASET') !== -1) {
      let newText = currentText;
      currentText.match(regEx).forEach(id => {
        newText = currentText.replace(
          '_DATASET' + id + 'IFRAME_',
          iframeURL(id)
        );
      });
      froala.current.editor.html.set(newText);
      valueCheckAssign(froala.current.editor.html.get(), 'data');
      setModel(froala.current.editor.html.get());
    }
    setModel(data);
    valueCheckAssign(data, 'data');
  };

  /**
   * Sets value of access leve
   * @param {String} value
   */
  const setAccessLevel = value => {
    setAccess(value);
  };

  return (
    <div className="new_story_container d-flex flex-row">
      <Card>
        <CardBody>
          <form
            className="form new-story-form d-flex flex-column"
            onSubmit={event => saveStoryForm(event)}
          >
            <div className="form__form-group">
              <div className="form__form-group-field">
                <InputField
                  valueCheck={(value, type) => valueCheckAssign(value, type)}
                  type="text"
                  name="title"
                  placeholder="Enter a title"
                  label="Title"
                  texture="material"
                  required
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Access</span>
              <div className="form__form-group-field">
                <RadioField
                  name="access_level"
                  type="radio"
                  values={['Public', 'Private']}
                  selectedRadio={(value, type) => valueCheckAssign(value, type)}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Text</span>
              <div className="story_editor">
                <FroalaEditor
                  tag="textarea"
                  ref={froala}
                  model={model}
                  onModelChange={handleModelChange}
                  className="d-flex flex-grow-1 froala_editor"
                />
              </div>
            </div>
            <div className="messages d-flex flex-row justify-content-center">
              <div>
                {savedId && (
                  <div className="success_message">
                    Saved successfuly, please wait to be redirected
                  </div>
                )}
                {redirect && savedId && (
                  <Redirect push to={`/story/${savedId}`} />
                )}
                <Loader loading={loading} simple={true}></Loader>
                {error && !loading && (
                  <div className="error_message">{error} </div>
                )}
              </div>
            </div>
            <div className="form__form-group">
              <div className="save_button_container d-flex flex-row justify-content-end">
                <Button
                  color="primary"
                  size="sm"
                  type="submit"
                  className="save_button"
                  disabled={!!savedId}
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default StoryForm;
