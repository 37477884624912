import { apiRequest } from "../../../services/api/index";

import { fetchData, setData, setError } from "./publicContractsActions";

export function getFacets(payload) {
  // console.log("Anesid refactor: ", payload);
  return async (dispatch) => {
    dispatch(fetchData("buyerCountries"));
    dispatch(fetchData("sellerCountries"));
    dispatch(fetchData("cpvs"));
    dispatch(fetchData("time"));
    try {
      const params = {
        type: "facets",
        data: {
          fields: ["WIN_COUNTRY_CODE", "ISO_COUNTRY_CODE", "CPV", "YEAR"],
          terms: [],
          aggregation: ["WIN_COUNTRY_CODE", "ISO_COUNTRY_CODE", "CPV", "YEAR"],
          ranges: [],
        },
      };
      // Add selected facet's value
      if (payload.buyerCountry) {
        params.data.terms.push({
          ISO_COUNTRY_CODE: payload.buyerCountry,
        });
      }
      if (payload.sellerCountry) {
        params.data.terms.push({
          WIN_COUNTRY_CODE: payload.sellerCountry,
        });
      }
      if (payload.cpv) {
        params.data.terms.push({
          CPV: payload.cpv,
        });
      }
      // Add selected facet's range
      if (payload.year) {
        params.data.ranges.push({
          YEAR: {
            start: payload.year[0],
            end: payload.year[1],
          },
        });
      }
      // if (payload.number) {
      //   params.data.params.number = payload.number;
      // }
      // if (payload.amount) {
      //   params.data.params.amount = payload.amount;
      // }

      //console.log("anesid facets: ", params);
      const response = await apiRequest(params);
      if (response.status === 200) {
        dispatch(setData("cpvs", response.data["CPV"]));
        dispatch(setData("time", response.data["YEAR"]));
        // dispatch(
        //   setData('numberOfTrends', response.data['NUMBER_TENDERS_SME'])
        // );
        // dispatch(setData('amount', response.data['VALUE_EURO']));
        response.data.map = "World";
        dispatch(setData("sellerCountries", response.data["WIN_COUNTRY_CODE"]));
        dispatch(setData("buyerCountries", response.data["ISO_COUNTRY_CODE"]));
      } else {
        dispatch(
          setError("sellerCountries", "Something went wrong. Please try later!")
        );
      }
    } catch (error) {
      dispatch(
        setError("sellerCountries", "Something went wrong. Please try later!")
      );
    }
  };
}
