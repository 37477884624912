///
///  {"field": "CPV", "from": 0, "size": 1000, "terms": [{"ISO_COUNTRY_CODE":"DE"}], "ranges": [],"size":100}
///

import React, { PureComponent } from "react";
import {
  Input,
  Label,
  Jumbotron,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
import { FormControl, Select } from "@material-ui/core";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import "rc-slider/assets/index.css";
import "./CountryComparison.css";
import Slider from "rc-slider";
import Loader from "../../components/Loader/Loader";

// import Range from "../../components/Range/Range"
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/bootstrap/tabulator_bootstrap.css"; // theme
import { ReactTabulator } from "react-tabulator";
// REDUX
import { connect } from "react-redux";
import { getBuyers } from "../../redux/actions/publicContracts/buyersActions";
import { getFacets } from "../../redux/actions/publicContracts/facetActions";
import {
  getFirstCountry,
  getSecondCountry,
} from "../../redux/actions/countryComparison/CountryComparison";

import DonutPie from "../../components/DonutChart/DonutPie";
import BubbleChart from "../../components/BubbleChart/BubbleChart";
// import BubbleChart2 from "../../components/BubbleChart/BubbleChart"

import DonutPie2 from "../../components/DonutChart/DonutPie2";

import TreeTable from "../../components/TreeTable/TreeTable";
import {
  getFirstCountryContracts,
  getSecondCountryContracts,
} from "../../redux/actions/countryComparison/CountryComparison";

import {
  setFilter,
  reset_filters,
  getFilters,
} from "../../redux/actions/publicContracts/filtersActions";

import MapSelect from "../../components/MapSelect/MapSelect";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import sunburst from "highcharts/modules/sunburst.js";
sunburst(Highcharts);

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

class CountryComparison extends PureComponent {
  constructor(props) {
    super(props);
    // let amountFrom = null
    // let ammountTo = null
    // Don't call this.setState() here!
    this.state = {
      amountFrom: null,
      ammountTo: null,
      first_country: "",
      second_country: "",
      year_range: [2006, 2019],
      first_country_data: [],
      compare: false,
    };
    // this.handleAmountFrom = this.handleAmountFrom.bind(this)
    // this.handleAmountTo = this.handleAmountTo.bind(this)
    this.inputFrom = React.createRef();
    this.inputTo = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log(
    //   "ShouldComponentUpdated",
    //   nextState.amountFrom,
    //   this.state.amountFrom
    // );
    if (nextState.amountFrom !== this.state.amountFrom) {
      return false;
    }
    if (nextState.amountTo !== this.state.amountTo) {
      return false;
    }
    if (nextState.year_range !== this.state.year_range) {
      return false;
    }

    return true;
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      // Loads preselected filters from dataset
      this.props.getFilters(this.props.match.params.id);
      this.setState({
        isLoading: true,
        initialFilters: true,
        cpvFilter: false,
        first_country: "",
        year_range: [2006, 2019],
        second_country: "",
      });
    } else {
      // Loads initial data
      this.loadApiData();
    }
  }

  componentDidUpdate(prevProps) {
    // Loads new data when filters are updated

    if (prevProps.firstCountry !== this.props.firstCountry) {
      // console.log("Component Did update ", this.props.firstCountry);
      if (this.props.firstCountry.data.length > 0) {
      }
    }
    // if (
    //   JSON.stringify(this.props.filters) !== JSON.stringify(prevProps.filters)
    // ) {
    //   if (this.props.filters.fetching !== prevProps.filters.fetching) {
    //     this.setState({ isLoading: this.props.filters.fetching });
    //   }
    //   if (this.props.filters.error !== prevProps.filters.error) {
    //     this.setState({ error: this.props.filters.error });
    //   }
    //   if (
    //     (this.props.filters.data.chartType !==
    //       prevProps.filters.data.chartType ||
    //       this.props.filters.data.resultsType !==
    //       prevProps.filters.data.resultsType) &&
    //     !this.props.filters.fetching
    //   ) {
    //     if (this.state.initialFilters) {
    //       // If it is the first time a saved query is load, get all data from api
    //       this.loadApiData();
    //       this.setState({ initialFilters: false });
    //     } else {
    //       // Update only results for chart and results type change
    //       this.props.getResult(this.props.filters.data, 0, "allData");
    //     }
    //   } else {
    //     if (!this.state.initialFilters) {
    //       this.loadApiData();
    //     }
    //   }
    // }
  }

  loadApiData = () => {
    // this.props.getFacets(this.props.filters.data, "allData");
    // console.log("BUYERS", this.props.filters);
    // this.props.getFacets(this.props.filters.data, "buyerCountries");
    this.props.getFacets(this.props.filters.data, "sellerCountries");

    // this.props.getBuyers(this.props.filters.data, 0);
    // this.props.getSellers(this.props.filters.data, 0);
    // this.props.getCPVs(this.props.filters.data, 0);
    // this.props.getSearch(this.props.filters.data, 0, "allData");
    // this.props.getResult(this.props.filters.data, 0, "allData");
  };

  setYear = (years) => {
    // console.log("YEARS:", years);
    this.setState({ year_range: years });
  };

  handleAmountTo = (e) => {
    // if (e.target.value === '' || re.test(e.target.value)) {
    this.setState({ amountTo: e.target.value });
    // this.amountTo = e.target.value
    // }
  };

  handleAmountFrom = (e) => {
    this.setState({ amountFrom: e.target.value });
    // this.amountFrom = e.target.value
  };

  fetchCountries = () => {
    // console.log("Fetch Countries");
    if (this.state.first_country && this.state.second_country) {
      this.props.getFirstCountry({
        buyerCountry: this.state.first_country,
        year: this.state.year_range,
      });
      this.props.getSecondCountry({
        buyerCountry: this.state.second_country,
        year: this.state.year_range,
      });
      this.props.getFirstCountryContracts(
        { year: this.state.year_range, buyerCountry: this.state.first_country },
        0,
        "buyerCountries",
        50,
        "CONTRACT_AWARD_NOTICES",
        "AWARD_VALUE_EURO"
      );
      this.props.getSecondCountryContracts(
        {
          year: this.state.year_range,
          buyerCountry: this.state.second_country,
        },
        0,
        "buyerCountries",
        50,
        "CONTRACT_AWARD_NOTICES",
        "AWARD_VALUE_EURO"
      );
      this.setState({ compare: true });
    }
  };

  fetchContractsAmountFilter = () => {
    // console.log("AMMOUNTTTTSSS", this.inputFrom);
    this.props.getFirstCountryContracts(
      {
        amount: [this.state.amountFrom, this.state.amountTo],
        year: this.state.year_range,
        buyerCountry: this.state.first_country,
      },
      0,
      "sellerCountries",
      50,
      "CONTRACT_AWARD_NOTICES",
      "AWARD_VALUE_EURO"
    );
    this.props.getSecondCountryContracts(
      {
        amount: [this.state.amountFrom, this.state.amountTo],
        year: this.state.year_range,
        buyerCountry: this.state.second_country,
      },
      0,
      "sellerCountries",
      50,
      "CONTRACT_AWARD_NOTICES",
      "AWARD_VALUE_EURO"
    );
  };

  renderPies = () => {
    // if (this.state.first_country && this.state.second_country){
    //   this.props.getFirstCountry({ sellerCountry: this.state.first_country })
    //   this.props.getSecondCountry({ sellerCountry: this.state.second_country })

    // }

    return (
      <Row>
        <Col md={6}>
          {this.props.firstCountry.data.length > 0 && (
            //  this.renderPie()
            <React.Fragment>
              <DonutPie data={this.props.firstCountry} />
            </React.Fragment>
          )}
        </Col>
        <Col md={6}>
          {this.props.secondCountry.data.length > 0 && (
            <React.Fragment>
              <DonutPie data={this.props.secondCountry} />
            </React.Fragment>
          )}
        </Col>
      </Row>
    );
  };
  renderArrays = () => {
    return (
      <Row>
        <Col md={6}>
          {this.props.firstCountry.data.length > 0 && (
            <React.Fragment>
              <TreeTable data={this.props.firstCountry} />
            </React.Fragment>
          )}
        </Col>
        <Col md={6}>
          {this.props.secondCountry.data.length > 0 && (
            <React.Fragment>
              <TreeTable data={this.props.secondCountry} />
            </React.Fragment>
          )}
        </Col>
      </Row>
    );
  };

  renderBubbleCharts = () => {
    return (
      <Row>
        <Col md={6}>
          {this.state.compare && (
            <React.Fragment>
              <BubbleChart contracts={this.props.firstCountryContracts.data} />
            </React.Fragment>
          )}
        </Col>
        <Col md={6}>
          {this.state.compare && (
            <React.Fragment>
              <BubbleChart contracts={this.props.secondCountryContracts.data} />
            </React.Fragment>
          )}
        </Col>
      </Row>
    );
  };

  renderAmmountFilter = () => {
    return (
      <Row>
        {this.state.compare && (
          <Col md={12} className="d-flex justify-content-center">
            <Jumbotron>
              <Row>
                <Col>
                  <h2>Select Contract Amounts Range</h2>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={6}>
                  <Label>From:</Label>
                  <Input
                    type="number"
                    step="1000"
                    min={0}
                    // ref={this.inputFrom}
                    // value={this.state.amountFrom}
                    onChange={this.handleAmountFrom}
                  />
                </Col>
                <Col md={6}>
                  <Label>To:</Label>
                  <Input
                    type="number"
                    step="1000"
                    min={0}
                    ref={this.inputTo}
                    // value={this.state.amountTo}
                    onChange={this.handleAmountTo}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={12} className="d-flex justify-content-center">
                  <Button onClick={this.fetchContractsAmountFilter}>OK</Button>
                </Col>
              </Row>
            </Jumbotron>
          </Col>
        )}
      </Row>
    );
  };
  // renderPie = () => {
  //   // var colors = Highcharts.getOptions().colors;
  //   // console.log("asdas", this.props.firstCountry)
  //   // let data = []
  //   // this.props.firstCountry.data.map((cpv, index) => {
  //   //   data.push({
  //   //     id: index.toString(),
  //   //     tooltipIncluded: true,
  //   //     color: colors[index],
  //   //     name: cpv.key,
  //   //     value: cpv.count
  //   //   })

  //   //   if (Array.isArray(cpv.additionals) && cpv.additionals.length > 0) {
  //   //     cpv.additionals.map((addCPV, i) => {
  //   //       data.push({
  //   //         id: i + "a",
  //   //         parent: index.toString(),
  //   //         name: addCPV.key,
  //   //         value: addCPV.doc_count,
  //   //         tooltipIncluded: true,
  //   //       })

  //   //     })
  //   //   }
  //   // })
  //   // const chartOptions = {
  //   //   title: "MY TEST22",
  //   //   chart: {
  //   //     type: 'sunburst'
  //   //   },
  //   //   series: {
  //   //     data: data,

  //   //   },
  //   //   allowDrillToNode: true,
  //   //   cursor: 'pointer',
  //   // }
  //   // console.log("DATADATA", data)
  //   return (
  //     <React.Fragment>
  //       <DonutPie data={this.props.firstCountry} />
  //     </React.Fragment>

  //     // <HighchartsReact
  //     //   highcharts={Highcharts}
  //     //   options={chartOptions}
  //     // />
  //   )
  // }

  render() {
    // console.log("BUYERS in render", this.props.buyerCountries);
    var colors = Highcharts.getOptions().colors;
    const bubbleOption = {
      title: "Bubble test",
      chart: {
        type: "bubble",
        plotBorderWidth: 1,
        zoomType: "xy",
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        gridLineWidth: 1,
        title: {
          text: "Daily fat intake",
        },
        labels: {
          format: "{value} gr",
        },
        plotLines: [
          {
            color: "black",
            dashStyle: "dot",
            width: 2,
            value: 65,
            label: {
              rotation: 0,
              y: 15,
              style: {
                fontStyle: "italic",
              },
              text: "Safe fat intake 65g/day",
            },
            zIndex: 3,
          },
        ],
        accessibility: {
          rangeDescription: "Range: 60 to 100 grams.",
        },
      },

      yAxis: {
        startOnTick: false,
        endOnTick: false,
        title: {
          text: "Daily sugar intake",
        },
        labels: {
          format: "{value} gr",
        },
        maxPadding: 0.2,
        plotLines: [
          {
            color: "black",
            dashStyle: "dot",
            width: 2,
            value: 50,
            label: {
              align: "right",
              style: {
                fontStyle: "italic",
              },
              text: "Safe sugar intake 50g/day",
              x: -10,
            },
            zIndex: 3,
          },
        ],
        accessibility: {
          rangeDescription: "Range: 0 to 160 grams.",
        },
      },
      series: [
        {
          data: [
            { x: 95, y: 95, z: 103.8, name: "BE", country: "Belgium" },
            { x: 86.5, y: 102.9, z: 14.7, name: "DE", country: "Germany" },
            { x: 80.8, y: 91.5, z: 15.8, name: "FI", country: "Finland" },
            { x: 80.4, y: 102.5, z: 12, name: "NL", country: "Netherlands" },
            { x: 80.3, y: 86.1, z: 11.8, name: "SE", country: "Sweden" },
            { x: 78.4, y: 70.1, z: 16.6, name: "ES", country: "Spain" },
            { x: 74.2, y: 68.5, z: 14.5, name: "FR", country: "France" },
            { x: 73.5, y: 83.1, z: 10, name: "NO", country: "Norway" },
            { x: 71, y: 93.2, z: 24.7, name: "UK", country: "United Kingdom" },
            { x: 69.2, y: 57.6, z: 10.4, name: "IT", country: "Italy" },
            { x: 68.6, y: 20, z: 16, name: "RU", country: "Russia" },
            {
              x: 65.5,
              y: 126.4,
              z: 35.3,
              name: "US",
              country: "United States",
            },
            { x: 65.4, y: 50.8, z: 28.5, name: "HU", country: "Hungary" },
            { x: 63.4, y: 51.8, z: 15.4, name: "PT", country: "Portugal" },
            { x: 64, y: 82.9, z: 31.3, name: "NZ", country: "New Zealand" },
          ],
        },
      ],
    };
    const optionSeries = {
      title: "MY TEST",
      chart: {
        type: "sunburst",
      },
      series: {
        data: [
          {
            id: "0.0",
            tooltipIncluded: false,
          },
          {
            id: 1,
            parent: "0.0",
            name: "Consumer",
            color: colors[1],
            tooltipIncluded: true,
          },
          {
            parent: 1,
            name: "Furniture",
            value: 1,
            tooltipIncluded: true,
          },
          {
            parent: 1,
            name: "Office Supplies",
            value: 1,
            tooltipIncluded: true,
          },
          {
            parent: 1,
            name: "Technology",
            value: 1,
            tooltipIncluded: true,
          },
          {
            id: "2.0",
            name: "Corporate",
            parent: "0.0",
            color: colors[2],
            tooltipIncluded: true,
          },
          {
            parent: "2.0",
            name: "Furniture",
            value: 1,
            tooltipIncluded: true,
          },
          {
            parent: "2.0",
            name: "Office Supplies",
            value: 1,
            tooltipIncluded: true,
          },
          {
            parent: "2.0",
            name: "Technology",
            value: 1,
            tooltipIncluded: true,
          },
          {
            id: "3.0",
            name: "Home office",
            parent: "0.0",
            color: colors[3],
            tooltipIncluded: true,
          },
          {
            parent: "3.0",
            name: "Furniture",
            value: 1,
            tooltipIncluded: true,
          },
          {
            parent: "3.0",
            name: "Office Supplies",
            value: 1,
            tooltipIncluded: true,
          },
          {
            parent: "3.0",
            name: "Technology",
            value: 1,
            tooltipIncluded: true,
          },
          {
            id: "4.0",
            name: "Small Business",
            parent: "0.0",
            color: colors[4],
            tooltipIncluded: true,
          },
          {
            parent: "4.0",
            name: "Office Supplies",
            value: 51,
            tooltipIncluded: true,
          },
          {
            parent: "4.0",
            name: "Technology",
            value: 10,
            tooltipIncluded: true,
          },
          {
            parent: "4.0",
            name: " ",
            value: 1,
            color: "white",
            tooltipIncluded: false,
          },
        ],
      },
      allowDrillToNode: true,
      cursor: "pointer",
    };
    return (
      <div className="container">
        <Row>
          <Col md={6}>
            <Row>
              <Col md={11}>
                <MapSelect
                  data={this.props.buyerCountries}
                  onClick={(e, j) => {
                    // console.log("mapSelect data", e, j);
                    this.setState({ first_country: j.key });
                    // this.props.getFirstCountry({ sellerCountry: j.key })
                  }}
                  // onClick={(e) => { console.log("CLICKED") }}
                  selectedValue={this.props.filters.data.buyerCountry}
                ></MapSelect>
              </Col>
              <Col md={1}>
                {/* LAGGY when pies are rendered */}
                <div className="h-75">
                  <Range
                    min={2006}
                    max={2019}
                    allowCross={false}
                    onChange={this.setYear}
                    defaultValue={[2006, 2019]}
                    vertical={true}
                  />
                </div>
              </Col>
            </Row>
          </Col>

          {/* <Col md={2}>
            <Range vertical={true} />

          </Col> */}
          {/* <Col md={1}>


          </Col> */}
          <Col md={6}>
            <MapSelect
              data={this.props.buyerCountries}
              onClick={(e, j) => {
                // console.log("map select data", e, j.key);
                this.setState({ second_country: j.key });
                // this.props.getSecondCountry({ sellerCountry: j.key })
              }}

              // selectedValue={
              //   this.props.filters.data.buyerCountry
              // }
            ></MapSelect>
          </Col>
        </Row>
        {/* <Row>
          <HighchartsReact
            highcharts={Highcharts}
            options={optionSeries}
          />
        </Row> */}
        <Row>
          <Col md={{ size: 6, offset: 5 }}>
            <Button onClick={this.fetchCountries}>Compare</Button>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <h3>Details</h3>
            <p>
              See the details The visualisations below gets updated every time
              you select different countries. They show the amounts spent per
              CPV (Common Procurement Vocabulary).
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            {/* <HighchartsReact
              highcharts={Highcharts}
              options={bubbleOption}
            /> */}
          </Col>
        </Row>
        {this.renderPies()}
        {this.renderArrays()}
        <br />
        <br />
        {this.renderAmmountFilter()}
        {this.renderBubbleCharts()}
        {/* <BubbleChart data={this.state.first_country} /> */}

        {/* <Row>
          <Col md={6}>



          </Col>
        </Row> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    buyerCountries: state.publicContracts.buyerCountries,
    filters: state.publicContracts.filters,
    time: state.publicContracts.time,
    firstCountry: state.publicContracts.firstCountryComparison,
    secondCountry: state.publicContracts.secondCountryComparison,
    // sellerCountries: state.publicContracts.sellerCountries,
    firstCountryContracts: state.publicContracts.firstCountryContracts,
    secondCountryContracts: state.publicContracts.secondCountryContracts,
    buyerCountries: state.publicContracts.buyerCountries,

    // filters: state.publicContracts.filters,
    // result: state.publicContracts.result,
    // buyerCountries: state.publicContracts.buyerCountries,
    // sellerCountries: state.publicContracts.sellerCountries,
    // buyers: state.publicContracts.buyers,
    // sellers: state.publicContracts.sellers,
    // time: state.publicContracts.time,
    // cpvs: state.publicContracts.cpvs,
    // cpvsTable: state.publicContracts.cpvsTable,
    // amount: state.publicContracts.amount,
    // number: state.publicContracts.number,
    // search: state.publicContracts.search,
    // additionalCpvsTable: state.publicContracts.additionalCpvsTable,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBuyers: (filters, from) => dispatch(getBuyers(filters, from)),
    getFilters: (payload) => dispatch(getFilters(payload)),
    getFacets: (payload, identifier) =>
      dispatch(getFacets(payload, identifier)),
    getFirstCountry: (filters) => dispatch(getFirstCountry(filters)),
    getSecondCountry: (filters) => dispatch(getSecondCountry(filters)),
    getFirstCountryContracts: (
      filters,
      from,
      identifier,
      rows,
      indece,
      sortBy
    ) =>
      dispatch(
        getFirstCountryContracts(
          filters,
          from,
          identifier,
          rows,
          indece,
          sortBy
        )
      ),
    getSecondCountryContracts: (
      filters,
      from,
      identifier,
      rows,
      indece,
      sortBy
    ) =>
      dispatch(
        getSecondCountryContracts(
          filters,
          from,
          identifier,
          rows,
          indece,
          sortBy
        )
      ),

    // getResult: (filters, from, identifier) =>
    //   dispatch(getResult(filters, from, identifier)),
    //   getFacets: (payload, identifier) =>
    //     dispatch(getFacets(payload, identifier)),
    //   getBuyers: (filters, from) => dispatch(getBuyers(filters, from)),
    //   getSellers: (filters, from) => dispatch(getSellers(filters, from)),
    //   getCPVs: (filters, from) => dispatch(getCPVs(filters, from)),
    //   setFilter: (payload) => dispatch(setFilter(payload)),
    //   getSearch: (filters, from, identifier) =>
    //     dispatch(getSearch(filters, from, identifier)),
    //   resetFilters: () => dispatch(reset_filters()),
    //   getFilters: (payload) => dispatch(getFilters(payload)),
    //   getAdditionalCPVsPagination: (payload, from) =>
    //     dispatch(getAdditionalCPVsPagination(payload, from)),
    // };
  };
};
export default withTranslation("common")(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CountryComparison))
);
