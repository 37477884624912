import {
  apiRequest
} from "../../../services/api/index";

import {
  fetchData,
  setData,
  setError
} from "./publicContractsActions";

export function esthonianSearch(
  filters
) {
  console.log("anesid search: ", filters);


  //Case: Buyer Countriess
  return async (dispatch) => {
    dispatch(fetchData("esthonianRegistry"));
    try {
      const params = {
        type: "searchEsthonianRegistry",
        data: {
          terms: []
        },
      };
      // Add selected facet's value

      // CAE = BUYER
      if (filters.cae_name) {
        params.data.terms.push({
          "nimi.keyword": filters.cae_name,
        });
      }

      if (filters.win_name) {
        params.data.terms.push({
          nimi: filters.win_name,
        });
      }



      const response = await apiRequest(params);
      if (response.status === 200) {
        const payload = {
          data: response.data.data,
          // total: response.data.total,
          // from: from + rows,
        };
        //console.log("GAVGAVGAV", payload);
        dispatch(setData("esthonianRegistry", payload));
      } else {
        dispatch(
          setError("esthonianRegistry", "Something went wrong. Please try later!")
        );
      }
    } catch (error) {
      dispatch(
        setError("esthonianRegistry", "Something went wrong. Please try later!")
      );
    }
  };
}