import { apiRequest } from '../../services/api/index';
import authStorage from '../../services/authentication/authStorage';

export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER = 'SET_USER';
export const LOG_IN_ERROR = 'LOG_IN_ERROR';
export const FETCHING_API = 'FETCHING_API';
export const RESET_REDUX = 'RESET_REDUX';

/** Error message for network down */
const catch_error = 'Please try again later';

export const set_token = token => {
  return {
    type: SET_TOKEN,
    payload: token
  };
};

export const set_user = payload => {
  return {
    type: SET_USER,
    payload: payload
  };
};

export const log_in_error = message => {
  return {
    type: LOG_IN_ERROR,
    payload: message
  };
};

export const fetch_api = loading => {
  return {
    type: FETCHING_API,
    payload: loading
  };
};

export const reset_redux = () => {
  return {
    type: RESET_REDUX
  };
};

export const resetRedux = () => {
  return dispatch => {
    dispatch(reset_redux());
  };
};

/**
 * Calls api with credentials and set tokens in successful login
 * @param {*} user
 */
export const login = user => {
  const request = {
    type: 'login',
    data: user
  };
  return async dispatch => {
    dispatch(fetch_api(true));
    try {
      const response = await apiRequest(request);
      if (response && response.status === 200) {
        authStorage.setAuth('auth', response.data.token);
        setUser();
        dispatch(set_token(response.data.token));
      } else {
        dispatch(log_in_error(response.data.error));
      }
    } catch (error) {
      dispatch(log_in_error(catch_error));
    }
    dispatch(fetch_api(false));
  };
};

/**
 * Set token to null value
 */
export const logout = () => {
  return dispatch => {
    dispatch(set_token(null));
  };
};

/**
 * Calls api with credentials and set tokens in successful registration
 * @param {*} user
 */
export const register = user => {
  const request = {
    type: 'register',
    data: user
  };
  return async dispatch => {
    dispatch(fetch_api(true));
    try {
      const response = await await apiRequest(request);
      if (response && response.status === 200) {
        authStorage.setAuth('auth', response.data.token);
        setUser();
        dispatch(set_token(response.data.token));
      } else {
        if (response.data.error) {
          dispatch(log_in_error(response.data.error));
        }
      }
    } catch (error) {
      dispatch(log_in_error(catch_error));
    }
    dispatch(fetch_api(false));
  };
};

/**
 * Calls api to set user
 */
export const setUser = () => {
  const request = {
    type: 'me'
  };
  return async dispatch => {
    try {
      const response = await apiRequest(request);
      if (response && response.status === 200) {
        dispatch(set_user(response.data));
      } else {
        dispatch(log_in_error(response.data.error));
        authStorage.removeAuth('auth');
      }
    } catch (error) {
      dispatch(log_in_error(catch_error));
    }
  };
};
