export const FETCH_DATA = "FETCH_DATA";
export const FETCH_DATA_SUCCESSFUL = "FETCH_DATA_SUCCESSFUL";
export const FETCH_DATA_ERROR = "FETCH_DATA_ERROR";
export const RESET_FILTERS = "RESET_FILTERS";
export const SET_NESTED_DATA = "SET_NESTED_DATA";

export function fetchData(stateType) {
  return {
    stateType: stateType,
    type: FETCH_DATA,
  };
}

export function setData(stateType, payload) {
  return {
    stateType: stateType,
    type: FETCH_DATA_SUCCESSFUL,
    payload,
  };
}

export function setError(stateType, payload) {
  return {
    stateType: stateType,
    type: FETCH_DATA_ERROR,
    payload,
  };
}

export function setNestedData(stateType, nestedType, payload) {
  return {
    stateType: stateType,
    nestedType: nestedType,
    type: SET_NESTED_DATA,
    payload,
  };
}

export function resetFilters(stateType) {
  return {
    stateType: stateType,
    type: RESET_FILTERS,
  };
}
