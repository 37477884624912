import React, { useState, useRef, useEffect, Fragment } from "react";
import { Card, CardBody } from "reactstrap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Europe from "./Europe.js";
import World from "./World.js";

// CUSTOM COMPONENTS
import Loader from "../Loader/Loader";
import Error from "../Error/Error";

// CUSTOM SCSS
import "./MapSelect.scss";

// Load Highcharts modules
require("highcharts/modules/map")(Highcharts);
var countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const MapSelect = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [mapOptions, setMapOptions] = useState({});

  const { data, selectedValue } = props;
  const prevProps = usePrevious({ data, selectedValue });
  useEffect(() => {
    if (prevProps) {
      if (JSON.stringify(prevProps.data) !== JSON.stringify(props.data)) {
        if (props.data && props.data.data && props.data.data.length > 0) {
          const myData = props.data.data.map((element) => {
            if (element.key === "UK") {
              element.key = "GB";
            }
            let item = element;
            item["hc-key"] = element.key.toLowerCase();
            item.name = countries.getName(element.key, "en");
            item.value = element.count;
            // if (
            //   props.selectedValue &&
            //   element.code === props.selectedValue.toUpperCase()
            // ) {
            //   item.color = '#44b57d';
            // }
            return item;
          });

          const mapOptions = {
            chart: {
              map: Europe,
            },
            title: {
              text: "",
            },
            mapNavigation: {
              enabled: true,
              buttonOptions: {
                verticalAlign: "bottom",
              },
            },
            colorAxis: {
              maxColor: props.data.map === "Europe" ? "#C44118" : "#C47618",
              min: 1,
              minColor: props.data.map === "Europe" ? "#FFDBD0" : "#FFEAD0",
              stops: [
                [0, props.data.map === "Europe" ? "#FFDBD0" : "#FFEAD0"],
                [0.67, props.data.map === "Europe" ? "#FF8660" : "#FFB760"],
                [1, props.data.map === "Europe" ? "#C44118" : "#C47618"],
              ],
              type: "logarithmic",
            },
            series: [
              {
                data: myData,
                allowPointSelect: true,
                states: {
                  select: {
                    color: "blue",
                  },
                },
                dataLabels: {
                  enabled: true,
                  format: "{point.name}",
                },
                point: {
                  events: {
                    click: function (e) {
                      // console.log("ClickedCountry", e)
                      // this.update({ color: '#fe5800' }, true, false);

                      // e.point.series.graph.attr('stroke', 'red')
                      props.onClick(props.data.id, e.point);
                      // props.onFilterUpdate(props.data.id, e.point.key);
                    },
                  },
                },
              },
            ],
          };

          if (props.data && props.data.view && props.data.view.length > 0) {
            props.data.view.forEach((element) => {
              Object.keys(element).forEach((key) => {
                if (key !== "colorAxis") {
                  mapOptions[key] = element[key];
                }
              });
            });
          }

          setMapOptions(mapOptions);
        } else {
          setMapOptions(false);
        }
        setIsLoading(props.data.fetching);
        setError(props.data.error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedValue]);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  return (
    <Card>
      <CardBody>
        {props.data && props.data.title && (
          <div className="card__title">
            <h5 className="bold-text">{props.data.title}</h5>
          </div>
        )}
        <Loader loading={isLoading} />
        {!isLoading && (
          <>
            <Error error={error} />
            {mapOptions && (
              <Fragment>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={mapOptions}
                  constructorType={"mapChart"}
                />
              </Fragment>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default MapSelect;
