import { apiRequest } from "../../../services/api/index";

import {
  fetchData,
  setData,
  setError,
} from "../publicContracts/publicContractsActions";

// CUSTOM DATA
import { searchHead } from "../../../services/helpers/searchHead";

export function getFirstCountry(filters) {
  // switch (identifier) {
  //   case "buyerCountries":
  //     filters.resultsType = "WIN_COUNTRY_CODE";
  //     break;
  //   case "sellerCountries":
  //     filters.resultsType = "ISO_COUNTRY_CODE";
  //     break;
  //   case "CPVs":
  //     filters.resultsType = "CPV";
  //     break;
  //   default:
  // }
  // console.log("anesid search: ", filters, identifier);
  //Case: Buyer Countriess
  return async (dispatch) => {
    dispatch(fetchData("firstCountryComparison"));
    try {
      const params = {
        type: "aggregateCPVsAdditionalCpvs",
        data: {
          field: "CPV",
          terms: [],
          from: 0,
          size: 20,
          ranges: [],
        },
      };

      // Add selected facet's value
      // if (filters.buyerCountry) {
      //   params.data.terms.push({
      //     WIN_COUNTRY_CODE: filters.buyerCountry,
      //   });
      // }
      // if (filters.sellerCountry) {
      //   params.data.terms.push({
      //     ISO_COUNTRY_CODE: filters.sellerCountry,
      //   });
      // }

      if (filters.buyerCountry) {
        params.data.terms.push({
          ISO_COUNTRY_CODE: filters.buyerCountry,
        });
      }
      if (filters.sellerCountry) {
        params.data.terms.push({
          WIN_COUNTRY_CODE: filters.sellerCountry,
        });
      }
      // if (filters.cpv) {
      //   params.data.terms.push({
      //     CPV: filters.cpv,
      //   });
      // }

      // Add selected facet's range
      if (filters.year) {
        // console.log("YEAR FILTERS", filters.year)
        params.data.ranges.push({
          YEAR: {
            start: filters.year[0],
            end: filters.year[1],
          },
        });
      }
      const response = await apiRequest(params);
      if (response.status === 200) {
        // console.log("anesid search response: ", response);
        // console.log("anesid link: ", response.data.queryData.body);
        // console.log("anesid method: ", response.data.queryData.method);
        // console.log(
        //   "anesid path: ",
        //   decodeURIComponent(response.data.queryData.path)
        // );

        // const payload = {
        //   data: response.data.data,
        //   view: searchHead,
        //   total: response.data.total,
        //   from: from + 10,
        //   queryData: response.data.queryData,
        // };

        // // ***** anesid: make a rework on date not to be invalid
        // let pl = payload.data.map((contract) => {
        //   let date = contract.DT_AWARD.split("-");
        //   date = date[2] + "-" + date[1] + "-" + date[0];
        //   contract.DT_AWARD = date;

        //   date = contract.DT_DISPATCH.split("-");
        //   date = date[2] + "-" + date[1] + "-" + date[0];
        //   contract.DT_DISPATCH = date;

        //   return contract;
        // });

        // payload.data = pl;

        dispatch(setData("firstCountryComparison", response.data));
      } else {
        dispatch(
          setError(
            "firstCountryComparison",
            "Something went wrong. Please try later!"
          )
        );
      }
    } catch (error) {
      dispatch(
        setError(
          "firstCountryComparison",
          "Something went wrong. Please try later!"
        )
      );
    }
  };
}

export function getSecondCountry(filters) {
  // switch (identifier) {
  //   case "buyerCountries":
  //     filters.resultsType = "WIN_COUNTRY_CODE";
  //     break;
  //   case "sellerCountries":
  //     filters.resultsType = "ISO_COUNTRY_CODE";
  //     break;
  //   case "CPVs":
  //     filters.resultsType = "CPV";
  //     break;
  //   default:
  // }
  // console.log("anesid search: ", filters, identifier);
  //Case: Buyer Countriess
  return async (dispatch) => {
    dispatch(fetchData("secondCountryComparison"));
    try {
      const params = {
        type: "aggregateCPVsAdditionalCpvs",
        data: {
          field: "CPV",
          terms: [],
          from: 0,
          size: 20,
          ranges: [],
        },
      };

      // Add selected facet's value
      // if (filters.buyerCountry) {
      //   params.data.terms.push({
      //     WIN_COUNTRY_CODE: filters.buyerCountry,
      //   });
      // }
      // if (filters.sellerCountry) {
      //   params.data.terms.push({
      //     ISO_COUNTRY_CODE: filters.sellerCountry,
      //   });
      // }

      if (filters.buyerCountry) {
        params.data.terms.push({
          ISO_COUNTRY_CODE: filters.buyerCountry,
        });
      }
      if (filters.sellerCountry) {
        params.data.terms.push({
          WIN_COUNTRY_CODE: filters.sellerCountry,
        });
      }

      // if (filters.cpv) {
      //   params.data.terms.push({
      //     CPV: filters.cpv,
      //   });
      // }
      // Add selected facet's range
      if (filters.year) {
        // console.log("YEAR FILTERS", filters.year)

        params.data.ranges.push({
          YEAR: {
            start: filters.year[0],
            end: filters.year[1],
          },
        });
      }
      const response = await apiRequest(params);
      if (response.status === 200) {
        // console.log("anesid search response: ", response);
        // console.log("anesid link: ", response.data.queryData.body);
        // console.log("anesid method: ", response.data.queryData.method);
        // console.log(
        //   "anesid path: ",
        //   decodeURIComponent(response.data.queryData.path)
        // );

        // const payload = {
        //   data: response.data.data,
        //   view: searchHead,
        //   total: response.data.total,
        //   from: from + 10,
        //   queryData: response.data.queryData,
        // };

        // // ***** anesid: make a rework on date not to be invalid
        // let pl = payload.data.map((contract) => {
        //   let date = contract.DT_AWARD.split("-");
        //   date = date[2] + "-" + date[1] + "-" + date[0];
        //   contract.DT_AWARD = date;

        //   date = contract.DT_DISPATCH.split("-");
        //   date = date[2] + "-" + date[1] + "-" + date[0];
        //   contract.DT_DISPATCH = date;

        //   return contract;
        // });

        // payload.data = pl;

        dispatch(setData("secondCountryComparison", response.data));
      } else {
        dispatch(
          setError(
            "secondCountryComparison",
            "Something went wrong. Please try later!"
          )
        );
      }
    } catch (error) {
      dispatch(
        setError(
          "secondCountryComparison",
          "Something went wrong. Please try later!"
        )
      );
    }
  };
}

export function getFirstCountryContracts(
  filters,
  from,
  identifier,
  rows,
  indece,
  sortBy = null
) {
  // console.log("anesid search: ", filters, identifier);
  // let rows = filters.rows
  // console.log("ROWS", from, rows);
  // console.log("INDECE", indece);
  // rows = 20;
  switch (identifier) {
    case "buyerCountries":
      filters.resultsType = "ISO_COUNTRY_CODE";
      break;
    case "sellerCountries":
      filters.resultsType = "WIN_COUNTRY_CODE";
      break;
    case "CPVs":
      filters.resultsType = "CPV";
      break;
    case "CAE_NAME":
      filters.resultsType = "ISO_COUNTRY_CODE";
      break;
    default:
  }

  //Case: Buyer Countriess
  return async (dispatch) => {
    dispatch(fetchData("firstCountryContracts"));
    try {
      const params = {
        type: "search",
        data: {
          terms: [],
          ranges: [],
          size: rows,
          from: from,
          indece: indece,
          sortBy: sortBy,
        },
      };
      // Add selected facet's value
      if (filters.cae_name) {
        params.data.terms.push({
          CAE_NAME: filters.cae_name,
        });
      }

      if (filters.win_name) {
        params.data.terms.push({
          WIN_NAME: filters.win_name,
        });
      }

      if (filters.title) {
        params.data.terms.push({
          TITLE: filters.title,
        });
      }
      // if (indece !== "CONTRACT_NOTICES") {
      //   if (filters.buyerCountry) {
      //     params.data.terms.push({
      //       WIN_COUNTRY_CODE: filters.buyerCountry,
      //     });
      //   }
      // }
      if (filters.buyerCountry) {
        params.data.terms.push({
          ISO_COUNTRY_CODE: filters.buyerCountry,
        });
      }
      if (filters.cpv) {
        params.data.terms.push({
          CPV: filters.cpv,
        });
      }
      // Add selected facet's range
      // if (filters.year) {
      //   params.data.ranges.push({
      //     YEAR: {
      //       start: filters.year[0],
      //       end: filters.year[1]
      //     },
      //   });
      // }
      if (filters.year) {
        params.data.ranges.push({
          YEAR: {
            start: filters.year[0],
            end: filters.year[1],
          },
        });
      }
      if (filters.amount) {
        // console.log("ACTION AMOUNT", filters.amount)
        params.data.ranges.push({
          AWARD_VALUE_EURO: {
            start: parseInt(filters.amount[0]),
            end: parseInt(filters.amount[1]),
          },
        });
      }
      // if (searchBy){

      // }
      // if (filters.number) {
      //   params.data.params.number = filters.number;
      // }
      // if (filters.amount) {
      //   params.data.params.amount = filters.amount;
      // }
      // console.log("anesid search: ", params);

      const response = await apiRequest(params);
      if (response.status === 200) {
        const payload = {
          data: response.data.data,
          view: searchHead,
          total: response.data.total,
          from: from + rows,
        };
        dispatch(setData("firstCountryContracts", payload));
      } else {
        dispatch(
          setError(
            "firstCountryContracts",
            "Something went wrong. Please try later!"
          )
        );
      }
    } catch (error) {
      dispatch(
        setError(
          "firstCountryContracts",
          "Something went wrong. Please try later!"
        )
      );
    }
  };
}

export function getSecondCountryContracts(
  filters,
  from,
  identifier,
  rows,
  indece,
  sortBy = null
) {
  // console.log("anesid search: ", filters, identifier);
  // let rows = filters.rows
  // console.log("ROWS", from, rows);
  // console.log("INDECE", indece);
  // rows = 20;
  switch (identifier) {
    case "buyerCountries":
      filters.resultsType = "ISO_COUNTRY_CODE";
      break;
    case "sellerCountries":
      filters.resultsType = "WIN_COUNTRY_CODE";
      break;
    case "CPVs":
      filters.resultsType = "CPV";
      break;
    case "CAE_NAME":
      filters.resultsType = "ISO_COUNTRY_CODE";
      break;
    default:
  }

  //Case: Buyer Countriess
  return async (dispatch) => {
    dispatch(fetchData("secondCountryContracts"));
    try {
      const params = {
        type: "search",
        data: {
          terms: [],
          ranges: [],
          size: rows,
          from: from,
          indece: indece,
          sortBy: sortBy,
        },
      };
      // Add selected facet's value
      if (filters.cae_name) {
        params.data.terms.push({
          CAE_NAME: filters.cae_name,
        });
      }

      if (filters.win_name) {
        params.data.terms.push({
          WIN_NAME: filters.win_name,
        });
      }

      if (filters.title) {
        params.data.terms.push({
          TITLE: filters.title,
        });
      }
      // if (indece !== "CONTRACT_NOTICES") {
      //   if (filters.buyerCountry) {
      //     params.data.terms.push({
      //       WIN_COUNTRY_CODE: filters.buyerCountry,
      //     });
      //   }
      // }
      if (filters.buyerCountry) {
        params.data.terms.push({
          ISO_COUNTRY_CODE: filters.buyerCountry,
        });
      }
      if (filters.cpv) {
        params.data.terms.push({
          CPV: filters.cpv,
        });
      }
      // Add selected facet's range
      // if (filters.year) {
      //   params.data.ranges.push({
      //     YEAR: {
      //       start: filters.year[0],
      //       end: filters.year[1]
      //     },
      //   });
      // }
      // if (filters.year) {
      //   params.data.terms.push({
      //     YEAR: filters.year,
      //   });
      // }

      if (filters.year) {
        params.data.ranges.push({
          YEAR: {
            start: filters.year[0],
            end: filters.year[1],
          },
        });
      }

      if (filters.amount) {
        // console.log("ACTION AMOUNT", filters.amount)
        params.data.ranges.push({
          AWARD_VALUE_EURO: {
            start: parseInt(filters.amount[0]),
            end: parseInt(filters.amount[1]),
          },
        });
      }

      // if (searchBy){

      // }
      // if (filters.number) {
      //   params.data.params.number = filters.number;
      // }
      // if (filters.amount) {
      //   params.data.params.amount = filters.amount;
      // }
      // console.log("anesid search: ", params);

      const response = await apiRequest(params);
      if (response.status === 200) {
        const payload = {
          data: response.data.data,
          view: searchHead,
          total: response.data.total,
          from: from + rows,
        };
        dispatch(setData("secondCountryContracts", payload));
      } else {
        dispatch(
          setError(
            "secondCountryContracts",
            "Something went wrong. Please try later!"
          )
        );
      }
    } catch (error) {
      dispatch(
        setError(
          "secondCountryContracts",
          "Something went wrong. Please try later!"
        )
      );
    }
  };
}
