import React, { PureComponent } from 'react';
import { Badge, Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

// REDUX
import { connect } from 'react-redux';
import { getStory } from '../../redux/actions/storiesActions';

// CUSTOM COMPONENTS
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error/Error';

// ICONS
import CalendarIcon from 'mdi-react/CalendarIcon';
import AccountIcon from 'mdi-react/AccountIcon';

// CUSTOM SCSS
import './Story.scss';

class Story extends PureComponent {
  state = {
    isLoading: false,
    error: ''
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getStory(this.props.match.params.id);
    } else {
      this.setState({ error: 'No story selected.' });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetching !== prevProps.fetching) {
      this.setState({ isLoading: this.props.fetching });
    }
    if (this.props.error !== prevProps.error) {
      this.setState({ error: this.props.error });
    }
  }

  formatDate = date => {
    date = new Date(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return (date = dd + '/' + mm + '/' + yyyy);
  };

  render() {
    return (
      <Container className="single_story">
        <Row>
          <Loader loading={this.state.isLoading} page={true} />
          {!this.state.isLoading && (
            <>
              {this.state.error ? (
                <Error error={this.state.error} page={true} />
              ) : (
                <Col>
                  <Row>
                    <Col md={12}>
                      <div className="title_container d-flex align-items-center">
                        <h3 className="page-title">{this.props.story.title}</h3>
                        {this.props.story.access_level && (
                          <Badge
                            className={`${this.props.story.access_level.toLowerCase()} story__label`}
                          >
                            {this.props.story.access_level}
                          </Badge>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div className="stories__card">
                    <div className="story_info d-flex align-items-center">
                      <AccountIcon /> {this.props.story.owner}
                      <span className="space"></span>
                      <CalendarIcon />
                      {this.formatDate(this.props.story.createdAtUTC)}
                    </div>
                    <div className="story_description">
                      <ReactMarkdown
                        source={this.props.story.data}
                        escapeHtml={false}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Link className="outline-link" to="/stories">
                      View all stories
                    </Link>
                  </div>
                </Col>
              )}
            </>
          )}
        </Row>

        {/* <TablePagination
          component="div"
          className="material-table__pagination"
          count={10}
          rowsPerPage={6}
          page={0}
          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          rowsPerPageOptions={[]}
          dir="ltr"
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true
          }}
        /> */}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    fetching: state.stories.fetching,
    error: state.stories.error,
    story: state.stories.story
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getStory: id => dispatch(getStory(id))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Story));
