import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarProfileVisitor from './TopbarProfileVisitor';

import { connect } from 'react-redux';
import { setUser, logout } from '../../../redux/actions/authActions';

import authStorage from '../../../services/authentication/authStorage';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired
  };

  componentDidMount() {
    if (!this.props.user.username && authStorage.getAuth('auth')) {
      this.props.setUser();
    }
  }

  logout = () => {
    this.props.logout(null);
  };

  render() {
    const {
      changeMobileSidebarVisibility,
      changeSidebarVisibility,
      user
    } = this.props;

    const isVisitor = !!user.username;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className={`topbar__left ${isVisitor ? '' : 'add_space'}`}>
            {user.username && (
              <TopbarSidebarButton
                changeMobileSidebarVisibility={changeMobileSidebarVisibility}
                changeSidebarVisibility={changeSidebarVisibility}
              />
            )}
          </div>
          <div className="tobar__logo_container">
            <Link className="topbar__logo" to="/" />
          </div>
          <div className="topbar__right">
            {user.username ? (
              <TopbarProfile user={user} logout={this.logout} />
            ) : (
              <TopbarProfileVisitor user={user} logout={this.logout} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
    setUser: () => dispatch(setUser())
  };
};

export default connect(null, mapDispatchToProps)(Topbar);
