const resultsHead = [
  {
    header: 'Country',
    type: 'country',
    attribute: 'key'
  },
  {
    header: 'Amount',
    type: 'number',
    attribute: 'amount'
  },
  {
    header: 'Total',
    type: 'number',
    attribute: 'count'
  }
];

export { resultsHead };
