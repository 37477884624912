const searchHead = [
  {
    header: 'Name',
    type: 'url',
    attribute: 'TITLE',
    url: 'TED_NOTICE_URL'
  },
  {
    header: 'Seller',
    type: 'link',
    attribute: 'CAE_NAME',
    link: 'sellers'
  },
  {
    header: 'Seller Country',
    type: 'country',
    attribute: 'ISO_COUNTRY_CODE'
  },
  {
    header: 'Buyer',
    type: 'link',
    attribute: 'WIN_NAME',
    link: 'buyers'
  },
  {
    header: 'Buyer Country',
    type: 'country',
    attribute: 'WIN_COUNTRY_CODE'
  },
  {
    header: 'Start Date',
    type: 'date',
    attribute: 'DT_AWARD'
  },
  {
    header: 'End Date',
    type: 'date',
    attribute: 'DT_DISPATCH'
  },
  {
    header: 'Amount',
    type: 'string',
    attribute: 'AWARD_VALUE_EURO'
  }
];

export { searchHead };
