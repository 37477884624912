import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link, Redirect } from 'react-router-dom';
import { Button } from 'reactstrap';
import { login, resetRedux } from '../../redux/actions/authActions';

// CUSTOM COMPONENTS
import InputField from '../CustomFields/InputField';
import Loader from '../Loader/Loader';

import './LoginForm.scss';

class LogInForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      showPassword: false,
      user: { username: '', password: '' }
    };
  }

  /**
   * Reset redux to initial state if error or loading is not null
   */
  componentWillUnmount() {
    if (this.props.error || this.props.loading) {
      this.props.resetRedux();
    }
  }

  /**
   * Displays password
   * @param {*} e
   */
  showPassword = e => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  /**
   * If username and password are validated it assigns them to state
   * @param {*} validation
   * @param {*} value
   * @param {*} name
   */
  valueCheck = (value, name) => {
    if (name === 'username') {
      this.setState(prevState => ({
        user: {
          ...prevState.user,
          username: value
        }
      }));
    } else if (name === 'password') {
      this.setState(prevState => ({
        user: {
          ...prevState.user,
          password: value
        }
      }));
    }
  };



  /**
   * Call redux action to login
   * @param {*} e
   */
  login(e) {
    e.preventDefault();
    console.log("LOGIN", this.state.user.username, this.state.user.password)
    if (this.state.user.username && this.state.user.password) {
      this.props.login(this.state.user);
    }
  }

  render() {
    return (
      <>
        <form className="form login-form" onSubmit={event => this.login(event)}>
          <div className="form__form-group">
            <span className="form__form-group-label">Username</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AccountOutlineIcon />
              </div>
              <InputField
                valueCheck={this.valueCheck}
                type="text"
                name="username"
                placeholder="Your name"
                required
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Password</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <KeyVariantIcon />
              </div>
              <InputField
                valueCheck={this.valueCheck}
                type={this.state.showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Your password"
                required
              />
              <button
                type="button"
                className={`form__form-group-button${
                  this.state.showPassword ? ' active' : ''
                  }`}
                onClick={e => this.showPassword(e)}
              >
                <EyeIcon />
              </button>
              <div className="account__forgot-password">
                <a href="/register">Forgot password?</a>
              </div>
            </div>
          </div>
          <div className="account__btns">
            <Button className="account__btn" color="success" type="submit">
              Sign In
            </Button>
            {this.props.token && <Redirect push to="/" />}
          </div>
          <Loader loading={this.props.loading} simple={true}></Loader>
          {this.props.error && !this.props.loading && (
            <div className="error_message">{this.props.error} </div>
          )}
        </form>
        <div className="account__have-account">
          <p>
            Don't have an account? <Link to="/register">Register</Link>
          </p>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.user.token,
    loading: state.user.loading,
    error: state.user.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: user => dispatch(login(user)),
    resetRedux: () => dispatch(resetRedux())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogInForm);
